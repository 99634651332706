import React, { useState, useEffect } from "react";
import { SearchRounded } from "@mui/icons-material";
import { Box, Button, useMediaQuery } from "@mui/material";
import Categories from "./Categories/Categories";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";
import CategoriesButtons from "./CategoriesButtons/CategoriesButtons";
import ProductSort from "./ServiceSort/ServiceSort";
import ProductsFilter from "./ServiceFilter/ServiceFilter";
import { useSelector } from "react-redux";
import {
  baseUrl,
  custServiceCategoryAPI,
  custServiceListAPI,
} from "../../../Config/apiConfig";

import axios from "axios";
import ServiceFilter from "./ServiceFilter/ServiceFilter";
import ServiceSort from "./ServiceSort/ServiceSort";

const CustService = () => {
  const [searchService, setSearchService] = useState("");
  const [cateId, setCateId] = useState("");
  const [custSortSerData, setCustSortSerData] = useState([]);
  const [serviceCount, setServiceCount] = useState("");
  const [cateName, setCateName] = useState("");
  const [firstCateId, setFirstCateId] = useState("");
  const [firstCateName, setFirstCateName] = useState("");
  const [firstServiceCount, setFirstServiceCount] = useState("");

  // Get Service Id from Redux
  const popularServiceCateId = useSelector(
    (state) => state.popularCateId.popularServiceCateId
  );

  const serviceSubCateIdItem = useSelector(
    (state) => state.serviceSubCateId.serviceSubCateIdItem
  );

  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery("(max-width:320px )");

  const handleSearch = (event) => {
    setSearchService(event.target.value);
  };
  const handleServiceSort = (id) => {
    if (id) {
      setCateId(id);
    }
  };
  const handlePorductFilter = (id) => {
    if (id) {
      setCateId(id);
    }
  };
  const handleCategoryId = (id) => {
    console.log("id", id);
    setCateId(id);
  };

  const handleCateName = (data) => {
    if (data) {
      setCateName(data);
    } else {
      setCateName(firstCateName);
    }
  };

  // const handlePopularServiceCateId = () => {
  //   setCateId("/home/searchByCategory/" + popularServiceCateId);
  //   console.log("Popular ID from Redux:", popularServiceCateId);
  //   handlePopularServiceCateId();
  // };

  useEffect(() => {
    const fetchServiceSortAPI = async () => {
      if (!cateId) return;
      try {
        const response = await axios.get(custServiceListAPI + cateId);

        if (response?.data && response.status === 200) {
          console.log("services lists", response?.data);
          setServiceCount(response?.data.data.length);
          setCustSortSerData(response?.data.data);
        }
      } catch (error) {
        console.log("All Services Error", error);
      }
    };
    fetchServiceSortAPI();
  }, [cateId]);

  return (
    <>
      <div id="cust_service__container">
        <div className="search__bar_container d-flex mt-5 pt-3">
          <div className="search_bar search-bar border border-2 border-end-0 px-1 d-flex align-items-center justify-content-center w-100">
            <SearchRounded
              sx={{
                color: "rgba(106, 125, 159, 1)",
                width: isVerySmallScreen ? "16px" : "25px",
                height: isVerySmallScreen ? "16px" : "25px",
              }}
            />
            <Box
              sx={{
                m: 0,
                width: "100%",
                height: isVerySmallScreen ? "30px" : "auto",
              }}
            >
              <input
                className="search-bar__input w-100 fs-6 fw-medium border-0"
                type="text"
                id="searchForService"
                placeholder="Search For Service"
                value={searchService}
                onChange={handleSearch}
              />
            </Box>
          </div>
          <Button
            variant="contained"
            sx={{
              padding: "auto 25px",
              borderLeft: "none",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
            className="gilroy"
          >
            Search
          </Button>
        </div>
        <section>
          <div className="d-flex justify-content-start gap-4 mt-5">
            <div className="">
              <CategoriesButtons
                catID={handleCategoryId}
                catName={handleCateName}
              />
            </div>
            <VirticalStyleLine />
            <div className="categories_container w-100">
              <h4 className="categories_heading gilroy">
                Showing All{" "}
                <strong>({serviceCount || firstServiceCount || 0})</strong>
                <span>{cateName ? cateName : firstCateName}</span>
              </h4>
              <div
                className={`d-flex ${
                  isExtraSmallScreen ? "flex-column" : "flex-row"
                }  gap-2 gap-sm-inherit justify-content-between mt-4`}
              >
                <ServiceFilter onCheck={handlePorductFilter} />
                <ServiceSort onCheck={handleServiceSort} />
              </div>
              <Categories custSortSerData={custSortSerData} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CustService;
