import React, { useEffect, useState } from "react";
import "./MyOrdersPaginate.css";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Rating,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { MoreVert, LocalOffer } from "@mui/icons-material";
import MyOrdersFilter from "../MyOrdersFilter/MyOrdersFilter";
import MyOrdersSort from "../MyOrderSort/MyOrderSort";
import axios from "axios";
import {
  custOrderListAPI,
  custOrderCancelAPI,
  custOrderRatingAPI,
  custAddressEditAPI,
} from "../../../Config/apiConfig";
import { toast } from "react-toastify";

const itemsPerPage = 4;

const MyOrdersPaginate = () => {
  const navigate = useNavigate();

  // useStates ------------------------
  const [currentPage, setCurrentPage] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [productFilter, setProductFilter] = useState("");
  const [status, setStatus] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Dialog open state
  const [openReview, setOpenReview] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [compId, setCompId] = useState("");
  const [custId, setCustId] = useState("");
  const [prodId, setProdId] = useState("");
  const [rateStar, setRateStar] = useState(0);
  const [hover, setHover] = useState(-1);
  const [tellUs, setTellUs] = useState("");

  // Screen size for responsiveness--------------------

  const isLargeScreen = useMediaQuery(" (max-width: 992px) ");
  const isSmallScreen = useMediaQuery("(max-width:564px) ");
  const isVerySmallScreen = useMediaQuery(" (max-width:420px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:350px)");

  const handleReviewOpen = (event) => {
    if (event) {
      event.stopPropagation(); // Prevent navigation on close
    }
    setOpenReview(true);
  };
  const handleReviewClose = (event) => {
    if (event) {
      event.stopPropagation(); // Prevent navigation on close
    }
    setOpenReview(false);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  //  Handling Show Bids Button -----------------------------
  const handleOrders = (id) => {
    if (id) {
      const prodId = id;
      localStorage.setItem("productDetailsId", JSON.stringify(prodId));
    }
    navigate("/my-order-page");
  };

  const handleMyOrdersFilter = (data) => {
    if (data) {
      setProductFilter(data);
    }
  };
  const handleMyOrdersSort = (data) => {
    if (data) {
      setProductFilter(data);
    }
  };

  const openDialog = () => setIsDialogOpen(true);

  // Close dialog
  const closeDialog = () => setIsDialogOpen(false);

  useEffect(() => {
    const fetchCustOrderListAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData) {
        try {
          const response = await axios.get(custOrderListAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.data) {
            setOrderData(response.data.data);
          }
        } catch (error) {}
      }
    };
    fetchCustOrderListAPI();
  }, [orderList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openDropdownId && !event.target.closest(".more-option--box")) {
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openDropdownId]);

  const handleMoreOption = (event, id) => {
    event.stopPropagation(); // Prevent click event from bubbling up
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const handleCancelOrder = async (event, id, status) => {
    event.stopPropagation();

    if (id) {
      if (
        status === "Confirm" ||
        status === "Delivered" ||
        status === "Canceled"
      ) {
        setIsDialogOpen(false);
        toast.warn("Your Order Cannot Be Canceled");
        return;
      }

      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData) {
        try {
          const response = await axios.get(custOrderCancelAPI + id, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response?.data && response.status === 200) {
            toast.success(response.data.msg);
            setIsDialogOpen(false);

            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        } catch (error) {
          console.log(error.response.data);
          setIsDialogOpen(false);
        }
      }
    }
  };

  const handleReviewSave = async () => {
    const loginResponse = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(loginResponse);
    if (parsedData) {
      try {
        const response = await axios.post(
          custOrderRatingAPI,
          {
            oId: orderId,
            pId: prodId,
            cusId: custId,
            cId: compId,
            star: rateStar,
            text: tellUs,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          }
        );
        if (response?.data && response.status === 200) {
          toast.success(response?.data.msg);
          handleReviewClose();

          // window.location.reload();
        }
      } catch (error) {
        console.log("Error updating social links", error.response.data);
      }
    } else {
      alert("token is not avalilable");
    }
  };

  const filterMap = {
    "High To Low": (a, b) => b.totalPrice - a.totalPrice,
    "Low To High": (a, b) => a.totalPrice - b.totalPrice,
    Newest: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    Oldest: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  };

  const statusFilters = ["Pending", "Confirm", "Delivered", "Canceled"];

  const sortedProducts =
    productFilter in filterMap
      ? [...orderData].sort(filterMap[productFilter])
      : statusFilters.includes(productFilter)
      ? orderData.filter((item) => item.status === productFilter)
      : [...orderData];

  const offset = currentPage * itemsPerPage;
  const currentItems = sortedProducts.slice(offset, offset + itemsPerPage);

  return (
    <div className="mt-5">
      <div className="my-5">
        <div
          className={`d-flex ${
            isExtraSmallScreen ? "flex-column" : "flex-sm-row"
          } gap-2 gap-sm-inherit justify-content-between mt-5`}
        >
          <MyOrdersFilter onCheck={handleMyOrdersFilter} />
          <MyOrdersSort onCheck={handleMyOrdersSort} />
        </div>
      </div>
      <div>
        {currentItems && currentItems.length > 0 ? (
          currentItems.map((item) => (
            <div
              key={item._id}
              onClick={() => handleOrders(item._id)}
              className="border p-2 p-sm-4 rounded mb-4 mt-2"
            >
              <div className="cust_order__details w-100 align-items-start align-items-lg-center  gap-4 ">
                <div className="cust_order_img__container">
                  <div className="cust_order_img">
                    <img
                      src={item?.productDetails?.image}
                      alt={item.title}
                      className="order_img"
                    />
                  </div>
                </div>

                <div className="cust_order_details_container">
                  <div className=" d-flex flex-column justify-content-between ">
                    <div className="d-flex justify-content-between align-items-center align-items-sm-start align-items-md-center">
                      <div>
                        <h4 className="order_title gilroy-regular m-0">
                          {item.productDetails.title}
                        </h4>
                      </div>
                      <div>
                        <IconButton
                          onClick={(event) => handleMoreOption(event, item._id)}
                        >
                          <MoreVert
                            sx={{
                              fontSize: isVerySmallScreen
                                ? "20px"
                                : isSmallScreen
                                ? "25px"
                                : "30px",
                              color: "#000",
                            }}
                          />
                        </IconButton>
                      </div>

                      {/* Only render the dropdown if the ID matches */}
                      {openDropdownId === item._id && (
                        <div className="more-option--box position-absolute border rounded">
                          <div className="more-option d-flex flex-column gap-2 align-items-start justify-content-center py-2">
                            <div className="button-box w-100 px-4">
                              <p
                                sx={{ color: "#000" }}
                                className="view--details gilroy m-0 py-2"
                                onClick={() => handleOrders(item._id)}
                              >
                                View Details
                              </p>
                            </div>
                            <div className="button-box w-100 px-4">
                              <p
                                sx={{ color: "#000" }}
                                className="delete--order gilroy m-0 py-2"
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevent navigation
                                  openDialog();
                                }}
                              >
                                Cancel
                              </p>

                              <Dialog open={isDialogOpen} onClose={closeDialog}>
                                <DialogTitle>Confirm Cancel Order</DialogTitle>
                                <DialogContent>
                                  <p>
                                    Are you sure you want to cancel this order?
                                  </p>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={closeDialog}
                                    sx={{ textTransform: "none" }}
                                    className="gilroy"
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    onClick={(event) =>
                                      handleCancelOrder(
                                        event,
                                        item._id,
                                        item.status
                                      )
                                    }
                                    sx={{
                                      color: "#fc3232",
                                      textTransform: "none",
                                    }}
                                    className="gilroy"
                                  >
                                    Cancel
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-lg-center gap-2 gap-sm-0">
                      <div className="order-details-info--container">
                        <div className="order_details_container ">
                          <div className="d-flex flex-column gap-1 gap-md-2">
                            <div className="d-flex align-items-center gap-2">
                              <LocalOffer
                                sx={{ width: 15, height: 15, color: "#275EA7" }}
                              />
                              <p className="orders_infos__text details gilroy fs-5 m-0">
                                <span className="text-decoration-line-through fs-6">
                                  &#8377; {item.productDetails.mrp}
                                </span>
                                &nbsp;&nbsp;
                                <span style={{ color: "#2a9b3c" }}>
                                  &#8377; {item.totalPrice}
                                </span>
                              </p>
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <p className="orders_infos__text details details-text gilroy m-0 ">
                                  Order ID: {item._id}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-1 gap-md-2 gap-lg-3">
                            <div className="d-flex align-items-center gap-2">
                              <p className="orders_infos__text details gilroy m-0">
                                Quantity: {item.qty}
                              </p>
                            </div>

                            <div>
                              <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon
                                  icon={faCalendarAlt}
                                  style={{
                                    color: "#275EA7",
                                    fontSize: "14.5px",
                                  }}
                                />
                                <p className="orders_infos__text details gilroy m-0">
                                  {item.oDate.split(" ")[0]} |{" "}
                                  {item.oDate.split(" ").slice(1).join(" ")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 w-100">
                      <p className="seller_order_description gilroy m-0">
                        <strong>Description</strong>:{" "}
                        {item?.productDetails?.description}
                      </p>
                    </div>
                    <div className="order_details_status d-flex flex-row justify-content-between mt-2 w-100">
                      <p
                        style={{
                          backgroundColor:
                            item.status === "Delivered"
                              ? "#B3F2DC"
                              : item.status === "Confirm"
                              ? "#E8FBE8"
                              : item.status === "Canceled"
                              ? "#FFDAD5"
                              : "#F2EFCF",
                          color:
                            item.status === "Delivered"
                              ? "#20C997"
                              : item.status === "Confirm"
                              ? "#2A9928"
                              : item.status === "Canceled"
                              ? "#C00006"
                              : "#898400",
                          fontWeight: 600,
                          letterSpacing: "0.5px",
                          fontSize: isSmallScreen
                            ? "12px"
                            : isLargeScreen
                            ? "14px"
                            : "16px",

                          // ------------------------------------
                        }}
                        className="order_status px-3 py-1 py-sm-2 m-0 rounded-pill gilroy"
                      >
                        Status: {item.status}
                      </p>

                      {item.status === "Delivered" && (
                        <>
                          <Button
                            onClick={(event) => {
                              event.stopPropagation();
                              setOrderId(item?._id);
                              setCustId(item?.cusId);
                              setProdId(item?.productDetails?._id);
                              setCompId(item?.companyId);
                              handleReviewOpen();
                            }}
                            sx={{
                              textTransform: "none",
                              fontSize: isSmallScreen ? "12px" : "16px",
                              borderColor: "#1f1f1f",
                              color: "#1f1f1f",
                            }}
                            // variant="outlined"
                            size="small"
                          >
                            Write a Review
                          </Button>
                          <Dialog
                            open={openReview}
                            onClose={handleReviewClose}
                            maxWidth="sm"
                            fullWidth
                            onClick={(event) => event.stopPropagation()}
                            slotProps={{
                              backdrop: {
                                sx: { backgroundColor: "rgba(0, 0, 0, 0.26)" }, // Default MUI backdrop
                              },
                            }}
                          >
                            <DialogTitle className="gilroy">
                              Rate Us
                            </DialogTitle>
                            <DialogContent>
                              <Stack
                                spacing={1}
                                fullWidth
                                alignItems="center"
                                margin="50px 20px 20px"
                              >
                                <Rating
                                  size="large"
                                  name="simple-controlled"
                                  value={rateStar}
                                  onChange={(event, newValue) => {
                                    setRateStar(newValue);
                                  }}
                                  onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                  }}
                                  emptyIcon={
                                    <StarIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                              </Stack>
                              <TextField
                                fullWidth
                                margin="dense"
                                label="Tell us"
                                value={tellUs}
                                multiline
                                rows={4}
                                onChange={(e) => setTellUs(e.target.value)}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleReviewClose}
                                color="secondary"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={handleReviewSave}
                                color="primary"
                                variant="contained"
                              >
                                Submit
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center p-4">
            <p className="text-muted">No orders found.</p>
          </div>
        )}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(sortedProducts.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </div>
  );
};

export default MyOrdersPaginate;
