// export const baseUrl = "http://65.1.109.1/api";
export const baseUrl = "https://api.eservmarket.com/api";

// home page APIs
export const homeSlidersAPI = baseUrl + "/home";
export const subscribeButtonAPI = baseUrl + "/home/subscribe/";
export const productSearchAPI = baseUrl + "/home/search/";
export const searchBySubCategoryAPI = baseUrl + "/home/searchBySubCategory/";

// APIs for Seller

// seller profile APIs  -------------------
export const registerPayloadAPI = baseUrl + "/register/organisationDetail";
export const businessTypeAPI = baseUrl + "/register/companyType";
export const registerEmailAPI = baseUrl + "/register/registerOtp";
export const verifyOtpAPI = baseUrl + "/register/verifyOtp";
export const supplierAPI = baseUrl + "/authentication/supplier";
export const verifyLoginOtpAPI = baseUrl + "/authentication/supplier/verifyOtp";
export const credentialsAPI = baseUrl + "/register/createCredentials";
export const profileBusinessAPI = baseUrl + "/profile/businessProfile";
export const registerDataAPI = baseUrl + "/profile/companyDetails";

// seller profile category APIs -------------------
export const profileCategoriesAPI = baseUrl + "/profile/categories";
export const createServices = baseUrl + "/service/createServices";
export const socialLinks = baseUrl + "/profile/socialLink";

// seller product APIs ------------------
export const productAddAPI = baseUrl + "/product/add";
export const productListAPI = baseUrl + "/product/filterByCategory/";
export const productCategoriesAPI = baseUrl + "/product/category";

// seller services APIs ------------------
export const allServicesAPI = baseUrl + "/service/filterByCategory/";
export const updateServicesAPI = baseUrl + "/service/updateServices";
export const serviceSortAscAPI = baseUrl + "/service/sort";
export const servicesCategoriesAPI = baseUrl + "/service/category";

// Seller Account Profile and Edit Profile APIs
export const sellerBannerEditAPI =
  baseUrl + "/seller/account/profile/imageUpdate";
export const sellerAccountProfileAPI = baseUrl + "/seller/account/profile";
export const sellerBusinessNameEditAPI =
  baseUrl + "/seller/account/profile/organizationName";
export const sellerSocialLinkEditAPI =
  baseUrl + "/seller/account/profile/socialLink";
export const sellerContactEditAPI =
  baseUrl + "/seller/account/profile/contactInformation";
export const sellerOverviewEditAPI =
  baseUrl + "/seller/account/profile/overview";
export const sellerCertificatesEditAPI =
  baseUrl + "/seller/account/profile/certificationImage";
export const sellerAddServiceOfferAPI =
  baseUrl + "/seller/account/profile/serviceOffer";
export const sellerOrderListAPI = baseUrl + "/seller/order/list";

// Seller Dashboard APIs
export const dashboardSliderAPI = baseUrl + "/seller/dashboard";

// APIs for Buyers

// Buyers Profile APIs
export const custRegistrationAPIs = baseUrl + "/customer/auth/register";
export const custOTPVerificationAPI =
  baseUrl + "/customer/auth/registerOtpVerification";
export const custResendOtpAPI = baseUrl + "/customer/auth/resendOtp/";
export const custLoginInAPI = baseUrl + "/customer/auth/login";
export const custLoginOtpVerifyAPI =
  baseUrl + "/customer/auth/loginOtpVerification";
export const custProfileAPI = baseUrl + "/customer/account/profile";
export const custProfileEditAPI = baseUrl + "/customer/account/profileUpdate";
export const custProfileImageEditAPI =
  baseUrl + "/customer/account/imageUpdate";
export const custAddressEditAPI = baseUrl + "/customer/account/addressUpdate";

// Buyer products APIs
export const custProductByCategAPI =
  baseUrl + "/customer/product/filterByCategory/";
export const custAllProductsAPI = baseUrl + "/customer/product";
export const custProductCategoryAPI = baseUrl + "/customer/product/categories";
export const custAddToCartAPI = baseUrl + "/customer/cart/add";
export const custCartListAPI = baseUrl + "/customer/cart/list";
export const custDeletFormCartAPI = baseUrl + "/customer/cart/delete/";
export const custOrderPlacedAPI = baseUrl + "/customer/cart/placeOrder";
export const custCorporatePlaceOrderAPI =
  baseUrl + "/customer/cart/orderPlaced";
export const custOrderListAPI = baseUrl + "/customer/order/list";
export const custProductDetailsAPI = baseUrl + "/customer/order/details/";
export const custOrderCancelAPI = baseUrl + "/customer/order/cancel/";
export const custAddFavouriteAPI = baseUrl + "/customer/wishlist/";
export const custOrderRatingAPI = baseUrl + "/customer/order/rating";

// Buyer Services APIs
export const custAllServicesAPI = baseUrl + "/customer/service";
export const custServiceCategoryAPI = baseUrl + "/customer/service/categories";
export const custServiceListAPI = baseUrl + "/customer/service/list/";
export const custSubCategoriesAPI =
  baseUrl + "/customer/service/subcategories/";

export const custServiceWishListAPI = baseUrl + "/customer/service/wishlist/";
export const custSeeServicesListAPI = baseUrl + "/home/see-more";

// Buyer Start a Bidding
export const custBidCateListAPI = baseUrl + "/customer/bid/categoryList";
export const custBidSubCateListAPI = baseUrl + "/customer/bid/subCategoryList/";
export const custBidServicesAPI = baseUrl + "/customer/bid/services/";
export const custBidAddAPI = baseUrl + "/customer/bid/add";

// Buyer Auction
export const custAuctionsListAPI = baseUrl + "/customer/auction/list";
export const custAuctionOnGoingAPI = baseUrl + "/customer/auction/onGoing";
export const custAuctionDetailsAPI = baseUrl + "/customer/auction/details/";
export const custAuctionPlaceBidAPI = baseUrl + "/customer/auction/placeBid/";
export const custAuctionBiddedOnAPI = baseUrl + "/customer/auction/biddedOn";
export const custAuctionBidAPI = baseUrl + "/customer/auction/bids/";
export const custAuctionBidSortAPI = baseUrl + "/customer/auction/bids/sort";
export const custAuctionBidsFilterAPI =
  baseUrl + "/customer/auction/bids/filter";
