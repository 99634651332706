import React, { useState, useEffect } from "react";
import "./CustProduct.css";
import { SearchRounded } from "@mui/icons-material";
import { Box, Button, useMediaQuery } from "@mui/material";
import Categories from "./Categories/Categories";
import CategoriesButtons from "./CategoriesButton/CategoriesButtons";
import ProductFilter from "./Categories/ProductFilter/ProductFilter";
import ProductSort from "./Categories/ProductSort/ProductSort";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";
import { baseUrl } from "../../../Config/apiConfig";
import { useDispatch } from "react-redux";
import { setSearchProductItem } from "../../../redux/searchProductSlice";
import { custProductByCategAPI } from "../../../Config/apiConfig";
import { useSelector } from "react-redux";
import axios from "axios";

const CustProduct = () => {
  const [searchProduct, setSearchProduct] = useState("");
  const [productCount, setProductCount] = useState("");
  const [cateId, setCateId] = useState("");
  const [custProductData, setCustProductData] = useState([]);
  const [productFilter, setProductFilter] = useState("");
  const [unfilteredBtn, setUnfilteredBtn] = useState([]);
  const [filteredCateBtn, setfilteredCateBtn] = useState([]);

  const [cateName, setCateName] = useState("");

  // Screen Size for responsiveness
  const isSmallScreen = useMediaQuery("(max-width: 576px) ");

  const isExtraSmallScreen = useMediaQuery("(max-width:320px )");

  const handleSearchProduct = (event) => {
    const value = event.target.value;
    setSearchProduct(value);
  };

  const handleProductSort = (data) => {
    if (data) {
      setProductFilter(data);
    }
  };
  const handlePorductFilter = (data) => {
    if (data) {
      setProductFilter(data);
    }
  };

  const handleCategoryId = (id) => {
    setCateId(id);
  };
  const handleCateName = (data) => {
    if (data) {
      setCateName(data);
    } else {
      setCateName("");
    }
  };

  const handleProductBtn = (data) => {
    setUnfilteredBtn(data);
  };

  useEffect(() => {
    if (searchProduct.trim() === "") {
      setfilteredCateBtn(unfilteredBtn);
      console.log("unfiltered btn", unfilteredBtn);
    }
  }, [searchProduct, unfilteredBtn]);

  const handleCustProduct = () => {
    if (searchProduct.trim() !== "") {
      const filtered = unfilteredBtn.filter((item) =>
        item.name.toLowerCase().includes(searchProduct.toLowerCase())
      );
      console.log("filtered btn", filtered);

      setfilteredCateBtn(filtered);
    }
  };

  useEffect(() => {
    const fetchPorductSortAPI = async () => {
      if (!cateId) return;
      try {
        const response = await axios.get(custProductByCategAPI + cateId);

        if (response?.data && response.status === 200) {
          setProductCount(response?.data.data.length);
          setCustProductData(response?.data.data);
        }
      } catch (error) {
        console.log("All Services Error", error);
      }
    };
    fetchPorductSortAPI();
  }, [cateId]);

  useEffect(() => {
    if (filteredCateBtn.length === 0) {
      setProductCount(0);
      setCustProductData([]);
      setCateName("");
    }
  }, [filteredCateBtn]);

  return (
    <>
      <div className="cust_product__container">
        <div className="search__bar_container d-flex justify-content-start w-100 my-2 my-sm-5 py-3">
          <div className="search_bar search-bar border border-2 border-end-0 px-1 d-flex align-items-center justify-content-center">
            <SearchRounded
              sx={{
                color: "rgba(106, 125, 159, 1)",
                width: isSmallScreen ? "16px" : "25px",
                height: isSmallScreen ? "16px" : "25px",
              }}
            />
            <Box
              sx={{
                m: 0,
                width: "100%",
                height: isSmallScreen ? "30px" : "auto",
              }}
            >
              <input
                className="search-bar__input w-100 fs-6 fw-medium border-0 bg-transparent"
                type="text"
                id="searchForService"
                placeholder="Search For Product"
                value={searchProduct}
                onChange={handleSearchProduct}
              />
            </Box>
          </div>
          <Button
            variant="contained"
            sx={{
              padding: isSmallScreen ? "0" : "auto 25px",
              fontSize: isSmallScreen ? "12px" : "18px",
              borderLeft: "none",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
            className="gilroy"
            onClick={handleCustProduct}
          >
            Search
          </Button>
        </div>
        <section>
          <div className="d-flex flex-column flex-md-row justify-content-start gap-4 mt-3 mt-sm-5">
            <div className="">
              <CategoriesButtons
                catID={handleCategoryId}
                catName={handleCateName}
                onProductBtn={handleProductBtn}
                filteredBtn={filteredCateBtn}
              />
            </div>
            <VirticalStyleLine className="" />
            <div className="categories_cust_product_container w-100">
              <h4 className="categories_cust_product_heading gilroy">
                Showing All <strong>({productCount})</strong>{" "}
                <span>{cateName}</span>
              </h4>
              <div
                className={`d-flex ${
                  isExtraSmallScreen ? "flex-column" : "flex-row"
                }  gap-2 gap-sm-inherit justify-content-between mt-4`}
              >
                <ProductFilter onFilter={handlePorductFilter} />
                <ProductSort onCheck={handleProductSort} />
              </div>
              <Categories
                custProductData={custProductData}
                filter={productFilter}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CustProduct;
