import React, { useState, useEffect } from "react";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { Person } from "react-ionicons";

function UserVerification({ onDataChange, onValidate }) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [touched, setTouched] = useState({
    firstname: false,
    lastname: false,
    username: false,
    password: false,
  });

  const isSmallScreen = useMediaQuery("(max-width: 576px)");

  const validateFirstname = (newFirstname) => {
    if (touched.firstname && newFirstname.trim() === "") {
      setFirstnameError("Firstname is required");
      return false;
    } else {
      setFirstnameError("");
      return true;
    }
  };
  const validateLastname = (newLastname) => {
    if (touched.lastname && newLastname.trim() === "") {
      setLastnameError("Lastname is required");
      return false;
    } else {
      setLastnameError("");
      return true;
    }
  };
  const validateUsername = (newUsername) => {
    if (touched.username && newUsername.trim() === "") {
      setUsernameError("Username is required");
      return false;
    } else {
      setUsernameError("");
      return true;
    }
  };
  const validatePassword = (newPassword) => {
    if (touched.password && newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return false;
    } else if (touched.password && !/[A-Z]/.test(newPassword)) {
      setPasswordError("Password must contain at least one uppercase letter");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };
  const handleFieldChange = (field, value) => {
    if (field === "firstname") {
      setFirstname(value);
      setTouched((prev) => ({ ...prev, firstname: true }));
      validateFirstname(value);
    } else if (field === "lastname") {
      setLastname(value);
      setTouched((prev) => ({ ...prev, lastname: true }));
      validateLastname(value);
    } else if (field === "username") {
      setUsername(value);
      setTouched((prev) => ({ ...prev, username: true }));
      validateUsername(value);
    } else if (field === "password") {
      setPassword(value);
      setTouched((prev) => ({ ...prev, password: true }));
      validatePassword(value);
    }
    triggerDataChange();
  };

  const triggerDataChange = () => {
    const isValid =
      firstname.trim() !== "" &&
      lastname.trim() !== "" &&
      username.trim() !== "" &&
      password.length >= 8 &&
      /[A-Z]/.test(password);

    if (isValid) {
      onDataChange({ firstname, lastname, username, password });
    } else {
      onDataChange(null);
    }
    onValidate(isValid);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between py-4 ms-2 gap-2 gap-sm-5 mb-4 ">
        <div className="firstname-container w-100 px-2 ">
          <div className="firstname__label d-flex align-items-center mb-2">
            <PersonRoundedIcon
              sx={{
                fontSize: isSmallScreen ? "22px" : "25px",
                color: "rgba(19, 101, 175, 1)",
              }}
            />
            <InputLabel>
              <p
                style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
                className="my-auto gilroy fw-bold ms-2 text-black"
              >
                First Name
              </p>
            </InputLabel>
          </div>
          <Box>
            <TextField
              id="firstname"
              size="small"
              fullWidth={true}
              value={firstname}
              onChange={(e) => handleFieldChange("firstname", e.target.value)}
              placeholder="Enter First Name"
              error={!!firstnameError}
              helperText={firstnameError}
            />
          </Box>
        </div>
        <div className="lastname-container w-100 px-2">
          <div className="lastname__label d-flex align-items-center mb-2">
            <PersonRoundedIcon
              sx={{
                fontSize: isSmallScreen ? "22px" : "25px",
                color: "rgba(19, 101, 175, 1)",
              }}
            />
            <InputLabel>
              <p
                style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
                className="my-auto gilroy fw-bold ms-2 text-black"
              >
                Last Name
              </p>
            </InputLabel>
          </div>
          <Box>
            <TextField
              id="lastname"
              size="small"
              fullWidth={true}
              value={lastname}
              onChange={(e) => handleFieldChange("lastname", e.target.value)}
              placeholder="Enter Last Name"
              error={!!lastnameError}
              helperText={lastnameError}
            />
          </Box>
        </div>
      </div>
      <div className="username-generate-container ms-2 my-2  px-2">
        <div className="username d-flex align-items-center p-0 mb-2">
          <PersonRoundedIcon
            sx={{
              fontSize: isSmallScreen ? "22px" : "25px",
              color: "rgba(19, 101, 175, 1)",
            }}
          />
          <InputLabel>
            <p
              style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
              className="my-auto gilroy fw-bold ms-2 text-black"
            >
              Create a username
            </p>
          </InputLabel>
        </div>

        <Box>
          <TextField
            id="username"
            size="small"
            fullWidth={true}
            value={username}
            onChange={(e) => handleFieldChange("username", e.target.value)}
            placeholder="Enter username"
            error={!!usernameError}
            helperText={usernameError}
          />
        </Box>
      </div>
      <div className="password-generate-container ms-2 mt-3 px-2 pb-4">
        <div className="password__label d-flex align-items-center mt-4">
          <KeyOutlinedIcon
            sx={{
              fontSize: isSmallScreen ? "23px" : "25px",
              color: "rgba(19, 101, 175, 1)",
              transform: "rotate(135deg)",
            }}
          />
          <p
            style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
            className="my-auto gilroy fw-bold ms-2"
          >
            Create a password
          </p>
        </div>
        <Box>
          {/* <TextField
            size="small"
            fullWidth={true}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => handleFieldChange("password", e.target.value)}
            error={!!passwordError}
            helperText={passwordError || ""}
            className=" mt-2 rounded w-100"
          /> */}

          <FormControl fullWidth variant="outlined" className="mt-2 w-100">
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => handleFieldChange("password", e.target.value)}
              error={!!passwordError}
              size="small"
              // helperText={passwordError || ""}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormHelperText error className="ps-3">
            {passwordError}
          </FormHelperText>
        </Box>
      </div>
    </>
  );
}

export default UserVerification;
