import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, useMediaQuery } from "@mui/material";
import { AddBoxRounded } from "@mui/icons-material";
import CategoriesButtons from "./CategoriesButton/CategoriesButtons";
import Categories from "./Categories/Categories";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";
import ProductSort from "./ProductSort/ProductSort";
import { productListAPI } from "../../../Config/apiConfig";
import axios from "axios";
import ProductFilter from "./ProductFilter/ProductFilter";

const MyProducts = () => {
  const navigate = useNavigate();

  const [sortAsc, setSortAsc] = useState("");
  const [sortData, setSortData] = useState([]);
  const [productCount, setProductCount] = useState(null);
  const [cateName, setCateName] = useState("");
  const [productFilter, setProductFilter] = useState("");

  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery("(max-width:320px) ");

  const handleAddProduct = () => {
    navigate("/add-products");
  };

  const handleProdCategoryId = (data) => {
    const categId = data;
    setSortAsc(categId);
  };

  const handleProductSort = (data) => {
    if (data) {
      setProductFilter(data);
    }
  };

  const handlePorductFilter = (data) => {
    if (data) {
      setProductFilter(data);
    }
  };

  const handleCateName = (data) => {
    if (data) {
      setCateName(data);
    } else {
      setCateName("");
    }
  };
  useEffect(() => {
    const fetchPorductSortAPI = async () => {
      const response = sessionStorage.getItem("loginCredentials");
      const parsedData = JSON.parse(response);
      // console.log(productList + sortAsc);
      if (!sortAsc) return;

      try {
        if (parsedData.token && sortAsc) {
          const response = await axios.get(productListAPI + sortAsc, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response?.data && response.status === 200) {
            // console.log("products Filter", response.data.data);
            setProductCount(response?.data.data.length);
            setSortData(response?.data.data);
          }
        }
      } catch (error) {
        console.log("All Services Error", error);
      }
    };
    fetchPorductSortAPI();
  }, [sortAsc]);

  return (
    <>
      <div className="my_products__container mt-4">
        <div className="add_my_product__button">
          <Button
            sx={{
              fontSize: isVerySmallScreen ? "15px" : "20px",
              color: "#1365AF",
              lineHeight: "28.8px",
              fontWeight: 400,
              backgroundColor: "#ECF0FF",
            }}
            onClick={handleAddProduct}
            className="gilroy py-2 py-sm-3  px-2 px-sm-5"
          >
            <AddBoxRounded sx={{ fontSize: isVerySmallScreen && "18px" }} />
            &nbsp;&nbsp;Add More Products
          </Button>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-start gap-4 mt-5">
          <div className="">
            <CategoriesButtons
              catID={handleProdCategoryId}
              catName={handleCateName}
            />
          </div>
          <VirticalStyleLine />
          <div className="categories_container w-100 ">
            <h4 className="categories_heading gilroy">
              Showing All <strong>({productCount})</strong>{" "}
              <span>{cateName}</span>
            </h4>
            <div
              className={`d-flex ${
                isExtraSmallScreen ? "flex-column" : "flex-row"
              }  gap-2 gap-sm-inherit justify-content-between mt-4`}
            >
              <ProductFilter onFilter={handlePorductFilter} />
              <ProductSort onCheck={handleProductSort} />
            </div>
            <Categories sortData={sortData} filter={productFilter} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProducts;
