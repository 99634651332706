import React, { useEffect, useState } from "react";
import "./CategoriesButtons.css";
import { Button, useMediaQuery } from "@mui/material";
import { productCategoriesAPI } from "../../../../Config/apiConfig";
import axios from "axios";

const CategoriesButtons = ({ catID, catName }) => {
  const [currentBtn, setCurrentBtn] = useState(1);
  const [categoriesTitle, setCategoriesTitle] = useState("");

  const isLargeScreen = useMediaQuery("(max-width:1024px)");
  const isMedLarScreen = useMediaQuery("(max-width:870px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  // Style for the active button
  const activeButtonStyle = {
    width: isMedLarScreen
      ? "12.6rem"
      : isLargeScreen
      ? "15.6rem"
      : window.innerWidth <= 576
      ? "16rem"
      : "16.6em",
    // maxWidth: "16rem !important",
    minWidth: isMedLarScreen
      ? "12.6rem"
      : isLargeScreen
      ? "15.6rem"
      : window.innerWidth <= 576
      ? "16rem"
      : "16.6em",
    fontSize: isMedLarScreen ? "16px" : "18px",
    fontWeight: 500,
    color: "#42474E",
    justifyContent: "start",
    backgroundColor: "#D6ECFF",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    textAlign: "start",
    "&:hover": {
      backgroundColor: "#D6ECFF",
    },
    border: "1px solid #1365AF",
  };

  // Style for the inactive button
  const inactiveButtonStyle = {
    width: isMedLarScreen ? "12.6rem" : isLargeScreen ? "15.6rem" : "16.6em",
    minWidth: "12rem !important",
    fontSize: isMedLarScreen ? "16px" : "18px",
    fontWeight: 500,
    color: "#42474E",
    justifyContent: "start",
    backgroundColor: "#FAF9FD",
    border: "1px solid #C2C7CF",
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Ensure text stays within the button
    textOverflow: "ellipsis",
    display: "inline-block",
    textAlign: "start",
  };

  useEffect(() => {
    const fetchProductCateAPI = async () => {
      try {
        const loginResponse = sessionStorage.getItem("loginCredentials");
        const parsedData = JSON.parse(loginResponse);
        if (parsedData) {
          const response = await axios.get(productCategoriesAPI, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response?.data && response.status === 200) {
            setCategoriesTitle(response.data.data);

            if (response.data.data.length > 0) {
              catID(response.data.data[0]._id);
              catName(response.data.data[0].name);
              setCurrentBtn(1);
            }
          }
        }
      } catch (error) {}
    };
    fetchProductCateAPI();
  }, []);

  // Handle button click and log _id
  const handleButtonClick = (id, index, name) => {
    catID(id);
    catName(name);
    setCurrentBtn(index + 1);
  };

  return (
    <>
      <div className="categories_buttons__container">
        <h4 className="categories_heading gilroy">Categories</h4>
        <div className="d-flex flex-row flex-md-column w-100 overflow-scroll no-scrollbar  gap-4 mt-4">
          {categoriesTitle &&
            categoriesTitle.map((item, index) => (
              <Button
                key={item._id} // Use _id as key if available
                sx={
                  currentBtn === index + 1
                    ? activeButtonStyle
                    : inactiveButtonStyle
                } // Conditionally apply styles
                varient="outlined"
                className="gilroy text-left px-3 py-2"
                onClick={() => handleButtonClick(item._id, index, item.name)} // Pass _id to the handler
              >
                <img src={item.path} alt="bug" style={{ width: "20px" }} />
                &nbsp;&nbsp;
                {item.name.length > 20
                  ? item.name.substring(0, 21) + "..."
                  : item.name}
              </Button>
            ))}
        </div>
      </div>
    </>
  );
};

export default CategoriesButtons;
