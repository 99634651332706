import React, { useEffect, useState } from "react";
import "./MyAuctionPaginate.css";
import { useNavigate } from "react-router-dom";
import auctionImage from "../../Rectangle 2057.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, useMediaQuery } from "@mui/material";
import { auctionContent } from "../myAuctionData";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MyAuctionFilter from "../MyAuctionFilter/MyAuctionFilter";
import MyAuctionSort from "../MyAuctionSort/MyAuctionSort";
import { custAuctionsListAPI } from "../../../Config/apiConfig";
import axios from "axios";

const itemsPerPage = 4;

const MyAuctionPaginate = () => {
  const navigate = useNavigate();

  // useStates ------------------------
  const [currentPage, setCurrentPage] = useState(0);
  const [auctionList, setAuctionList] = useState([]);

  // Screen size for responsiveness--------------------
  const isExtraSmallScreen = useMediaQuery("(max-width:320px) ");
  const isVerySmallScreen = useMediaQuery("(max-width: 426px) ");
  const isSmallScreen = useMediaQuery("(max-width: 576px)");
  // const isMediumScreen = useMediaQuery("(max-width: 768px) ");
  const islargeScreen = useMediaQuery("(max-width: 1024px)");
  const isMediumXLargeScreen = useMediaQuery(
    "(min-width: 568px) and (max-width:768px)"
  );

  // Styling -------------------
  const styleLabel = {
    fontSize: isVerySmallScreen ? "12px" : islargeScreen ? "14px" : "16px",
    color: "#343538",
  };
  const auctionInfo =
    " w-100 d-flex flex-column flex-md-row justify-content-center justify-content-md-start gap-2 gap-md-4 gap-lg-5 align-items-start align-items-sm-start my-2 my-lg-3 mx-0 mx-md-2 mx-lg-4 ps-0 ps-md-4 ps-lg-2 ";
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    window.scroll(0, 0);
  };

  //  Handling Show Bids Button -----------------------------
  const handleClick = () => {
    navigate("/my-auction-details");
  };

  useEffect(() => {
    const fetchAuctionListAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData?.token) {
        try {
          const response = await axios.get(custAuctionsListAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response?.data && response.status === 200) {
            console.log("auction list", response?.data);
            setAuctionList(response?.data?.data);
          }
        } catch (error) {
          console.log("error in auction cust api", error?.response?.data);
        }
      }
    };
    fetchAuctionListAPI();
  }, []);

  const handleMyAuctionFilter = (data) => {
    console.log(data);
  };

  const handleMyAuctionSort = (data) => {
    console.log(data);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = auctionList.slice(offset, offset + itemsPerPage);
  return (
    <div className="mt-5">
      <div className="my-5">
        <div
          className={`d-flex ${
            isExtraSmallScreen ? "flex-column" : "flex-row"
          }  gap-2 gap-sm-inherit justify-content-between mt-4`}
        >
          <MyAuctionFilter onCheck={handleMyAuctionFilter} />
          <MyAuctionSort onCheck={handleMyAuctionSort} />
        </div>
      </div>
      <div>
        {currentItems &&
          currentItems.map((item, index) => (
            <div
              key={index}
              // onClick={() => handleClick(item._id)}
              className="border p-2 p-sm-4 rounded mb-4 mt-2"
            >
              <div className="cust_order__details w-100 align-items-start align-items-lg-center  gap-4 ">
                <div className="cust_order_img__container">
                  <div className="cust_order_img">
                    <img
                      src={item?.childCatId?.path}
                      alt={item.title}
                      className="order_img"
                    />
                  </div>
                </div>

                <div className="cust_order_details_container">
                  <div className=" d-flex flex-column justify-content-between ">
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex justify-content-between align-items-center align-items-sm-start align-items-md-center">
                        <div className="d-flex gap-3">
                          <div>
                            <h4 className="order_title gilroy-regular m-0">
                              {item?.childCatId.name}
                            </h4>
                            <h5 className="sub_category_title gilroy-regular m-0">
                              {item?.categoryId.name}
                            </h5>
                          </div>
                          <div>
                            <p
                              style={{
                                color: "#2A9928",
                                fontSize: "16px",
                                lineHeight: "20px",
                                backgroundColor: "#D9F4D9",
                                borderRadius: "3px",
                              }}
                              className="cust_auction_status gilroy text-center px-1 py-1"
                            >
                              OPEN
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="cust_auction_details">{item?.details}</p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(auctionList.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </div>
  );
};

export default MyAuctionPaginate;
