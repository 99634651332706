import React, { useEffect, useState } from "react";
import { TextField, Select, MenuItem } from "@mui/material";
import "./ProductInfo.css";
import { profileCategoriesAPI } from "../../../../../Config/apiConfig";
import axios from "axios";

const ProductInfo = ({ onSave, isAdded }) => {
  const [token, setToken] = useState("");
  const [dropDownContent, setDropDownContent] = useState("");
  // handle form data
  const [productTitle, setProductTitle] = useState("");
  const [categories, setCategories] = useState("");
  const [prodDescription, setProdDescription] = useState("");
  const [productMRP, setProductMRP] = useState("");
  const [productFinalPrice, setProductFinalPrice] = useState("");
  const [productFeatures, setProductFeatures] = useState("");
  const [minQuantity, setMinQuantity] = useState("");
  const [maxQuantity, setMaxQuantity] = useState("");
  const [availableStock, setAvailableStock] = useState("");
  const [productSpecification, setProductSpecification] = useState("");

  useEffect(() => {
    if (isAdded) {
      setProductTitle("");
      setCategories("");
      setProdDescription("");
      setProductMRP("");
      setProductFinalPrice("");
      setProductFeatures("");
      setMinQuantity("");
      setMaxQuantity("");
      setAvailableStock("");
      setProductSpecification("");
    }
  }, [isAdded]);

  useEffect(() => {
    const formData = {
      productTitle,
      categories,
      prodDescription,
      productMRP,
      productFinalPrice,
      productFeatures,
      availableStock,
      productSpecification,
      minQuantity,
      maxQuantity,
    };
    onSave(formData);
  }, [
    prodDescription,
    productFeatures,
    productFinalPrice,
    productMRP,
    productSpecification,
    productTitle,
    availableStock,
    categories,
    minQuantity,
    maxQuantity,
  ]);

  const handleProductTitle = (event) => {
    setProductTitle(event.target.value);
  };
  const handleCategories = (event) => {
    setCategories(event.target.value);
  };

  const handleProdDescription = (event) => {
    setProdDescription(event.target.value);
  };

  const handleProductMRP = (event) => {
    setProductMRP(event.target.value);
  };
  const handleProdFinalPrice = (event) => {
    setProductFinalPrice(event.target.value);
  };
  const handleProductFeatures = (event) => {
    setProductFeatures(event.target.value);
  };
  const handleMinQuantity = (event) => {
    setMinQuantity(event.target.value);
  };
  const handleMaxQuantity = (event) => {
    setMaxQuantity(event.target.value);
  };

  const handleAvailableStock = (event) => {
    setAvailableStock(event.target.value);
  };
  const handleProductSpecification = (event) => {
    setProductSpecification(event.target.value);
  };

  useEffect(() => {
    const fetchProfileCategoriesAPI = async () => {
      try {
        const loginResponse = sessionStorage.getItem("loginCredentials");
        if (loginResponse) {
          const parsedData = JSON.parse(loginResponse);
          setToken(parsedData.token);

          const response = await axios.get(profileCategoriesAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          setDropDownContent(response.data.data);
        }
      } catch (error) {
        console.log("error in api ", error);
      }
    };

    fetchProfileCategoriesAPI();
  }, [token]);

  return (
    <>
      <div className="product_info_section">
        <div className="product_name__mrp d-flex flex-column flex-sm-row justify-content-between gap-4 mt-4">
          <div className="w-100">
            <h3 className="label-text gilroy">Product Title</h3>
            <TextField
              fullWidth
              size="small"
              value={productTitle}
              onChange={handleProductTitle}
              sx={{ backgroundColor: "#ffffff" }}
            />
          </div>

          <div className="w-100">
            <h3 className="label-text gilroy">Product Categories</h3>
            <Select
              value={categories}
              onChange={handleCategories}
              fullWidth
              size="small"
              sx={{ backgroundColor: "#ffffff" }}
            >
              <MenuItem value="">Select Categories</MenuItem>
              {dropDownContent &&
                dropDownContent.map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
        <div className="product_descrb__final_price__avai_stack d-flex  flex-column flex-sm-row justify-content-between gap-4 mt-4">
          <div className="w-100">
            <h3 className="label-text gilroy">Product Description</h3>
            <TextField
              multiline
              fullWidth
              size="small"
              value={prodDescription}
              onChange={handleProdDescription}
              rows={5}
              sx={{ backgroundColor: "#ffffff" }}
            />
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="w-100">
              <h3 className="label-text gilroy">Product MRP</h3>
              <TextField
                fullWidth
                size="small"
                value={productMRP}
                onChange={handleProductMRP}
                sx={{ backgroundColor: "#ffffff" }}
              />
            </div>

            <div className="w-100">
              <h3 className="label-text gilroy">Product Final Price</h3>
              <TextField
                fullWidth
                size="small"
                value={productFinalPrice}
                onChange={handleProdFinalPrice}
                sx={{ backgroundColor: "#ffffff" }}
              />
            </div>
          </div>
        </div>
        <div className="product_features__specifications d-flex flex-column flex-sm-row  justify-content-between gap-4 mt-4">
          <div className="w-100">
            <h3 className="label-text gilroy">Product Features</h3>
            <TextField
              multiline
              fullWidth
              rows={5}
              size="small"
              value={productFeatures}
              onChange={handleProductFeatures}
              sx={{ backgroundColor: "#ffffff" }}
            />
          </div>
          <div className="w-100 d-flex flex-column gap-4">
            <div className="w-100">
              <h3 className="label-text gilroy">Minimum Quantity</h3>
              <TextField
                fullWidth
                size="small"
                value={minQuantity}
                onChange={handleMinQuantity}
                sx={{ backgroundColor: "#ffffff" }}
              />
            </div>
            <div className="w-100 ">
              <h3 className="label-text gilroy">Maximum Quantity</h3>
              <TextField
                fullWidth
                size="small"
                value={maxQuantity}
                onChange={handleMaxQuantity}
                sx={{ backgroundColor: "#ffffff" }}
              />
            </div>
          </div>
        </div>
        <div className="w-100  d-flex gap-4 mt-4">
          <div className="w-100">
            <h3 className="label-text gilroy">Available Stock</h3>
            <TextField
              fullWidth
              size="small"
              value={availableStock}
              onChange={handleAvailableStock}
              sx={{ backgroundColor: "#ffffff" }}
            />
          </div>
          <div className="w-100 ">
            <h3 className="label-text gilroy">Product Specifications</h3>
            <TextField
              fullWidth
              size="small"
              value={productSpecification}
              onChange={handleProductSpecification}
              sx={{ backgroundColor: "#ffffff" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInfo;
