import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import "./HealthWellness.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setServiceSubCateIdItem } from "../../../../redux/serviceSubCateIdSlice";

const HealthWellness = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isData, setIsData] = useState(false);
  const [serviceName, setServiceName] = useState("");

  useEffect(() => {
    if (data?.health?.length > 0) {
      setIsData(true);
      setServiceName(data.health[0].name);
    } else {
      setIsData(false);
    }
  }, [data]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
    },
  };

  const handleSearchService = (id) => {
    dispatch(setServiceSubCateIdItem(id));
    navigate("/cust-products");
  };

  return (
    <Container
      maxWidth="lg"
      style={{
        borderBottom: "1px solid #E0E3E7",
        marginBottom: "80px",
        paddingBottom: "80px",
        display: isData ? "block" : "none",
      }}
      className="bsiness_service"
    >
      <Box
        className="d-flex justify-content-center mb-4 mb-lg-5"
        sx={{
          marginBottom: {
            lg: "46px",
          },
        }}
      >
        <Box
          className="gilroy-regular"
          sx={{
            fontSize: {
              xs: "25px",
              lg: "40px",
            },
            alignItems: "center",
            fontWeight: "600",
          }}
        >
          <span style={{ color: "#1365AF" }}>{serviceName}</span>
        </Box>
      </Box>

      {isData && data?.health[0]?.children?.length > 0 && (
        <div className="business_carousel">
          <Carousel
            infinite={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            responsive={responsive}
            removeArrowOnDeviceType={["mobile", "tablet"]}
          >
            {data.health[0].children.map((item) => (
              <div
                key={item._id}
                className="d-flex justify-content-center flex-column align-items-center"
                onClick={() => handleSearchService(item._id)}
              >
                <img
                  src={item.path}
                  alt={item.name}
                  className="item_image px-2"
                />

                <p
                  className="pb-1 px-0 pt-0 mx-0 mb-0 gilroy-regular"
                  style={{
                    marginTop: "19px",
                    color: "#000",
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  {item.name}
                </p>

                <p
                  className="p-0 m-0 gilroy-regular"
                  style={{
                    color: " #42474E",
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {item.description}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </Container>
  );
};

export default HealthWellness;
