import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import "./ServiceOffered.css";
import { useMediaQuery } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { sellerAddServiceOfferAPI } from "../../../../../Config/apiConfig";
import Pencil from "../../../../../Assets/Icons/pencil.png";
import axios from "axios";

function ServiceOffered({ profId, overviewData }) {
  const [serviceOffers, setServiceOffers] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [open, setOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  useEffect(() => {
    if (overviewData?.service_offer) {
      setServiceList(overviewData.service_offer.split(", "));
    }
  }, [overviewData]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddService = () => {
    if (serviceOffers.trim()) {
      setServiceList((prev) => [...prev, serviceOffers.trim()]);
      setServiceOffers("");
    }
  };

  const handleDeleteService = (index) => {
    setServiceList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    const loginResponse = sessionStorage.getItem("loginCredentials");
    const parsedData = JSON.parse(loginResponse);

    if (parsedData && profId) {
      try {
        const formData = {
          id: profId,
          service_offer: serviceList.join(", "),
        };
        const response = await axios.put(sellerAddServiceOfferAPI, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });

        if (response?.data && response.status === 200) {
          handleClose();
          window.location.reload();
          // console.log(response?.data);
        }
      } catch (error) {
        console.log("Error updating service offers", error);
      }
    }
  };

  return (
    <div className="mt-5">
      <div className="service_offered_box d-flex justify-content-between">
        <h4 className="service_offered_heading gilroy ">Service offered:</h4>
        <Button
          onClick={handleOpen}
          style={{
            backgroundColor: "rgb(238,238,244)",
            fontSize: "16px",
            height: "40px",
          }}
          className="rounded-pill text-capitalize gilroy"
        >
          <img src={Pencil} alt="edit" className="edit_pen_size" />
          <span className="d-none d-sm-inline">&nbsp;Edit</span>
        </Button>
      </div>
      <div className="d-flex gap-3 flex-row flex-wrap mt-3">
        {overviewData?.service_offer?.split(", ").map((service, index) => (
          <div
            key={index}
            className="p-2 px-3"
            style={{
              backgroundColor: "rgba(214, 236, 255, 1)",
              borderRadius: "5px",
            }}
          >
            {service}
          </div>
        ))}
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          <TextField
            fullWidth
            size="small"
            margin="dense"
            label="Service Offers"
            value={serviceOffers}
            onChange={(e) => setServiceOffers(e.target.value)}
          />
          <Button
            onClick={handleAddService}
            variant="contained"
            color="primary"
            className="mt-2"
          >
            Add
          </Button>
          <div className="mt-2 d-flex flex-row flex-wrap gap-2">
            {serviceList.map((service, index) => (
              <div
                key={index}
                className="d-flex align-items-center p-1"
                style={{
                  backgroundColor: "#f0f0f0",
                  borderRadius: "5px",
                  marginTop: "5px",
                  padding: "20px",
                }}
              >
                <span style={{ fontSize: "16px" }} className="gilroy p-1 px-2">
                  {service}
                </span>
                <IconButton
                  size="small"
                  onClick={() => handleDeleteService(index)}
                  style={{ marginLeft: "10px", color: "red" }}
                >
                  <ClearIcon sx={{ fontSize: "15px" }} />
                </IconButton>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ServiceOffered;
