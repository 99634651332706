import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@mui/material";

const ManageTaxAssessments = ({ onAddNewTaxAssessment }) => {
  const [assessmentData, setAssessmentData] = useState([]);

  // Screen Size for responsiveness ------------
  const isInBetween768To992px = useMediaQuery(
    "(min-width: 768px) and (max-width: 992px)"
  );
  const isLargeScreen = useMediaQuery("(max-width:992px)");
  const isMediumScreen = useMediaQuery("(max-width: 768px) ");
  const isVerySmallScreen = useMediaQuery("(max-width: 435px)");

  const tableHeadStyle = {
    fontSize: "13px",
    fontWeight: 600,
    color: "#ffffff",
    verticalAlign: "top",
  };

  useEffect(() => {
    const assessmentResponse = localStorage.getItem("taxAssessmentData");
    const parsedAssessment = JSON.parse(assessmentResponse) || [];
    console.log("assessment ", parsedAssessment);
    setAssessmentData(parsedAssessment);
  }, []);

  const handleDelete = (index) => {
    const newassessmentData = assessmentData.filter((_, i) => i !== index);
    // Update localStorage
    localStorage.setItem(
      "taxAssessmentData",
      JSON.stringify(newassessmentData)
    );
    setAssessmentData(newassessmentData);
  };

  const handleEdit = (index) => {
    onAddNewTaxAssessment(index);
  };

  const renderMessage = () => {
    const rowCount = assessmentData.length;
    if (rowCount === 0) {
      return (
        <TableCell colSpan={2} className="gilroy">
          No Records Found.
        </TableCell>
      );
    } else {
      return (
        <TableCell colSpan={2} className="gilroy">
          {rowCount} of {rowCount} Record{rowCount > 1 ? "s" : ""}
          &nbsp;Displayed
        </TableCell>
      );
    }
  };

  return (
    <>
      <div
        className="manage_tax_assessments__container"
        style={{ width: isInBetween768To992px ? "28rem" : "100%" }}
      >
        <div className="manage_tax_assessments__table mt-4">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead
                style={{
                  backgroundColor: "#9ABAFA",
                }}
              >
                <TableRow>
                  {/* <TableCell style={tableHeadStyle} className="gilroy">
                    Verification Status
                  </TableCell> */}
                  <TableCell style={tableHeadStyle} className="gilroy">
                    ITR Type/PAN
                  </TableCell>
                  <TableCell style={tableHeadStyle} className="gilroy">
                    Assessment Year
                  </TableCell>
                  <TableCell style={tableHeadStyle} className="gilroy">
                    Sale/Gross Recipts Of Business
                  </TableCell>
                  <TableCell style={tableHeadStyle} className="gilroy">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessmentData.map((row, index) => (
                  <TableRow key={index}>
                    {/* <TableCell
                      style={{ fontSize: "12px" }}
                      className="gilroy border-end"
                    >
                      {row.verificationStatus}
                    </TableCell> */}
                    <TableCell
                      style={{ fontSize: "12px", verticalAlign: "top" }}
                      className="gilroy border-end"
                    >
                      {row.itrType}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "12px", verticalAlign: "top" }}
                      className="gilroy border-end"
                    >
                      {row.assessmentYear}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "12px", verticalAlign: "top" }}
                      className="gilroy border-end"
                    >
                      {row.sales}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "12px", verticalAlign: "top" }}
                      className="gilroy d-flex gap-1 border-end"
                    >
                      <Button
                        style={{
                          textTransform: "none",
                          padding: "3px 5px",
                          border: "0.5px solid #1365AF",
                          fontSize: "11px",
                        }}
                        onClick={() => handleDelete(index)}
                      >
                        Delete
                      </Button>
                      <Button
                        onClick={() => handleEdit(index)}
                        style={{
                          textTransform: "none",
                          padding: "3px 5px",
                          border: "0.5px solid #1365AF",
                          fontSize: "11px",
                        }}
                      >
                        Edit
                      </Button>
                      {/* <Button
                        style={{
                          textTransform: "none",
                          padding: "3px 5px",
                          border: "0.5px solid #1365AF",
                          fontSize: "11px",
                        }}
                      >
                        Verify
                      </Button> */}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  {renderMessage()}
                  <TableCell colSpan={2} align="right">
                    <Button
                      varient="contained"
                      style={{
                        textTransform: "none",
                        backgroundColor: "#fa9b00",
                        color: "#ffffff",
                        padding: "3px 8px",
                      }}
                      onClick={onAddNewTaxAssessment}
                    >
                      Add New Tax Assessment
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default ManageTaxAssessments;
