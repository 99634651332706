import React, { useEffect, useState } from "react";
import "./Certification.css";
import {
  Button,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";

import { sellerCertificatesEditAPI } from "../../../../../Config/apiConfig";
import axios from "axios";

function Certification({ certificateData, businessID }) {
  const [certificates, setCertificates] = useState([]);
  const [open, setOpen] = useState(false);
  const [newImages, setNewImages] = useState([]);
  const [deletedCertificates, setDeletedCertificates] = useState([]);
  const [error, setError] = useState("");

  const isSmallScreen = useMediaQuery("(max-width:576px)");

  // console.log("certificates", certificateData);
  useEffect(() => {
    if (certificateData) {
      setCertificates(certificateData);
    }
  }, [certificateData]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setNewImages([]); // Reset images when closing dialog
    setError(""); // Clear any error message
    setOpen(false);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    console.log("files Array", Array.from(e.target.files));
    files.forEach((image) => {
      console.log("name", image.name);
    });

    if (files.length + newImages.length > 6) {
      setError("You can upload a maximum of 6 images.");
      console.log(files.name);
    } else {
      setError("");
      setNewImages((prevImages) => [...prevImages, ...files]);
    }
  };

  const handleImageDelete = (index) => {
    setNewImages(newImages.filter((_, i) => i !== index));
  };

  // const handleExistingDelete = (index) => {
  //   setCertificates(certificates.filter((_, i) => i !== index));
  // };

  const handleSave = async () => {
    const loginResponse = sessionStorage.getItem("loginCredentials");
    const parsedData = JSON.parse(loginResponse);
    if (parsedData) {
      try {
        const formData = new FormData();

        newImages.forEach((image) => {
          formData.append("image", image);
        });
        formData.append("id", businessID);
        console.log("FormData entries:", [...formData.entries()]);
        const response = await axios.patch(
          sellerCertificatesEditAPI,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${parsedData.token}`,
            },
          }
        );
        if (response?.data && response.status === 200) {
          handleClose();
          window.location.reload();
        }
      } catch (error) {
        console.log("Error updating certificates", error);
      }
    }
  };

  const StyledIconButton = styled(IconButton)({
    position: "absolute",
    top: "5px",
    right: "5px",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    borderRadius: "50%",
    padding: "5px",
    zIndex: 10,
  });

  return (
    <div className="mt-5 px-0 pb-0 pt-5 mb-0">
      <div className="d-flex justify-content-between gilroy">
        <h4 className="fw-bold fs-4 gilroy text-black">
          Certifications & Accreditations
        </h4>

        <div>
          <Button
            onClick={handleOpen}
            style={{
              backgroundColor: "rgb(238,238,244)",
              fontSize: isSmallScreen ? "14px" : "16px",
              width: isSmallScreen ? "20px" : "80px",
              height: "40px",
            }}
            className="rounded-pill text-capitalize gilroy"
          >
            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "20px" }} />
            <span className="d-none d-sm-inline">&nbsp;Add</span>
          </Button>
        </div>
      </div>
      <div className="certificates_boxes certificates-container mt-4">
        {certificates?.map((image, index) => (
          <div className="certificates_slider certificate-item" key={index}>
            <img src={image} alt={`certificate-${index}`} />
          </div>
        ))}
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          <div className="d-flex flex-column gap-3">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            {error && <div className="text-danger">{error}</div>}

            <div className="dialog-image-grid">
              {/* Show existing certificates with delete button */}

              {/* Show newly uploaded images with delete button */}
              {newImages.map((image, index) => (
                <div
                  key={`new-${index}`}
                  className="position-relative image-container"
                >
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`new-upload-${index}`}
                    className="uploaded-image"
                  />

                  <StyledIconButton onClick={() => handleImageDelete(index)}>
                    <DeleteIcon fontSize="inherit" sx={{ color: "red" }} />
                  </StyledIconButton>
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Certification;
