import React, { useEffect, useState } from "react";
import "./Categories.css";
import { Button, Checkbox, useMediaQuery } from "@mui/material";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";

import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import CheckCircle from "../../../../Assets/Icons/check.png";
import Location from "../../../../Assets/Icons/locationgrey.png";
import { Favorite, FavoriteBorder } from "@mui/icons-material";

import {
  custServiceWishListAPI,
  custServiceListAPI,
} from "../../../../Config/apiConfig";

import axios from "axios";

const itemsPerPage = 5;
const Categories = ({ custSortSerData, sorting }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [wishListId, setWishListId] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false); // New state variable
  const [custToken, setCustToken] = useState(null);
  const [expandedDetails, setExpandedDetails] = useState({});

  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 420px)");

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const toggleDetails = (id) => {
    setExpandedDetails((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {}, [custSortSerData]);

  const sortedServiceCatelogue =
    sorting === "Newest"
      ? [...custSortSerData].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      : sorting === "Oldest"
      ? [...custSortSerData].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      : [...custSortSerData];

  const offset = currentPage * itemsPerPage;
  const currentItems = sortedServiceCatelogue.slice(
    offset,
    offset + itemsPerPage
  );

  return (
    <>
      <div className="service_catelogue__container w-100">
        <section className="service_catelogue_section mt-4">
          {currentItems.length > 0 ? (
            currentItems?.map((item) => (
              <div
                key={item?._id}
                className="services_img__container w-100 border mb-5 p-2 p-sm-4"
              >
                <div className="services__img-box result-content__box overflow-hidden border">
                  <img
                    src={item?.service}
                    alt={item?.title}
                    className="services__img"
                  />
                </div>
                <div className="d-flex flex-row gap-1 justify-content-between mt-2 mt-sm-3">
                  <div className="inline-block d-flex flex-column gap-1 gap-sm-2 align-items-start">
                    <div className="service_catelogue__details d-flex align-items-start align-items-sm-center gap-2 ">
                      <h4 className=" gilroy text-lg-start m-0 ">
                        {item?.title}
                      </h4>
                      <p className="checker gilroy px-1 text-center m-0">
                        <img
                          src={CheckCircle}
                          alt="status"
                          style={{
                            width: isSmallScreen ? "12px" : "16px",
                            paddingBottom: "2px",
                          }}
                        />
                        &nbsp;
                        <span>
                          {item?.status === "Approved"
                            ? "Certified"
                            : "Not Certified"}
                        </span>
                      </p>
                    </div>
                    <div className="service_catelogue">
                      <p className="service_catelogue__address m-0 p-0 gilroy">
                        <img
                          src={Location}
                          alt="status"
                          style={{
                            width: isSmallScreen ? "14px" : "20px",
                            paddingBottom: "2px",
                          }}
                        />{" "}
                        {item?.address[0]?.buildingVillage}{" "}
                        {item?.address[0]?.flatBlockNo}{" "}
                        {item?.address[0]?.street},{" "}
                        {item?.address[0]?.townCityDistrict},{" "}
                        {item?.address[0]?.state} {item?.address[0]?.pincode}
                      </p>
                    </div>
                  </div>
                  <div className="" style={{ width: "8rem" }}>
                    <p className="categories__ratings text-end d-flex justify-content-center align-items-center m-0 p-0">
                      <span>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{
                            color: "#fa9b00",
                            fontSize: isSmallScreen ? "16px" : "18px",
                          }}
                        />
                      </span>
                      &nbsp;
                      <span className="ratings__outof fw-bold gilroy">
                        {item?.star}/5
                      </span>
                    </p>
                    <p className="categories__reviews text-center m-0 p-0 ">
                      {item?.totalReview} reviews
                    </p>
                  </div>
                </div>
                <div className="mt-2 mt-sm-4">
                  <div className="d-flex flex-row flex-wrap gap-2">
                    {item?.profileData?.service_offer
                      ?.split(",")
                      .map((service, index) => (
                        <p
                          key={index}
                          className="service-catelogue-other-giving m-0 "
                        >
                          {service}
                        </p>
                      ))}
                  </div>
                  <div className="d-flex align-items-start mt-2">
                    <p className="service_catelogue_details_para  p-0 m-0">
                      {expandedDetails[item._id]
                        ? item.details || "No details available"
                        : (item.details
                            ? item.details.split(" ").slice(0, 6).join(" ")
                            : "No details available") + "..."}
                      &nbsp;&nbsp;
                      <button
                        className="service-pare-expand"
                        onClick={() => toggleDetails(item._id)}
                      >
                        {expandedDetails[item._id] ? "Less" : "More"}
                      </button>
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between mt-2 mt-sm-4 gap-2 w-100">
                  <div className="d-flex flex-column flex-sm-row align-itmes-center gap-2 w-100 ">
                    <p className="service_catelogue__response text-center gilroy m-0">
                      <strong>Response:</strong> <span>Within an hour</span>
                    </p>
                    <p className="service_catelogue--time text-center gilroy m-0">
                      <strong>Open:</strong> <span>9 AM to 11 PM</span>
                    </p>
                  </div>
                  <div className="">
                    <Button
                      sx={{
                        border: "1px solid rgba(194, 199, 207, 1)",
                        width: "36px",
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "4px",
                        padding: "0",
                        minWidth: "36px",
                      }}
                      className="share__btn "
                    >
                      <ShareRoundedIcon
                        style={{
                          width: isSmallScreen ? "18px" : "20px",
                          height: isSmallScreen ? "16px" : "20px",
                          color: "#6a7d9f",
                        }}
                      />
                    </Button>
                    {/* <Button
                      variant="contained"
                      size="small"
                      className="gilroy"
                      sx={{ fontSize: "14px", width: "8rem" }}
                    >
                      Request Quote
                    </Button> */}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center mt-4">No data available at the moment</p>
          )}
        </section>
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(sortedServiceCatelogue.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
};

export default Categories;
