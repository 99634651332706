import React, { useEffect, useState } from "react";
import "./WishLists.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  custAddFavouriteAPI,
  custAddToCartAPI,
} from "../../../Config/apiConfig";
import axios from "axios";
import NotLike from "../../../Assets/Icons/oulineHeart.png";
import Like from "../../../Assets/Icons/redHeart.png";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const WishLists = () => {
  const navigate = useNavigate();

  const [wishLists, setWishLists] = useState([]);
  const [favorites, setFavorites] = useState({});
  const [checkedWishList, setCheckedWishList] = useState([]);

  const handleCustProductId = (id) => {
    navigate("/buyer-product-details", { state: { prodID: id } });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(amount);
  };

  useEffect(() => {
    const fetchWishListAPI = async () => {
      const custResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custResponse);
      if (parsedData?.token) {
        try {
          const response = await axios.get(custAddFavouriteAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });

          if (response?.data && response.status === 200) {
            console.log("Wish List", response?.data);
            setWishLists(response?.data.data);
            setCheckedWishList(response?.data.data);
          }
        } catch (error) {
          console.error("Error fetching wishlist:", error);
        }
      }
    };
    fetchWishListAPI();
  }, []);

  const toggleFavorite = async (productId) => {
    const response = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(response);

    if (parsedData?.token) {
      try {
        const response = await axios.get(custAddFavouriteAPI + productId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });

        if (response?.data && response.status === 200) {
          console.log("wish list ok", response?.data);
          setFavorites((prev) => ({
            ...prev,
            [productId]: !prev[productId],
          }));
          window.location.reload();
        }
      } catch (error) {
        console.error("Error in adding to favorites:", error);
      }
    }
  };

  useEffect(() => {
    const favoriteMap = {};
    checkedWishList.forEach((item) => {
      favoriteMap[item._id] = true;
    });
    setFavorites(favoriteMap);
  }, [checkedWishList]);

  const isFavorite = (productId) => favorites[productId] || false;

  const handleAddToCartBtn = async (id, quantity) => {
    try {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);

      if (parsedData && parsedData.token) {
        const response = await axios.post(
          custAddToCartAPI,
          {
            id: id,
            qty: String(quantity),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          }
        );

        if (response && response.status === 200) {
          toast.success(response.data.msg);
        }
      } else {
        toast.warn("Please login before adding to cart your product");
      }
    } catch (error) {}
  };

  return (
    <div id="wish_list_container">
      <Container>
        <Row>
          {wishLists?.map((item) => (
            <Col key={item._id} xs={12} sm={6} md={4} className="mb-3">
              <div className="product_card__content border rounded p-3">
                <div onClick={() => handleCustProductId(item._id)}>
                  <div className="wish_list_image position-relative">
                    <button
                      className="favorite-icon btn btn-link position-absolute"
                      style={{ top: "2%", right: "2%" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavorite(item._id);
                      }}
                    >
                      <img
                        src={isFavorite(item._id) ? Like : NotLike}
                        alt="favorite"
                        style={{ width: "18px" }}
                      />
                    </button>
                    <img
                      src={item.image}
                      alt={item.title}
                      className="img-fluid"
                    />
                  </div>
                  <div className="product_info_section mt-2">
                    <p className="product__title gilroy-regular">
                      {item.title}
                    </p>

                    <div className="product__price_content d-flex gap-2 align-items-center mt-3">
                      <span className="orignal__prod_price text-decoration-line-through">
                        {formatCurrency(item.mrp)}
                      </span>
                      <span className="disconted__prod_price">
                        {formatCurrency(item.finalPrice)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-100 mt-2">
                  <Button
                    variant="contained"
                    sx={{ textTransform: "none" }}
                    fullWidth
                    onClick={() => handleAddToCartBtn(item._id, item.minQty)}
                  >
                    Add To Cart
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default WishLists;
