import React, { useEffect, useState } from "react";
import "./AddServiceDetails.css";
import {
  ArrowBackRounded,
  KeyboardArrowDown,
  ConstructionRounded,
} from "@mui/icons-material";
import {
  TextField,
  Autocomplete,
  styled,
  Box,
  useMediaQuery,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import SubCategories from "./MultilevelDropdown/SubCategories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { profileCategoriesAPI } from "../../../../Config/apiConfig";
// import { loginCredentials } from "../../ZReusableData/ResuableData";
import { createServices } from "../../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddServiceDetails = () => {
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [token, setToken] = useState("");
  const [dropDownContent, setDropDownContent] = useState("");
  const [childCategoryDetailId, setChildCategoryDetialId] = useState("");
  const [childCategoryDetailName, setChildCategoryDetailName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [license, setLicense] = useState(null);
  const [service, setService] = useState(null);
  // const [documentDetailsData, setDocumentDetailsData] = useState("");

  const isVerySmallScreen = useMediaQuery("(max-width:568px) ");

  const CustomTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
  });

  const handleDocumentDetailsData = async (data) => {
    setChildCategoryDetialId(data.childCategoryId);
    setChildCategoryDetailName(data.childCategoryName);
    setLicense(data.licenseDocument);
    setService(data.serviceDocument);
  };

  useEffect(() => {
    if (
      !categoryId ||
      !childCategoryDetailId ||
      !categoryName ||
      !childCategoryDetailName ||
      !license ||
      !service
    ) {
      return; // Prevent API call if any value is missing
    }

    const sendData = async () => {
      const formData = new FormData();
      formData.append("categoryId", categoryId);
      formData.append("childCategoryId", childCategoryDetailId);
      formData.append("categoryName", categoryName);
      formData.append("childCategoryName", childCategoryDetailName);
      formData.append("license", license);
      formData.append("service", service);

      console.log("Form Data: ", formData);

      const loginResponse = sessionStorage.getItem("loginCredentials");

      const parsedData = JSON.parse(loginResponse);
      if (!parsedData?.token) {
        console.error("Invalid token");
        return;
      }

      try {
        const response = await axios.post(createServices, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });

        if (response?.data && response.status === 200) {
          toast.success(response.data.msg);
          setLicense(null);
          setService(null);
        }
      } catch (error) {
        console.error("Error in API call: ", error);
        if (error?.response?.status === 400) {
          console.log(error?.response.data);
          toast.warn(error?.response?.data.msg);
        }
      }
    };

    sendData();
  }, [
    categoryId,
    childCategoryDetailId,
    categoryName,
    childCategoryDetailName,
    license,
    service,
  ]);

  // console.log("service", service);

  useEffect(() => {
    const fetchProfileCategoriesAPI = async () => {
      try {
        const loginResponse = sessionStorage.getItem("loginCredentials");
        const parsedData = JSON.parse(loginResponse);
        if (parsedData?.token) {
          const response = await axios.get(profileCategoriesAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response?.data) {
            console.log("categories data", response.data);
            setDropDownContent(response.data.data);
          }
        }
      } catch (error) {
        console.log("error in api ", error);
      }
    };
    fetchProfileCategoriesAPI();
  }, [token]);

  // Log item._id and item.name on click of service card
  const handleCardClick = (item) => {
    setCategoryId(item._id);
    setCategoryName(item.name);
    console.log("Service ID:", item._id);
    console.log("Service Name:", item.name);
  };

  // Handling Select Dropdowns Items
  const handleSelect = (event, value) => {
    if (value && !selectedItems.includes(value)) {
      setSelectedItems([...selectedItems, value]);
      setInputValue("");
    }
  };

  const handleRemove = (item) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem !== item)
    );
  };

  return (
    <>
      <div className="profile__container mb-5">
        <div className="d-flex gap-2 gap-sm-3">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-1 fw-bold"
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold gilroy"
            >
              Add Services
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/dashboard"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Dashboard /{" "}
              </Link>
              <Link
                underline="none"
                href={"/admin-products-services"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Products & Services
              </Link>{" "}
              / Add Services
            </p>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#ffffff" }}
          className="border rounded mt-5 px-2 py-4 p-sm-4"
        >
          <div className="d-flex gap-2 align-items-center">
            <ConstructionRounded
              sx={{
                fontSize: isVerySmallScreen ? "24px" : "32px",
                color: "#275ea7",
              }}
            />
            <h2 className="service_catalouge gilroy m-0">Services Catalogue</h2>
          </div>
          <div
            className={`search_categories border rounded ps-3 ${
              isVerySmallScreen ? "w-100" : "w-50"
            } my-3 d-flex align-items-center mx-auto `}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              style={{ color: "#6A7D9F", fontSize: "20px" }}
            />
            <Autocomplete
              disablePortal
              options={top100Films}
              size="small"
              fullWidth
              popupIcon={<KeyboardArrowDown sx={{ fontSize: "20px" }} />}
              onChange={handleSelect}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <CustomTextField {...params} fullWidth={true} />
              )}
              clearOnBlur
            />
          </div>
          <div sx={{ flexGrow: 1, marginTop: 5, marginBottom: 5 }}>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              {selectedItems.map((item, index) => (
                <Grid key={index} xs={4}>
                  <span className="search_selected__options mx-3 rounded d-flex flex-row align-items-center justify-content-between py-1 ps-2 pe-4">
                    <FontAwesomeIcon
                      icon={faXmark}
                      style={{ color: "#001e2f", fontSize: "10px" }}
                      onClick={() => handleRemove(item)}
                    />
                    <span className="search_seleted__title">{item.label}</span>
                  </span>
                </Grid>
              ))}
            </Grid>
          </div>

          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={5} justifyContent="center">
              {dropDownContent &&
                dropDownContent.map((item, index) => (
                  <Grid
                    key={index}
                    xs={12}
                    sm={6}
                    lg={4}
                    sx={{ padding: "20px 10px" }}
                  >
                    <div
                      className="service_category_dropdown__card d-flex flex-column justify-content-center align-items-center p-2 rounded"
                      onClick={() => handleCardClick(item)} // Handle card click here
                    >
                      <img
                        src={item.path}
                        alt={item.name}
                        className="profile_img"
                      />
                      <h4 className="service_card_title my-4 gilroy">
                        {item.name}
                      </h4>
                      <div className="w-100">
                        <SubCategories
                          itemsData={item}
                          onSave={handleDocumentDetailsData}
                        />
                      </div>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default AddServiceDetails;

const top100Films = [
  { label: "Fight Club", year: 1999 },

  { label: "Toy Story", year: 1995 },
  { label: "Bicycle Thieves", year: 1948 },
  { label: "The Kid", year: 1921 },
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];
