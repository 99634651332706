import React from "react";
import "./DescriptionBox.css";
import { useMediaQuery, Rating, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const DescriptionBox = ({ data, recommended }) => {
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 644 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 644, min: 0 },
      items: 1,
    },
  };

  console.log("description", data);
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");
  return (
    <>
      <div className="product__description_box pt-2 pt-sm-5">
        <div className="description__heading_and_content ">
          <h2 className="description_heading gilroy">Description</h2>
          <p className="description_content gilroy">{data.description}</p>
        </div>
        <div className="features__heading_and_content pt-4">
          <h4 className="features__heading gilroy">Features</h4>
          <p className="description_content gilroy">{data.features}</p>

          <div className="specifications__heading_and_content mt-5">
            <h4 className="specifications__heading gilroy">Specifications</h4>
            <p className="description_content gilroy">{data.specifications}</p>
          </div>
        </div>
        <div className="recommended__products mt-5">
          <div className="recommeded__see_more">
            <h1 className="rcommended_heading gilroy">Recommended Products</h1>
            <div className="see_more__btn">
              <Button
                onClick={() => {
                  navigate("/cust-products");
                }}
                variant="contained"
                size="small"
                className="gilroy"
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#001B3D",
                  backgroundColor: "#D0DFFF",
                  padding: "8px 15px",
                }}
              >
                See All
              </Button>
            </div>
          </div>
          <div className="recommended__content mt-4 pt-2">
            <div className="recommended_product_card__container p-0">
              <Carousel
                infinite={true}
                autoPlaySpeed={3000}
                autoPlay={true}
                responsive={responsive}
                transitionDuration={1000}
                removeArrowOnDeviceType={["mobile", "tablet"]}
              >
                {recommended &&
                  recommended.map((item) => (
                    <div key={item._id} className="py-3 px-3 mx-2">
                      <div className="product_card__content border rounded p-3">
                        <div className="product_img_section">
                          <img
                            src={item.image}
                            alt={item.title}
                            className="product__img"
                          />
                        </div>
                        <div className="product_info_section mt-2 w-100">
                          <p className="product__title gilroy-regular">
                            {item.title}
                          </p>
                          <p className="product__quantity m-0 gilroy">
                            Min. Qty: 2
                          </p>
                          <div className="ratings mt-2 d-flex gap-2 align-items-ceter">
                            <Rating
                              sx={{
                                fontSize: isLargeScreen ? "18px" : "20px",
                                marginTop: 0.5,
                              }}
                              name="half-rating-read"
                              readOnly
                              defaultValue={2.5}
                              precision={0.5}
                            />
                            <p className="ratings__viewers gilroy m-0 pt-1">
                              (12.3k)
                            </p>
                          </div>
                          <div className="product__price_content d-flex gap-2 align-items-center mt-3">
                            <span className="orignal__prod_price text-decoration-line-through">
                              &#8377; {item.mrp}
                            </span>
                            <span className="disconted__prod_price">
                              &#8377; {item.finalPrice}
                            </span>
                          </div>
                        </div>
                        <div className="delivery d-flex gap-2 align-items-center mt-2">
                          <img
                            src="icons/delivery-car.png"
                            alt="delivery_icon"
                            className="delivery_icon m-0"
                          />
                          <p className="delivery_text gilroy m-0">
                            Delivery in 4 to 5 days.
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DescriptionBox;
