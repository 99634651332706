import React, { useState, useEffect } from "react";
import "./Details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faWallet,
  faClock,
  faWandMagicSparkles,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import { Button } from "@mui/material";
import DegitalSign from "./DegitalSign/DegitalSign";
import { ClearRounded } from "@mui/icons-material";
import { custAuctionPlaceBidAPI } from "../../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";

function Details() {
  const [bidId, setBidId] = useState("");
  const [custAuctionDetails, setCustAuctionDetails] = useState({});
  const [isBidPopupOpen, setIsBidPopupOpen] = useState(false);
  const [sign, setSign] = useState(null);

  // console.log("auctionDetails", data);

  const handlePlaceBidClick = (id) => {
    setIsBidPopupOpen(true);
    console.log("bid id", id);
    const bidid = id;
    setBidId(bidid);
  };

  const handleCloseBidPopup = () => {
    setIsBidPopupOpen(false);
  };

  const handleSign = (data) => {
    // console.log("sign", data);

    setSign(data);
  };

  useEffect(() => {
    const custAuctionDetailsResponse =
      localStorage.getItem("custAuctionDetails");
    const parsedData = JSON.parse(custAuctionDetailsResponse);
    console.log("custAuctionDetailsResponse", parsedData);
    setCustAuctionDetails(parsedData);
  }, []);

  useEffect(() => {
    const fetchCustAuctionPlaceBidAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");

      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData && bidId) {
        try {
          const response = await axios.put(
            custAuctionPlaceBidAPI + bidId,
            {
              image: sign,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${parsedData.token}`,
              },
            }
          );
          if (response && response.status === 200) {
            console.log(response);
            toast.success(response.data.msg);
          }
        } catch (error) {
          if (error && error.response.status === 400) {
            toast.warn(error.response.data.error);
          }
        }
      }
    };
    fetchCustAuctionPlaceBidAPI();
  }, [bidId, sign]);

  return (
    <>
      <div className="auction-all-details px-5 d-flex flex-column align-items-center">
        <div className=" px-5">
          <div className=" my-4">
            <h5 className="details-titles title-name fs-4 fw-bold gilroy mb-0">
              {custAuctionDetails?.categoryName}
            </h5>
            <p className="all-details fs-5 fw-bold m-0 p-0">
              {custAuctionDetails?.serviceName}
            </p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faWallet}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles budget fs-4 fw-bold gilroy mb-0">
                Budget
              </h5>
            </div>
            <p className="all-details fs-5 ">
              {custAuctionDetails?.budget} Rupees
            </p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles budget fs-4 fw-bold gilroy mb-0">
                Preferred Date & Time:
              </h5>
            </div>
            <p className="all-details fs-5 ">
              {custAuctionDetails?.date} | {custAuctionDetails?.time}
            </p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ color: "#1365af", fontSize: "20px" }}
              />

              <h5 className="details-titles mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Address:
              </h5>
            </div>
            <p className="all-details fs-5 ">{custAuctionDetails?.address}</p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faClock}
                style={{ color: "#1365af", fontSize: "20px" }}
              />

              <h5 className="details-titles auction-duration mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Auction Duration:
              </h5>
            </div>
            <p className="all-details fs-5 ">
              {custAuctionDetails?.auction_duration} Days
            </p>
          </div>
          {/* <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faReceipt}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles auction-duration mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Package includes:
              </h5>
            </div>
            <ul className="all-details fs-5 ">
              <li>Cobweb removal</li>
              <li>
                Common Pest Coverage (Ants, Cockroaches, Spider & Mice and Rats)
              </li>
              <li>Seasonal Pest Protection (Wasps, mosquitoes etc.)</li>
            </ul>
          </div> */}
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faWandMagicSparkles}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles auction-duration mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Special Instructions:
              </h5>
            </div>
            <p className="all-details fs-5 ">
              {custAuctionDetails?.instruction}
            </p>
          </div>
        </div>

        <Button
          fullWidth={false}
          style={{
            backgroundColor: "rgba(250, 155, 0, 1)",
            padding: "8px 15px",
          }}
          className="text-capitalize text-white fs-5"
          onClick={() => handlePlaceBidClick(custAuctionDetails?._id)}
        >
          Place a Bid
        </Button>

        <Popup open={isBidPopupOpen} onClose={handleCloseBidPopup} modal nested>
          <div className="popup_content py-4">
            <div className="popup-content mx-4 d-flex align-items-center gap-2">
              <ClearRounded
                sx={{ width: 30, height: 30 }}
                onClick={handleCloseBidPopup}
              />
              <h2 className="digital_sign__heading gilroy-regular m-0">
                Please sign to confirm your bid!
              </h2>
            </div>
            <DegitalSign onSign={handleSign} />
          </div>
        </Popup>
      </div>
    </>
  );
}

export default Details;
