import React from "react";
import { Button, useMediaQuery } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

function OrderQuotesButton({ onViewChange, currentView }) {
  // Screen Size for responsiveness

  const isVerySmallScreen = useMediaQuery(" (max-width: 420px) ");

  console.log(onViewChange, currentView);

  return (
    <>
      <div
        className={`orders-button--quotes-sent-button d-flex justify-content-center mt-5 mb-4 mx-sm-5 px-sm-5`}
      >
        <Button
          fullWidth
          sx={{
            width: isVerySmallScreen ? "100%" : "22rem",
            backgroundColor:
              currentView === "orders"
                ? "rgba(214, 236, 255, 1)"
                : "transparent",

            "&:hover": {
              backgroundColor: "rgba(214,236,255,1)",
            },
            color: "rgba(0, 30, 47, 1)",
            fontSize: isVerySmallScreen ? "14px" : "16px",
          }}
          className={`orders-button border border-end-0 rounded-end rounded-pill border-secondary text-capitalize gilroy`}
          onClick={() => onViewChange("orders")}
        >
          {currentView === "orders" ? (
            <DoneRoundedIcon fontSize="small" style={{ fontSize: "20px" }} />
          ) : (
            ""
          )}
          Orders
        </Button>
        <Button
          fullWidth
          sx={{
            width: isVerySmallScreen ? "100%" : "22rem",
            backgroundColor:
              currentView === "quotes"
                ? "rgba(214, 236, 255, 1)"
                : "transparent",

            "&:hover": {
              backgroundColor: "rgba(214,236,255,1)",
            },
            color: "rgba(0, 30, 47, 1)",
            fontSize: isVerySmallScreen ? "14px" : "16px",
          }}
          className={`quotes-sent-button text-capitalize border rounded-start rounded-pill border-secondary gilroy`}
          onClick={() => onViewChange("quotes")}
        >
          {currentView === "quotes" ? (
            <DoneRoundedIcon fontSize="small" style={{ fontSize: "20px" }} />
          ) : (
            ""
          )}
          Quotes Sent
        </Button>
      </div>
    </>
  );
}

export default OrderQuotesButton;
