import React, { useEffect, useState, useRef } from "react";
import "./BuyersAccounts.css";
import { ArrowBackRounded } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Link,
  TextField,
  useMediaQuery,
  styled,
} from "@mui/material";
import AccountInfo from "./AccountInfo/AccountInfo";
import Pencil from "../../Assets/Icons/pencil.png";
import {
  custProfileAPI,
  custProfileEditAPI,
  custProfileImageEditAPI,
} from "../../Config/apiConfig";
import axios from "axios";

const BuyersAccounts = () => {
  const [profileData, setProfileData] = useState({});
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mobile, setMobile] = useState("");
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({ fname: "", lname: "", mobile: "" });

  const fileUploadRef = useRef();

  const isMediumScreen = useMediaQuery("(max-width:768px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    const fetchCustProfileAPI = async () => {
      const custResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custResponse);
      if (parsedData.token) {
        try {
          const response = await axios.get(custProfileAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response?.data && response.status === 200) {
            setProfileData(response?.data.data);
          }
        } catch (error) {
          console.log("Error in Cust profile", error.response);
        }
      }
    };
    fetchCustProfileAPI();
  }, []);

  useEffect(() => {
    setFname(profileData?.fname);
    setLname(profileData?.lname);
    setMobile(profileData?.number);
  }, [profileData]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validateFields = () => {
    let newErrors = { fname: "", lname: "", mobile: "" };

    if (!fname.trim()) newErrors.fname = "First name is required";
    else if (fname.length > 50) newErrors.fname = "Max 50 characters allowed";
    else if (/\d/.test(fname)) newErrors.fname = "Numbers not allowed";

    if (!lname.trim()) newErrors.lname = "Last name is required";
    else if (lname.length > 50) newErrors.lname = "Max 50 characters allowed";
    else if (/\d/.test(lname)) newErrors.lname = "Numbers not allowed";

    const mobileStr = String(mobile); // Convert mobile to a string

    if (!mobileStr.trim()) newErrors.mobile = "Mobile number is required";
    else if (!/^[0-9]{10}$/.test(mobileStr))
      newErrors.mobile = "Enter a valid 10-digit number";

    setErrors(newErrors);
    return Object.values(newErrors).every((err) => err === "");
  };

  const handleSave = async () => {
    if (!validateFields()) return;

    const loginResponse = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(loginResponse);
    if (parsedData) {
      try {
        const response = await axios.post(
          custProfileEditAPI,
          {
            fname,
            lname,
            number: mobile,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          }
        );
        if (response?.data && response.status === 200) {
          handleClose();
          window.location.reload();
        }
      } catch (error) {
        console.log("Error updating social links", error);
      }
    }
  };

  const handleProfileEdit = () => {
    fileUploadRef.current.click();
  };

  const handleEditPf = async (event) => {
    const file = event.target.files[0];
    const custResponse = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(custResponse);
    if (parsedData?.token) {
      try {
        const formData = new FormData();
        formData.append("image", file);
        const response = await axios.post(custProfileImageEditAPI, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response?.data && response.status === 200) {
          window.location.reload();
        }
      } catch (error) {}
    }
  };

  return (
    <>
      <div className="customer_account__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1
              style={{ fontSize: isSmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold gilroy m-0"
            >
              Account
            </h1>
            <p
              style={{
                fontSize: isSmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>
              / <span style={{ color: "#1365AF" }}>My Account</span>
            </p>
          </div>
        </div>

        <section className="cust_details_account__section d-flex flex-row justify-content-between gap-1 mt-5">
          <div className="d-flex align-items-start align-items-md-center gap-2 gap-sm-4 h-100">
            <div
              className="ps-1 d-flex flex-column jusity-content-between align-items-center"
              onClick={handleProfileEdit}
            >
              <Avatar
                alt="profile_img"
                src={profileData?.image}
                style={{
                  width: isSmallScreen
                    ? "3.75rem"
                    : isMediumScreen
                    ? "6rem"
                    : "9rem",
                  height: isSmallScreen
                    ? "3.75rem"
                    : isMediumScreen
                    ? "6rem"
                    : "9rem",
                }}
              />
              <input
                type="file"
                ref={fileUploadRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleEditPf}
              />
            </div>

            <div
              className="d-flex flex-column justify-content-between align-items-start"
              style={{
                height: isSmallScreen
                  ? "5.5rem"
                  : isMediumScreen
                  ? "7rem"
                  : "9rem",
              }}
            >
              <div className="d-flex flex-column justify-content-start gap-sm-2">
                <p className="cust_name__heading profile_heading_text gilroy m-0">
                  Your Name:
                </p>
                <p className="cust_name gilroy m-0">
                  {profileData?.fname}&nbsp;{profileData?.lname}
                </p>
              </div>
              <div className="d-flex flex-column justify-content-start justify-content-sm-end gap-sm-2">
                <p className="cust_number_heading profile_heading_text gilroy m-0">
                  Phone number
                </p>
                <p className="cust_number gilroy m-0">
                  +91 {profileData?.number}
                </p>
              </div>
            </div>
          </div>

          <div className="cust_profile_details d-flex justify-content-betweenh-100 ">
            <div>
              <Button
                onClick={handleOpen}
                sx={{
                  fontSize: "16px",
                  backgroundColor: "#EEEEF4",
                  textTransform: "none",
                  padding: "5px",
                  width: isSmallScreen
                    ? "10px"
                    : isMediumScreen
                    ? "10rem"
                    : "12rem",
                  minWidth: "50px",
                  height: isSmallScreen ? "30px" : "43px",
                }}
                className="gilroy-regular"
              >
                <img
                  src={Pencil}
                  alt="Edit"
                  style={{
                    width: isSmallScreen
                      ? "18px"
                      : isMediumScreen
                      ? "25px"
                      : "30px",
                    height: isSmallScreen
                      ? "18px"
                      : isMediumScreen
                      ? "25px"
                      : "30px",
                  }}
                />
                <span className="edit_my_profile">
                  &nbsp;&nbsp;Edit my profile
                </span>
              </Button>
            </div>
          </div>
        </section>
        <section className="pt-5">
          <AccountInfo />
        </section>

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogContent>
            <TextField
              fullWidth
              margin="dense"
              label="First Name"
              error={!!errors.fname}
              helperText={errors.fname}
              value={fname}
              onChange={(e) => setFname(e.target.value)}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Last Name"
              error={!!errors.lname}
              helperText={errors.lname}
              value={lname}
              onChange={(e) => setLname(e.target.value)}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Mobile No."
              value={mobile}
              error={!!errors.mobile}
              helperText={errors.mobile}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                if (value.length <= 10) setMobile(value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default BuyersAccounts;
