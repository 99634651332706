import React, { useEffect, useState } from "react";
import "./Categories.css";
import { Button, IconButton, useMediaQuery } from "@mui/material";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

const itemsPerPage = 5;

const Categories = ({ serviceSortData, filterSort }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedDetails, setExpandedDetails] = useState({});

  const isExtraSmallScreen = useMediaQuery("(max-width: 320px) ");

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const toggleDetails = (id) => {
    setExpandedDetails((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  console.log("Services", serviceSortData);
  // console.log("Services", serviceSortData[0].status);

  const filteredServices = serviceSortData.filter((item) => {
    switch (filterSort) {
      case "Pending":
        return item.status === "Pending";
      case "Approved":
        return item.status === "Approved";
      case "Rejected":
        return item.status === "Rejected";
      default:
        return true;
    }
  });

  const sortedServices = [...filteredServices].sort((a, b) => {
    if (filterSort === "Newest") {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
    if (filterSort === "Oldest") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    return 0;
  });

  const offset = currentPage * itemsPerPage;
  const currentItems = sortedServices.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div className="categories_services__container w-100">
        <section className="product_card_section mt-4">
          {currentItems &&
            currentItems.map((item, index) => (
              <div
                key={item._id}
                className="services_img__container w-100 border mb-5 p-2 p-sm-4"
              >
                <div className="services__img-box result-content__box overflow-hidden border">
                  <img src={item.image} alt="" className="services__img" />
                </div>
                <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-start justify-content-sm-between justify-content-md-start justify-content-lg-between align-items-start align-items-lg-center mt-1 mt-sm-3  ">
                  <div className="inline-block w-100">
                    <div className="d-flex flex-column flex-sm-row align-items-center gap-1 ">
                      <h2 className="categories--title gilroy text-lg-center m-0 p-0">
                        {item.title}
                      </h2>
                      <span className="checker gilroy ">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{
                            color: "#172b4d",
                            fontSize: "10px",
                          }}
                        />
                        &nbsp;
                        {item.status === "Approved"
                          ? "Certified"
                          : "Not Certified"}
                      </span>
                    </div>
                    <div
                      className={`company__address d-flex justify-content-center align-items-start justify-content-sm-start gap-1 mt-1 gilroy`}
                    >
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{
                          color: "#6a7d9f",
                          fontSize: "18px",
                          marginTop: "2px",
                          // marginTo,
                        }}
                      />
                      <p className="company__address m-0 p-0 gilroy">
                        {item.address[0]?.flatBlockNo}{" "}
                        {item.address[0]?.buildingVillage},{" "}
                        {item.address[0]?.street}, {item.address[0]?.area},{" "}
                        {item.address[0]?.townCityDistrict},{" "}
                        {item.address[0]?.state}, {item.address[0]?.pincode}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`d-flex ${
                      isExtraSmallScreen ? "flex-column" : "flex-row"
                    } 
                   
                    flex-row flex-sm-column flex-md-row flex-lg-column ${
                      isExtraSmallScreen ? "gap-1" : "gap-3"
                    } gap-sm-0 gap-md-3 gap-lg-0 ${
                      isExtraSmallScreen
                        ? "align-items-start"
                        : "align-items-center"
                    } mt-1`}
                  >
                    <p className="categories__ratings text-center d-flex align-items-center m-0 p-0">
                      <span>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{
                            color: "#fa9b00",
                            fontSize: "18px",
                          }}
                        />
                      </span>
                      &nbsp;
                      <span className="ratings__outof fw-bold gilroy">
                        {item.star}/5
                      </span>
                    </p>
                    <p className="categories__reviews m-0 p-0">
                      {item.totalReview} reviews
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="d-flex flex-row flex-wrap gap-2">
                    {item.pSetup?.service_offer
                      ?.split(", ")
                      .map((service, index) => (
                        <p key={index} className="service-giving m-0 ">
                          {service}
                        </p>
                      ))}
                  </div>
                  <div className="d-flex align-items-start mt-2">
                    <p className="service-details p-0 m-0">
                      {expandedDetails[item._id]
                        ? item.details || "No details available"
                        : (item.details
                            ? item.details.split(" ").slice(0, 10).join(" ")
                            : "No details available") + "..."}
                      &nbsp;&nbsp;
                      <button
                        className="service-pare-expand"
                        onClick={() => toggleDetails(item._id)}
                      >
                        {expandedDetails[item._id] ? "Less" : "More"}
                      </button>
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between align-items-start align-items-sm-center align-items-md-start align-items-lg-center mt-4 gap-2">
                  <div className="d-flex flex-column flex-sm-row gap-2 align-items-start align-items-lg-center w-100">
                    <p className="categories__response text-center w-100 gilroy m-0">
                      Response: <span>Within an hour</span>
                    </p>
                    <p className="categories__open--time text-center w-100 gilroy m-0">
                      Open: <span>{item.pSetup?.working_hours}</span>
                    </p>
                  </div>
                  <div className="d-flex gap-2 mt-3 mt-sm-0 mt-md-3 mt-lg-0">
                    <IconButton
                      sx={{
                        border: "1px solid rgba(194, 199, 207, 1)",
                        width: "36px",
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "4px",
                        padding: "0",
                        minWidth: "36px",
                      }}
                      className="share__btn "
                    >
                      <ModeEditIcon
                        fontSize="small"
                        style={{ color: "#6a7d9f" }}
                      />
                    </IconButton>
                    <IconButton
                      sx={{
                        border: "1px solid rgba(194, 199, 207, 1)",
                        width: "36px",
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "4px",
                        padding: "0",
                        minWidth: "36px",
                      }}
                      className="share__btn "
                    >
                      <ShareRoundedIcon
                        fontSize="small"
                        style={{ color: "#6a7d9f" }}
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))}
        </section>
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(sortedServices.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
};

export default Categories;
