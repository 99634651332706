import React, { useState, useEffect, useRef } from "react";
import "./Profile.css";
import ProfileCarousels from "./ProfileCarousels/ProfileCarousels";
import BusinessInformation from "./BusinessInformation/BusinessInformation";
import ContactInformation from "./ContactInformation/ContactInformation";
import Overview from "./Overview/Overview";
import ServiceOffered from "./ServiceOffered/ServiceOffered";
import SocialLinks from "./SocialLinks/SocialLinks";
import Certification from "./Certification/Certification";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import ShareIcon from "@mui/icons-material/Share";
import Pencil from "../../../../Assets/Icons/pencil.png";
import {
  sellerAccountProfileAPI,
  sellerBannerEditAPI,
} from "../../../../Config/apiConfig";

import axios from "axios";

function Profile() {
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [accountBanner, setAccountBanner] = useState("");
  const [address, setAddress] = useState([]);
  const [overview, setOverview] = useState({});
  const [certificates, setCertificates] = useState([]);
  const [profileId, setProfileId] = useState("");
  const [open, setOpen] = useState(false);
  const [bannerUpdate, setBannerUpdate] = useState(null);
  const fileInputRef = useRef(null);

  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const handleLogOutBtn = () => {
    sessionStorage.removeItem("loginCredentials");
    localStorage.removeItem("loginCredentials");
    localStorage.removeItem("prodServiButton");
    sessionStorage.clear();

    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 500);
  };

  const handleBannerEdit = () => {
    fileInputRef.current.click();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchSellerAccountProfileAPI = async () => {
      const loginResponse = sessionStorage.getItem("loginCredentials");
      const parsedData = JSON.parse(loginResponse);
      if (parsedData) {
        try {
          const response = await axios.get(sellerAccountProfileAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response?.data && response.status === 200) {
            console.log("response", response?.data.data.profileSetup._id);

            setBusinessName(response.data.data.businessProfile.businessName);
            setBusinessId(response.data.data.businessProfile._id);
            setProfileId(response.data.data.profileSetup._id);
            setAccountBanner(response.data.data.profileSetup.image);
            setAddress(response.data.data.businessProfile.officeLocation);
            setOverview(response.data.data.profileSetup);
            setCertificates(response.data.data.businessProfile.certifications);
          }
        } catch (error) {
          console.log("All Services Error", error);
        }
      }
    };
    fetchSellerAccountProfileAPI();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setBannerUpdate(file);
  };

  useEffect(() => {
    const fetchBannerUpdateAPI = async () => {
      const formData = new FormData();
      formData.append("image", bannerUpdate);
      formData.append("id", profileId);

      try {
        const loginResponse = sessionStorage.getItem("loginCredentials");
        const parsedData = JSON.parse(loginResponse);

        if (parsedData?.token) {
          const response = await axios.put(sellerBannerEditAPI, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });

          if (response?.data && response.status === 200) {
            console.log("Banner updated successfully!", response.data);
            setBannerUpdate(null);
            window.location.reload();
            // setAccountBanner(URL.createObjectURL(file)); // Update preview instantly
          } else {
            console.error("Banner update failed:", response.data);
          }
        }
      } catch (error) {
        console.error(
          "Error updating banner:",
          error.response?.data || error.message
        );
      }
    };
    fetchBannerUpdateAPI();
  }, [bannerUpdate, businessId]);

  return (
    <>
      <div className="profile_section mb-0">
        <div>
          <div className="share-button-container d-flex flex-row justify-content-end">
            <Button
              sx={{
                color: "rgba(106, 125, 159, 1)",
                border: "rgba(106, 125, 159, 1)",
                padding: "1px 15px",
                fontSize: { xs: "16px", md: "18px", lg: "20px" },
              }}
              className="shareButton border border-2 text-capitalize"
            >
              <ShareIcon
                sx={{
                  width: { xs: "18px", lg: "25px" },
                  height: { xs: "18px", lg: "25px" },
                }}
              />
              &nbsp; Share
            </Button>
          </div>
        </div>

        <div
          className="profile-pic__container mt-3 p-0"
          onClick={handleClickOpen}
        >
          <img
            src={overview?.image}
            alt="Account"
            className="banner_account_img"
          />
        </div>

        {/* <ProfileCarousels /> */}
        <div className="mt-3">
          <BusinessInformation
            businessNameData={businessName}
            businessID={businessId}
          />
          <ContactInformation
            addressData={address}
            businessID={businessId}
            profId={profileId}
          />
          <Overview overviewData={overview} profId={profileId} />
          <ServiceOffered profId={profileId} overviewData={overview} />
          <SocialLinks overviewData={overview} />
          <Certification
            certificateData={certificates}
            businessID={businessId}
          />
        </div>

        <div className="my-5 mx-4">
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#ffcccc",
              color: "#000",
              border: "2px solid #ff5c5c",
              fontSize: "18px",
              boxShadow: "1px 4px 7px -4px",
              "&:hover": {
                backgroundColor: "#ffaaaa",
                border: "2px solid #ff5c5c",
              },
            }}
            className="gilroy"
            onClick={handleLogOutBtn}
          >
            Log Out
          </Button>
        </div>

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleFileChange}
        />
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle className="gilroy fw-bold">
            Are You Sure?{" "}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="text-center gilroy">
            Do you really want to update newimage? <br />
            This process cannot be undone.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleBannerEdit}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default Profile;
