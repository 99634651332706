import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import { styled, Button, useMediaQuery } from "@mui/material";
import "reactjs-popup/dist/index.css";
import "./SubCategories.css";
import {
  CloudUpload,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@mui/icons-material";

const SubCategories = ({ itemsData, onSave }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const popupContentRef = useRef(null);
  const [serviceDocument, setServiceDocument] = useState(null);
  const [licenseDocument, setLicenseDocument] = useState(null);
  const [childCategoryId, setChildCategoryId] = useState("");
  const [childCategoryName, setChildCategoryName] = useState("");

  const isVerySmallScreen = useMediaQuery("(max-width:320px) ");

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleServiceDocument = (event) => {
    setServiceDocument(event.target.files[0]);
    console.log(event.target.files);
  };

  const handleLicenseDocument = (event) => {
    setLicenseDocument(event.target.files[0]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !(
          popupContentRef.current &&
          popupContentRef.current.contains(event.target)
        )
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemClick = (item) => {
    console.log("Selected Item ID:", item._id);
    console.log("Selected Item Name:", item.name);
    setChildCategoryId(item._id);
    setChildCategoryName(item.name);
  };

  const handleProceedData = () => {
    const formData = {
      childCategoryId,
      childCategoryName,
      serviceDocument,
      licenseDocument,
    };
    onSave(formData);
    setShowDropdown(false);
  };

  const renderPopupContent = () => (
    <div
      ref={popupContentRef}
      style={{
        width: "26rem",
      }}
      className="d-flex rounded flex-column gap-3 justify-content-center align-items-center p-4"
    >
      <p
        style={{ fontSize: "16px", color: "#172B4D" }}
        className="text-break text-center p-0 m-0"
      >
        Upload the service document
      </p>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        sx={{
          backgroundColor: "#FA9B00",
          "&:hover": {
            backgroundColor: "#ea9100",
          },
        }}
        startIcon={<CloudUpload />}
      >
        Upload files
        <VisuallyHiddenInput
          type="file"
          onChange={handleServiceDocument}
          multiple
        />
      </Button>
      <p>{serviceDocument?.name}</p>
      <p
        style={{ fontSize: "16px", color: "#172B4D" }}
        className="text-break text-center m-0 p-0"
      >
        Upload the license issued by the government of India
      </p>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        sx={{
          backgroundColor: "#FA9B00",
          "&:hover": {
            backgroundColor: "#ea9100",
          },
        }}
        startIcon={<CloudUpload />}
      >
        Upload files
        <VisuallyHiddenInput
          type="file"
          onChange={handleLicenseDocument}
          multiple
        />
      </Button>
      <p>{licenseDocument?.name}</p>
      <Button onClick={handleProceedData}>Save</Button>
    </div>
  );

  return (
    <>
      <div className="dropdown" ref={dropdownRef}>
        <button
          className={`dropbtn d-flex flex-row ${
            isVerySmallScreen && "justify-content-arround"
          } justify-content-between ${showDropdown ? "active" : ""}`}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <span style={{ fontSize: isVerySmallScreen && "14px" }}>
            See sub-categories
          </span>
          <span>
            {showDropdown ? (
              <KeyboardArrowUp sx={{ fontSize: "20px" }} />
            ) : (
              <KeyboardArrowDown sx={{ fontSize: "20px" }} />
            )}
          </span>
        </button>
        <div>
          {showDropdown && (
            <div className="dropdown-content">
              {itemsData.children.map((item, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onChange={() => handleItemClick(item)}
                >
                  <Popup
                    trigger={<p className="m-1">{item.name}</p>}
                    modal
                    closeOnDocumentClick={false}
                  >
                    {renderPopupContent(item)}
                  </Popup>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubCategories;
