import React, { useState, useEffect } from "react";
import "./StartBidding.css";
import { ArrowBackRounded } from "@mui/icons-material";
import {
  useMediaQuery,
  Link,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  Typography,
} from "@mui/material";
import PickService from "./PickService/PickService";
import RequestDetails from "./RequestDetails/RequestDetails";
import Reviews from "./Reviews/Reviews";
import SuccessMsg from "./SuccessMsg";
import BidCategoriesBtn from "./BidCategoriesBtn/BidCategoriesBtn";
import VirticalStyleLine from "../VirticalStyleLine/VirticalStyleLine";
import SubCateImg from "../../Assets/Images/request-details1.png";
import { custBidSubCateListAPI, custBidAddAPI } from "../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";

const steps = ["Pick a Service", "Request Details", "Reviews"];

const StartBidding = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [auctionSuccess, setAuctionSuccess] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCateDetailData, setSubCateDetailData] = useState([]);
  const [childCategoryId, setChildCategoryId] = useState("");
  const [childCategoryName, setChildCategoryName] = useState("");
  const [serviceDetails, setServiceDetails] = useState("");
  const [address, setAddress] = useState("");
  const [instruction, setInstruction] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [budget, setBudget] = useState("");
  const [auction_duration, setAuction_duration] = useState("");

  const isSmallScreen = useMediaQuery("(max-width: 576px) ");
  const isMediumScreen = useMediaQuery("(max-width:768px)");
  const isLargeScreen = useMediaQuery("(max-width:992px)");

  const handleRequestDetails = (data) => {
    setAddress(data.address);
    setInstruction(data.spcInstruction);
    setServiceDetails(data.details);
    setDate(data.serviceDate);
    setTime(data.timing);
    setBudget(data.budget);
    setAuction_duration(data.auctionDuration);
  };

  const formData = {
    categoryId: categoryId,
    categoryName: categoryName,
    childCatId: childCategoryId,
    childCatName: childCategoryName,
    address: address,
    details: serviceDetails,
    instruction: instruction,
    date: date,
    time: time,
    budget: budget,
    auction_duration: auction_duration,
  };

  console.log(formData);

  const handleNext = async () => {
    if (
      categoryId !== "" ||
      categoryName !== "" ||
      childCategoryId !== "" ||
      address !== "" ||
      serviceDetails !== "" ||
      instruction !== "" ||
      date !== "" ||
      time !== "" ||
      budget !== "" ||
      auction_duration !== ""
    ) {
      if (activeStep === steps.length - 1) {
        // The last step (Reviews) where "Post the Auction" is clicked
        const custLoginResponse = localStorage.getItem("custLoginCredentials");
        const parsedData = JSON.parse(custLoginResponse);

        if (parsedData && isFormDataComplete()) {
          try {
            const response = await axios.post(custBidAddAPI, formData, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${parsedData.token}`,
              },
            });

            if (response?.data && response.status === 200) {
              console.log(response.data);
              toast.success(response.data.msg);
              setAuctionSuccess(true);
            }
          } catch (error) {
            console.error("Error posting auction", error);
            toast.error("Failed to post auction. Please try again.");
          }
        } else {
          toast.error("Please complete all required details.");
        }
      } else if (activeStep === 1) {
        // Move from "Request Details" to "Reviews" without API call
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        // Move to next step normally
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    window.scroll(0, 0);
  };

  const handleCateId = (data) => {
    if (data) {
      const cateId = data;
      setCategoryId(cateId);
    }
  };
  const handleCateName = (data) => {
    if (data) {
      const cateName = data;
      setCategoryName(cateName);
    }
  };

  const handlePickServiceId = (id) => {
    setChildCategoryId(id);
  };

  const handlePickServiceName = (data) => {
    setChildCategoryName(data);
  };

  useEffect(() => {
    const fetchCustBidSubCateListAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData.token && categoryId) {
        try {
          const response = await axios.get(custBidSubCateListAPI + categoryId, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response.data && response.status === 200) {
            setSubCateDetailData(response.data.data);
          }
        } catch (error) {}
      }
    };
    fetchCustBidSubCateListAPI();
  }, [categoryId]);

  const isFormDataComplete = () => {
    // Check if all keys in formData have values
    return Object.values(formData).every((value) => value);
  };

  return (
    <>
      <div className="start_bidding__container">
        <div className="d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isSmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-2 mt-sm-1 fw-bold"
          />
          <div className="">
            <h1
              style={{ fontSize: isSmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold gilroy m-0"
            >
              Start A <span style={{ color: "#1365AF" }}>Bidding</span>
            </h1>
            <p
              style={{
                fontSize: isSmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 mt-1 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              /{" "}
              <Link
                underline="none"
                href={"/service-categories"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Service Categories
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}>{steps[activeStep]}</span>
            </p>
          </div>
        </div>

        <section>
          <div className="service_category_container d-flex flex-column flex-md-row gap-4 mt-5 justify-content-between">
            {activeStep === 0 ? (
              <aside className="d-flex flex-column justify-content-start gap-4 mt-5">
                <BidCategoriesBtn
                  catID={handleCateId}
                  catName={handleCateName}
                />
              </aside>
            ) : (
              <div className="bidding_img_container position-relative d-none d-md-block rounded-top mt-4">
                <div className=" positon-relative w-100 h-100">
                  <img
                    src={SubCateImg}
                    alt="Services"
                    className="bidding_img w-100 h-100"
                  />
                  <p
                    style={{
                      width: "100%",
                      color: "#FA9B00",
                      fontSize: isLargeScreen ? "30px" : "40px",
                      fontWeight: 600,
                      lineHeight: "47.72px",
                      top: "80%",
                    }}
                    className="gilroy position-absolute ps-4 pb-5 mb-5"
                  >
                    <span style={{ color: "#ffffff" }}>Find the</span> best pest
                    control services!
                  </p>
                </div>
              </div>
            )}

            <VirticalStyleLine />

            <Box
              sx={{
                width: isMediumScreen ? "100%" : isLargeScreen ? "65%" : "70%",
              }}
            >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <React.Fragment>
                {activeStep === 0 && (
                  <PickService
                    data={categoryName}
                    details={subCateDetailData}
                    onSubCateID={handlePickServiceId}
                    onSubCateName={handlePickServiceName}
                  />
                )}
                {activeStep === 1 && (
                  <RequestDetails
                    onSave={handleRequestDetails}
                    subCateName={childCategoryName}
                  />
                )}
                {activeStep === 2 && (
                  <>
                    {auctionSuccess && <SuccessMsg />}
                    <Reviews data={formData} />
                  </>
                )}
                <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    style={{
                      backgroundColor: "#1365AF",
                      color: "#ffffff",
                      padding: "10px 30px",
                    }}
                  >
                    {activeStep === steps.length - 1
                      ? "Post the Auction"
                      : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            </Box>
          </div>
        </section>
      </div>
    </>
  );
};

export default StartBidding;
