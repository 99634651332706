import React, { useState, useEffect } from "react";
import "./SellerProfile.css";
import VirticalStyleLine from "../VirticalStyleLine/VirticalStyleLine";
import { Box, Button, useMediaQuery } from "@mui/material";
import PanValidation from "./PanValidation/PanValidation";
import BusinessDetails from "./BusinessDetails/BusinessDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AdditionalDetails from "./AdditionalDetails/AdditionalDetails";
import OfficeLocations from "./OfficeLoctions/OfficeLocations";
import ProfileSteps from "./ProfilesSteps/ProfileSteps";
import BankAccounts from "./BankAccounts/BankAccounts";
import TaxAssessments from "./TaxAssessments/TaxAssessments";
import Invoicing from "./Invoicing/Invoicing";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// importing APIs
import { profileBusinessAPI, registerDataAPI } from "../../Config/apiConfig";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SellerProfile = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [panFilledData, setPanFilledData] = useState("");
  const [isOrganization, setIsOrganization] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [dateOfIncorporation, setDateOfIncorporation] = useState("");
  const [preferences, setPreferences] = useState({});
  const [officeLocationData, setOfficeLocationData] = useState([]);
  const [accountDetailsData, setAccountDetailsData] = useState([]);
  const [taxAssessmentDetailsData, setTaxAssessmentDetailsData] = useState([]);
  const [turnoverDetailsData, setTurnoverDetailsData] = useState({});
  const [businessType, setBusinessType] = useState();
  const [bussPanNo, setBussPanNo] = useState("");
  const [bussPanName, setBussPanName] = useState("");
  const [bussPanDob, setBussPanDob] = useState("");
  const [turnover, setTurnover] = useState("");
  const [specificCategory, setSpecificCategory] = useState("");

  const [mergeData, setMergeData] = useState({});

  const navigate = useNavigate();

  // Screen Size for responsiveness ---------------
  const isExtraLargeScreen = useMediaQuery("(max-width:1200px)");
  // const isMediumScreen = useMediaQuery("(max-width: 992px)");
  const isSmallScreen = useMediaQuery("(max-width: 768px) ");
  const isVerySmallScreen = useMediaQuery("(max-width: 435px) ");

  // Porceed to next page
  const handleProceed = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 7));
    window.scrollTo(0, 0);
  };
  // Back to previous page
  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
    window.scrollTo(0, 0);
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchRegisterDataApi = async () => {
      const loginData = localStorage.getItem("loginCredentials");
      const parsedData = JSON.parse(loginData);
      if (parsedData && parsedData.token) {
        try {
          const response = await axios.get(registerDataAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            const apiData = response.data;
            if (apiData !== null) {
              const isVerifPan = apiData.data[0].personalVerificationVia;
              if (isVerifPan === "PAN") {
                setPanFilledData(apiData.data[0]);
              }
              setIsOrganization(apiData.data[0]);
            }
          }
        } catch (error) {
          console.log("Error", error);
        }
      }
    };

    fetchRegisterDataApi();
  }, []);

  // data form localStorage ---------------
  useEffect(() => {
    const fetchingLocalData = () => {
      const officeLocationDetails = localStorage.getItem("addNewOfficeData");
      const accountDetails = localStorage.getItem("bankDetails");
      const taxAssessmentDetails = localStorage.getItem("taxAssessmentData");
      if (officeLocationDetails) {
        const parsedData = JSON.parse(officeLocationDetails);
        setOfficeLocationData(parsedData);
      }
      if (accountDetails) {
        const parsedData = JSON.parse(accountDetails);
        setAccountDetailsData(parsedData);
      }
      if (taxAssessmentDetails) {
        const parsedData = JSON.parse(taxAssessmentDetails);
        setTaxAssessmentDetailsData(parsedData);
      }
    };
    fetchingLocalData();
  }, [turnoverDetailsData]);

  useEffect(() => {
    const mergeDetails = {
      businessType: businessType,
      bussPanNo: bussPanNo,
      bussPanName: bussPanName,
      bussPanDob: bussPanDob,
      businessName: businessName,
      dateOfIncorporation: dateOfIncorporation,
      preference: preferences,
      officeLocation: officeLocationData,
      bankAccount: accountDetailsData,
      taxAssessment: taxAssessmentDetailsData,
      turnover: turnover,
      specificCategory: specificCategory,
    };

    if (mergeDetails) {
      setMergeData(mergeDetails);
    }
  }, [
    businessType,
    bussPanNo,
    bussPanName,
    bussPanDob,
    businessName,
    dateOfIncorporation,
    preferences,
    officeLocationData,
    accountDetailsData,
    taxAssessmentDetailsData,
    turnover,
    specificCategory,
  ]);

  const handleBusinessDetails = (data) => {
    setBusinessName(data.businessName);
    setDateOfIncorporation(data.dateOfIncorporation);
  };

  const handlePanDetails = (data) => {
    setBusinessType(data.businessType);
    setBussPanNo(data.bussPanNo);
    setBussPanName(data.bussPanName);
    setBussPanDob(data.bussPanDob);
  };

  const handleAddDetailsData = (data) => {
    setPreferences(data);
  };

  const handleTurnoverDetailsData = (data) => {
    setTurnover(data.turnover);
    setSpecificCategory(data.specificCategory);
    setTurnoverDetailsData(data);
  };

  // handle submit
  const handleSubmit = async () => {
    if (mergeData) {
      const loginResponse = localStorage.getItem("loginCredentials");
      const parsedData = JSON.parse(loginResponse);
      if (parsedData && mergeData) {
        try {
          const response = await axios.post(profileBusinessAPI, mergeData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            toast.success(response.data.message);
            if (response.data.message) {
              setTimeout(() => {
                navigate("/profile-setup");
              }, 1000);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.error);
            setTimeout(() => {
              navigate("/profile-setup");
            }, 1000);
          } else if (error.response && error.response.status === 500) {
            toast.error(error.response.data.error);
            // navigate("/seller-profile");
          }
        }
      }
    }
  };

  const stepHeadings = {
    1: "Pan Validation",
    2: "Business Details",
    3: "Additional Details",
    4: "Office Locations",
    5: "Bank Accounts",
    6: "Tax Assessments",
    7: "E-Invoicing",
  };

  return (
    <div className="seller_details__container mb-5">
      <div className="d-flex gap-2 mb-5">
        <h1
          style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
          className="text-dark gilroy fw-bold"
        >
          Business Profile
        </h1>
      </div>
      <div className="seller_profile__contents">
        <Box
          style={{ display: isSmallScreen ? "none" : "block" }}
          className="seller_details_steps__container "
        >
          <ProfileSteps
            currentStep={currentStep}
            onStepClick={handleStepClick}
          />
        </Box>

        <VirticalStyleLine className="d-none d-md-block" />

        <div className="w-100 d-flex flex-column justify-content-center pb-5">
          <div className="d-flex gap-2 align-items-center">
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ fontSize: isVerySmallScreen ? "20px" : "25px" }}
              onClick={handleBack}
            />
            <h2 className="steps_headings p-0 fw-bold m-0 gilroy text-darks">
              {stepHeadings[currentStep]}
            </h2>
          </div>
          <div className="business__profile_content w-100 border rounded mt-3 p-2 p-sm-4">
            <div
              className={`pan-verify form-tab ${
                currentStep !== 1 ? "d-none" : ""
              }`}
            >
              <PanValidation
                data={panFilledData}
                businessData={isOrganization}
                onSave={handlePanDetails}
              />
            </div>
            <div
              className={`business-details form-tab ${
                currentStep !== 2 ? "d-none" : ""
              }`}
            >
              <BusinessDetails
                businessData={isOrganization}
                onSave={handleBusinessDetails}
              />
            </div>
            <div
              className={`additional-details form-tab ${
                currentStep !== 3 ? "d-none" : ""
              }`}
            >
              <AdditionalDetails onSave={handleAddDetailsData} />
            </div>
            <div
              className={`office-location form-tab ${
                currentStep !== 4 ? "d-none" : ""
              }`}
            >
              <OfficeLocations />
            </div>
            <div
              className={`bank-accounts form-tab ${
                currentStep !== 5 ? "d-none" : ""
              }`}
            >
              <BankAccounts />
            </div>
            <div
              className={`tax-assessment form-tab ${
                currentStep !== 6 ? "d-none" : ""
              }`}
            >
              <TaxAssessments data={panFilledData} />
            </div>
            <div
              className={`invoicing form-tab ${
                currentStep !== 7 ? "d-none" : ""
              }`}
            >
              <Invoicing onSave={handleTurnoverDetailsData} />
            </div>
          </div>
          <div className="mt-4 d-flex align-items-center">
            {currentStep === 7 ? (
              <Button
                sx={{
                  backgroundColor: "#1365AF",
                  color: "#ffffff",
                  padding: isVerySmallScreen ? "5px 15px" : "10px 20px ",
                  fontSize: isExtraLargeScreen ? "18px" : "20px",
                  fontWeight: 500,
                  textTransform: "none",
                  margin: "0 auto",
                }}
                variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: "#1365AF",
                  color: "#ffffff",
                  padding: isVerySmallScreen ? "5px 15px" : "10px 20px ",
                  fontSize: isExtraLargeScreen ? "18px" : "20px",
                  fontWeight: 500,
                  textTransform: "none",
                  margin: "0 auto",
                }}
                variant="contained"
                className="gilroy-regular"
                onClick={handleProceed}
              >
                Proceed
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerProfile;
