import React, { useState, useEffect } from "react";
import "./OrderSummary.css";
import {
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import {
  custCartListAPI,
  custDeletFormCartAPI,
} from "../../../../Config/apiConfig";
import axios from "axios";
import { Delete } from "@mui/icons-material";

const OrderSummary = ({ cartData, price, onSave }) => {
  const [cartDetailsData, setCartDetailsData] = useState([]);
  const [payablePrice, setPayablePrice] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [address, setAddress] = useState("");
  const [sellerChoose, setSellerChoose] = useState("");

  const isVerySmallScreen = useMediaQuery("(max-width:568px) ");

  const custLoginResponse = localStorage.getItem("custLoginCredentials");
  const parsedData = JSON.parse(custLoginResponse);

  const handleFullName = (event) => {
    setFullName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleEmailBlur = () => {
    // Validate email format using a regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailPattern.test(email));
  };
  const handleAddress = (event) => {
    setAddress(event.target.value);
  };
  const handleSellerChoose = (event) => {
    setSellerChoose(event.target.value);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(amount);
  };

  useEffect(() => {
    const formData = {
      fullName,
      email,
      address,
      sellerChoose,
    };
    onSave(formData);
  }, [fullName, email, address, sellerChoose]);

  useEffect(() => {
    if ((cartData, price)) {
      setCartDetailsData(cartData);
      setPayablePrice(price);
    }
  }, [cartData, price]);

  const handleDeletFromCart = (id) => {
    const deleteProdId = id;
    if (deleteProdId && parsedData) {
      const fetchCustDeletFromCartAPI = async () => {
        try {
          const response = await axios.get(
            custDeletFormCartAPI + deleteProdId,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${parsedData.token}`,
              },
            }
          );
          if (response && response.status === 200) {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } catch (error) {}
      };
      fetchCustDeletFromCartAPI();
    }
  };

  return (
    <>
      <div className="order_summary__containers">
        <div className="mt-5 border px-2 p-sm-5 py-4 w-100 rounded">
          <section className="order_summary__form py-3">
            <div className="order_summary">
              <p className="form_title gilroy m-0 pb-1">Full Name</p>
              <TextField
                size="small"
                sx={{ backgroundColor: "#ffffff" }}
                fullWidth
                value={fullName}
                onChange={handleFullName}
              />
            </div>
            <div className="mt-3">
              <p className="form_title gilroy m-0 pb-1">Email address</p>
              <TextField
                type="email"
                size="small"
                sx={{ backgroundColor: "#ffffff" }}
                fullWidth
                value={email}
                onChange={handleEmail}
                onBlur={handleEmailBlur}
                error={emailError} // Display error if email is invalid
                helperText={emailError ? "Please enter a valid email" : ""}
              />
            </div>
            <div className="mt-3">
              <p className="form_title gilroy m-0 pb-1">Delivery Address</p>
              <TextField
                size="small"
                sx={{ backgroundColor: "#ffffff" }}
                fullWidth
                value={address}
                onChange={handleAddress}
              />
            </div>
          </section>
        </div>
        <section className="cart_table mt-5">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: isVerySmallScreen ? 450 : 650,
              }}
            >
              <TableHead sx={{ backgroundColor: "#F1F0F4" }}>
                <TableRow>
                  <TableCell>Product Title</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartDetailsData &&
                  cartDetailsData.map((item) => (
                    <TableRow key={item._id}>
                      <TableCell>
                        <div className="d-flex gap-1 gap-sm-4">
                          <div className="cart_prod_img__box me-1 rounded border overflow-hidden text-center">
                            <img
                              src={item.image}
                              alt={item.title}
                              className="cart_prod_img"
                            />
                          </div>
                          <div className="cart_prod_title">{item.title}</div>
                        </div>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <span className="details_of_cart">{item.qty}</span>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <span className="details_of_cart">
                          &#8377;{item.tAmount}
                        </span>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeletFromCart(item.cartId)}
                        >
                          <Delete sx={{ fontSize: "25px" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </section>

        <section className=" pricing__section d-flex flex-column gap-2 gap-sm-5 mt-5">
          <div className="">
            <h3 className="total_amount gilroy m-0">
              Total Amount Payabal:{" "}
              <span className="amount gilroy">
                <span>{formatCurrency(payablePrice)}</span>{" "}
              </span>
            </h3>
          </div>
          <div className="mt-2 mt-sm-4">
            <p className="reason gilroy">Reason for Choosing seller</p>
            <div className="reason_details">
              <TextField
                multiline
                rows={5}
                fullWidth
                value={sellerChoose}
                onChange={handleSellerChoose}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OrderSummary;
