import React, { useEffect, useState } from "react";
import "./MyServices.css";
import { Button, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AddBoxRounded } from "@mui/icons-material";
import CategoriesButtons from "./CategoriesButton/CategoriesButtons";
import Categories from "./Categories/Categories";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";
import axios from "axios";
import { allServicesAPI } from "../../../Config/apiConfig";
import ServiceFilter from "./Categories/ServicesFilter/ServiceFilter";
import ServiceSort from "./Categories/ServiceSort/ServiceSort";

const MyServices = () => {
  const navigate = useNavigate();
  const [cateId, setCateId] = useState("");
  const [serviceSortData, setServiceSortData] = useState([]);
  const [serviceCount, setServiceCount] = useState("");
  const [cateName, setCateName] = useState("");
  const [serviceFilterSort, setServiceFilterSort] = useState("");

  // Screen Size for responsiveness -------------------------
  const isExtraSmallScreen = useMediaQuery("(max-width: 320px) ");
  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isMediumScreen = useMediaQuery("(max-width:876px) ");
  const isLargeScreen = useMediaQuery("(max-width:1024px)");

  const handleAddServiceData = () => {
    navigate("/add-services");
  };
  const handleAddService = () => {
    navigate("/add-services-details");
  };

  const handleCategoryId = (data) => {
    setCateId(data);
    console.log("default id", data);
  };

  const handleServiceFilter = (data) => {
    if (data) {
      setServiceFilterSort(data);
      console.log("filter service", data);
    }
  };

  const handleServiceSort = (data) => {
    if (data) {
      setServiceFilterSort(data);
    }
  };

  const handleCateName = (data) => {
    if (data) {
      setCateName(data);
    }
  };

  useEffect(() => {
    const fetchPorductSortAPI = async () => {
      if (!cateId) return;
      const response = sessionStorage.getItem("loginCredentials");
      const parsedData = JSON.parse(response);
      console.log(allServicesAPI + cateId);
      console.log("token", parsedData.token);
      try {
        if (parsedData?.token) {
          const response = await axios.get(allServicesAPI + cateId, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response?.data && response.status === 200) {
            console.log("service data sort", response.data.data);
            setServiceSortData(response.data.data);
            setServiceCount(response.data.data.length);
          }
        }
      } catch (error) {
        console.log("All Services Error", error);
      }
    };
    fetchPorductSortAPI();
  }, [cateId]);

  return (
    <>
      <div className="my_services__container mt-4">
        <div className="add_my_service__button ">
          <Button
            sx={{
              fontSize: isVerySmallScreen ? "15px" : "20px",
              color: "#1365AF",
              lineHeight: "28.8px",
              fontWeight: 400,
              backgroundColor: "#ECF0FF",
            }}
            onClick={handleAddService}
            size="small"
            className="gilroy py-2 py-sm-3  px-2 px-sm-5"
          >
            <AddBoxRounded sx={{ fontSize: isVerySmallScreen && "18px" }} />
            &nbsp;&nbsp;Add Services
          </Button>
          <Button
            sx={{
              fontSize: isVerySmallScreen ? "15px" : "20px",
              color: "#1365AF",
              lineHeight: "28.8px",
              fontWeight: 400,
              backgroundColor: "#ECF0FF",
            }}
            onClick={handleAddServiceData}
            size="small"
            className="gilroy py-2 py-sm-3 px-2 px-sm-5"
          >
            <AddBoxRounded sx={{ fontSize: isVerySmallScreen && "18px" }} />
            &nbsp;&nbsp;Add Services Data
          </Button>
        </div>
        <div className="d-flex justify-content-between gap-3 gap-lg-4 mt-5 w-100 p-0">
          <div className="categories_buttons_container d-none d-md-block">
            <CategoriesButtons
              catID={handleCategoryId}
              catName={handleCateName}
            />
          </div>
          <VirticalStyleLine />
          <div className="categories_container w-100">
            <h4 className="categories_heading gilroy">
              Showing All <strong>({serviceCount})</strong>&nbsp;&nbsp;
              <span>{cateName}</span>
            </h4>
            <div
              className={`d-flex ${
                isExtraSmallScreen ? "flex-column" : "flex-row"
              }  gap-2 gap-sm-inherit justify-content-between mt-4`}
            >
              <ServiceFilter onFilter={handleServiceFilter} />
              <ServiceSort onCheck={handleServiceSort} />
            </div>
            <Categories
              serviceSortData={serviceSortData}
              filterSort={serviceFilterSort}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyServices;
