import React, { useEffect, useState } from "react";
import "./FinancialDetails.css";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  useMediaQuery,
  TextField,
  Grid2 as Grid,
  Select,
  MenuItem,
  Button,
  styled,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

const FinancialDetails = ({ price, onformdata }) => {
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentAuthority, setPaymentAuthority] = useState("");
  const [administrationApproval, setAdministrationApproval] = useState("");
  const [financialApproval, setFinancialApproval] = useState("");
  const [hodApproval, setHodApproval] = useState("");
  const [cdaCode, setCdaCode] = useState("");
  const [codeHead, setCodeHead] = useState("");
  const [unitCode, setUnitCode] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [filePdf, setFilePdf] = useState(null);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  // Screen Size for responsiveness
  const isLargeScreen = useMediaQuery("(max-width: 992px)");
  const isMinSmallScreen = useMediaQuery("(min-width: 568px) ");

  const handleRadioChange = (event) => {
    if (event.target.value === "Unit") {
      setOpen(true);
    }
    setPaymentMode(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePaymentAuthority = (event) => {
    setPaymentAuthority(event.target.value);
  };

  const handleAministrationApproval = (event) => {
    setAdministrationApproval(event.target.value);
  };

  const handleFinancialApproval = (event) => {
    setFinancialApproval(event.target.value);
  };

  const handleHODApproval = (event) => {
    setHodApproval(event.target.value);
  };

  const handleCDACode = (event) => {
    setCdaCode(event.target.value);
  };

  const handleCodeHead = (event) => {
    setCodeHead(event.target.value);
  };

  const handleUnitCode = (event) => {
    setUnitCode(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFilePdf(file);
  };

  useEffect(() => {
    const formData = {
      paymentBy: paymentMode,
      paymentAuthority,
      administrationApproval,
      financialApproval,
      filePdf,
      hodApproval,
      cdaCode,
      headCode: codeHead,
      unitCode,
      amount: price,
    };
    onformdata(formData);
  }, [
    paymentMode,
    paymentAuthority,
    administrationApproval,
    financialApproval,
    hodApproval,
    cdaCode,
    filePdf,
    codeHead,
    unitCode,
    price,
  ]);

  const formControlStyle =
    "d-flex flex-column flex-sm-row gap-1 gap-sm-5 mt-4 align-items-sm-center";
  const styleLabel = {
    fontSize: isLargeScreen ? "14px" : "16px",
    fontWeight: 500,
    width: isMinSmallScreen ? "70%" : "100%",
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <div className="financial_details__container">
        <div className="border rounded p-3">
          <FormControl className="w-100 mb-4">
            <div className={`${formControlStyle}`}>
              <label style={styleLabel} htmlFor="payment" className="gilroy">
                Payment By CDA/Unit*
              </label>
              <RadioGroup
                row
                aria-labelledby="financial-details"
                name="paymentMethod"
                id="payment"
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="CDA"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      CDA
                    </p>
                  }
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Unit"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      Unit
                    </p>
                  }
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          </FormControl>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Payment Mode Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Selection of this method is applicable to only Post Audit bills
                wherein payment will be made bby the Unit not CDA. Once demand
                created with selected payment mode, cannot be revoked.
              </DialogContentText>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                >
                  Okay
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>

          <Grid container spacing={{ xs: 0, sm: 3 }}>
            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy"
              >
                Select Payment Authority*
              </p>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <Select
                value={paymentAuthority}
                fullWidth
                size="small"
                onChange={handlePaymentAuthority}
              >
                <MenuItem value="">Select Payment Authority</MenuItem>
                <MenuItem value={10}>Payment Authority - 1</MenuItem>
                <MenuItem value={20}>Payment Authority - 2</MenuItem>
                <MenuItem value={30}>Payment Authority - 3</MenuItem>
              </Select>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy mt-3 mt-sm-0"
              >
                Designation of official providing administration approval*
              </p>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <TextField
                fullWidth
                size="small"
                value={administrationApproval}
                onChange={handleAministrationApproval}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy mt-3 mt-sm-0"
              >
                Designation of official providing financial approval*
              </p>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <TextField
                fullWidth
                size="small"
                value={financialApproval}
                onChange={handleFinancialApproval}
              />
            </Grid>
          </Grid>
          <FormControl className="w-100 pt-4 mt-sm-0">
            <div
              className={`d-flex flex-column flex-sm-row gap-1 gap-sm-5 align-items-sm-center`}
            >
              <label style={styleLabel} htmlFor="payment" className="gilroy">
                IFD Concurrence / Component Authority (HOD/ Head of Office)
                Approval Required?
              </label>
              <RadioGroup
                row
                aria-labelledby="financial-details"
                name="ifdApproval"
                id="ifdApproval"
                onChange={handleHODApproval}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      Yes
                    </p>
                  }
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      No
                    </p>
                  }
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          </FormControl>

          <Grid container spacing={{ xs: 0, sm: 3 }}>
            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy mt-3 mt-sm-0"
              >
                CDA Code
              </p>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <Select
                value={cdaCode}
                fullWidth
                size="small"
                onChange={handleCDACode}
              >
                <MenuItem value="">Please Select CDA code</MenuItem>
                <MenuItem value={10}>CDA 1111</MenuItem>
                <MenuItem value={20}>CDA 2222</MenuItem>
                <MenuItem value={30}>CDA 3333</MenuItem>
              </Select>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy mt-3 mt-sm-0"
              >
                Select Code head*
              </p>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <Select
                value={codeHead}
                fullWidth
                size="small"
                onChange={handleCodeHead}
              >
                <MenuItem value="">Please Select CDA head</MenuItem>
                <MenuItem value={10}>CDA Head-1</MenuItem>
                <MenuItem value={20}>CDA Head-2</MenuItem>
                <MenuItem value={30}>CDA Head-3</MenuItem>
              </Select>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy mt-3 mt-sm-0"
              >
                Unit Code
              </p>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <TextField
                fullWidth
                size="small"
                value={unitCode}
                onChange={handleUnitCode}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy mt-3 mt-sm-0"
              >
                Amount To Be Paid
              </p>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "#000000",
                }}
                className="m-0 gilroy"
              >
                &#8377; {price}
              </p>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy mt-3 mt-sm-0"
              >
                Financial Approval*
              </p>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
              <div className="d-flex flex-column flex-sm-row justify-content-start align-items-start">
                <Button
                  sx={{ width: "8rem" }}
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={
                    <CloudUpload
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  }
                  className="gilroy"
                  size="small"
                >
                  Choose files
                  <VisuallyHiddenInput
                    type="file"
                    accept=".pdf" // Only accept PDF files
                    onChange={handleFileChange} // Handle file selection
                    multiple
                  />
                </Button>
                {/* Show file names next to the button */}
                <span style={{ marginLeft: "1rem" }}>{filePdf?.name}</span>
                {/* Show error message if any file exceeds 10MB */}
                {error && (
                  <div style={{ color: "red", marginTop: "0.5rem" }}>
                    {error}
                  </div>
                )}
              </div>
              <FormHelperText className="p-0 m-0 ">
                Please Upload file in pdf format with no exceeding 10MB
              </FormHelperText>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default FinancialDetails;
