import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Form from "react-bootstrap/Form";
import {
  Button,
  Modal,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { custLoginInAPI } from "../../../../Config/apiConfig";
import { custLoginOtpVerifyAPI } from "../../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPage = ({ loginOpen, handleLoginClose, style }) => {
  const [emailId, setEmailId] = useState("");
  const [otp, setOtp] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  const emailErrorMessage = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailId = (event) => {
    const email = event.target.value;
    setEmailId(email);
  };

  useEffect(() => {
    if (emailErrorMessage(emailId)) {
      setErrorEmail(false);
    }
  }, []);

  const handleOtp = (event) => {
    setOtp(event.target.value.replace(/\D/g, "").slice(0, 6));
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleGenerateOtp = async () => {
    if (!emailErrorMessage(emailId)) {
      setErrorEmail(true);
      return;
    }
    try {
      const response = await axios.post(custLoginInAPI, {
        email: emailId,
      });

      if (response?.data && response.status === 200) {
        setLoginId(response.data.data);
        toast.success(response.data.msg);
      }
    } catch (error) {
      if (error) {
        // toast.error(error.response.data.message);
        console.log("Otp error", error.response.data);
      }
    }
  };

  const hadleVerifyOtp = async () => {
    try {
      if (otp) {
        const response = await axios.post(custLoginOtpVerifyAPI, {
          id: loginId,
          otp: otp,
        });
        if (response?.data && response.status === 200) {
          localStorage.setItem(
            "custLoginCredentials",
            JSON.stringify(response.data)
          );
          toast.success(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          setEmailId("");
          handleLoginClose();
        }
      }
    } catch (error) {
      if (error?.response) {
        console.log("error on otp", error.response.data);
      }
    }
  };

  return (
    <>
      <Modal open={loginOpen} onClose={handleLoginClose}>
        <Box sx={style} mx className="no-scrollbar">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-3">
              <CloseIcon
                onClick={handleLoginClose}
                fontSize="small"
                color="rgba(26, 27, 30, 1)"
              />
              <p
                style={{
                  fontFamily: "Gilroy-SemiBold",
                  fontSize: "28px",
                  color: "rgba(19, 101, 175, 1)",
                }}
                className="register_to_continue m-0"
              >
                Welcome Back!&nbsp;
                <span style={{ color: "rgba(0, 0, 0, 1)" }}>Please Login</span>
              </p>
            </div>
            <div className="registration_page_form mt-3">
              <div
                style={{
                  gap: "29px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "rgba(26, 27, 30, 1)",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    gap: "4px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  Email ID
                  <TextField
                    type="email"
                    size="small"
                    sx={{ color: "#6A7D9F" }}
                    className="gilroy-regular"
                    placeholder="Enter Email Id"
                    value={emailId}
                    onChange={handleEmailId}
                    error={errorEmail}
                    helperText={
                      errorEmail ? "Please enter a valid email address" : ""
                    }
                    variant="outlined"
                  />
                  <Button
                    sx={{
                      px: {
                        lg: "12px",
                        md: "1px",
                      },
                      marginTop: "10px",
                    }}
                    style={{ alignSelf: "flex-start" }}
                    className="nav-icon-btn2 gilroy"
                    onClick={handleGenerateOtp}
                  >
                    Get OTP
                  </Button>
                </div>

                <div
                  style={{
                    color: "rgba(26, 27, 30, 1)",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  OTP
                  <TextField
                    type="text"
                    size="small"
                    sx={{ color: "#6A7D9F" }}
                    className="gilroy-regular"
                    placeholder="Enter Email Id"
                    value={otp}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, ""); // Non-numeric characters remove karega
                      if (value.length <= 6) {
                        handleOtp(e);
                      }
                    }}
                    variant="outlined"
                  />
                  <div
                    style={{
                      fontFamily: "Gilroy-Medium",
                      fontSize: "15px",
                      color: "rgba(128, 128, 127, 1)",
                      display: "flex",
                      alignSelf: "flex-end",
                    }}
                  >
                    Didn’t receive code?&nbsp;
                    <span style={{ color: "rgba(19, 101, 175, 1)" }}>
                      Resend
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <FormGroup>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="small"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                    }
                    label="Remember me"
                    style={{ fontFamily: "Gilroy-Regular" }}
                  />
                </FormGroup>
              </div>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center gap-3">
                <Button
                  sx={{
                    px: {
                      lg: "12px",
                      md: "1px",
                    },
                    backgroundColor: "rgba(19, 101, 175, 1)",
                    "&:hover": {
                      backgroundColor: "rgba(19, 101, 175, 1)",
                    },
                    fontSize: "16px",
                    color: "#ffffff",
                    textTransform: "capitalize",
                    textAlign: "center",
                    fontFamily: "Gilroy-SemiBold",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    padding: "10px 20px",
                  }}
                  className="gilroy"
                  onClick={hadleVerifyOtp}
                >
                  Login
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default LoginPage;
