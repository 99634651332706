import React, { useState } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  styled,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";

const CustomKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme }) => ({
    color: "rgba(42, 23, 0, 1)",
  })
);

export default function MyOrderSort({ onCheck }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    const newValue = selectedOptions === value ? "Default" : value;
    setSelectedOptions(newValue);
    onCheck(newValue);
  };

  return (
    <Box sx={{ minWidth: 100 }} className="me-2">
      <FormControl fullWidth>
        <Select
          value={selectedOptions}
          displayEmpty
          size="small"
          onChange={handleChange}
          IconComponent={CustomKeyboardArrowDownIcon}
          inputProps={{ "aria-label": "Select an Sort" }}
          sx={{
            backgroundColor: "rgba(255, 221, 184, 1)",
            "&:hover": {
              backgroundColor: "rgba(255, 221, 184, 1)",
            },
            color: "rgba(42, 23, 0, 1)",
            padding: "0px 10px",
            fontSize: "0.875rem",
          }}
          renderValue={() => (
            <span style={{ color: "#2a1700" }} className="fw-bold gilroy">
              <FontAwesomeIcon
                icon={faArrowRightArrowLeft}
                transform={{ rotate: 90 }}
                style={{
                  color: "#2a1700",
                  fontSize: "20px",
                  marginBottom: "-4px",
                }}
              />{" "}
              &nbsp;Sort
            </span>
          )}
        >
          <MenuItem value="" disabled>
            <span
              style={{ color: "rgba(42, 23, 0, 1)" }}
              className="fw-bold gilroy"
            >
              Sort
            </span>
          </MenuItem>
          {[
            "Newest",
            "Oldest",
            "Pending",
            "Confirm",
            "Delivered",
            "Canceled",
          ].map((name) => (
            <MenuItem
              key={name}
              value={name}
              onClick={() => handleChange({ target: { value: name } })}
            >
              <Checkbox checked={selectedOptions === name} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
