import React, { useState, useEffect } from "react";
import "./DashboardCarousel.css";
import Carousel from "react-bootstrap/Carousel";
import { Button } from "@mui/material";
import { dashboardSliderAPI } from "../../../Config/apiConfig";
import axios from "axios";

const DashboardCarousel = () => {
  const [dashboardSlider, setDashboardSlider] = useState([]);
  useEffect(() => {
    const fetchHomeSliderApi = async () => {
      const loginResponse = localStorage.getItem("loginCredentials");
      const parsedData = JSON.parse(loginResponse);
      if (parsedData?.token) {
        try {
          const response = await axios.get(dashboardSliderAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response?.data && response?.status === 200) {
            const sliderContent = response.data.data;
            // console.log("slider", response.data.data);
            console.log(response?.data?.data?.slider);
            setDashboardSlider(response?.data?.data?.slider);
            // setHomeSlider(sliderContent);
            // console.log("sliderContent", sliderContent.category);
          }
        } catch (error) {
          console.log("error in api ", error);
        }
      }
    };
    fetchHomeSliderApi();
  }, []);

  return (
    <>
      <div className="dashboard__carousel">
        <Carousel className="mt-5 w-100 px-0 mx-0">
          {dashboardSlider &&
            dashboardSlider.map((item, index) => (
              <Carousel.Item key={index}>
                <div className="dashboard_carousel_img">
                  <img
                    src={item.path}
                    alt={item.title}
                    className="d-block w-100 h-100"
                  />
                </div>

                {/* <Carousel.Caption>
                <h3 className="dashboard_banner_heading">
                  <span style={{ color: "#1365AF" }}>Looking for services? </span>
                  <br />
                  <span style={{ color: "#000000" }}>Well, look no further!</span>
                </h3>
  
                <Button
                  style={{ background: "#1365AF", color: "white" }}
                  className="gilroy px-3"
                >
                  Start a Bidding
                </Button>
              </Carousel.Caption> */}
              </Carousel.Item>
            ))}
        </Carousel>
      </div>
    </>
  );
};

export default DashboardCarousel;
