import React, { useEffect, useState } from "react";
import "./Overview.css";
import {
  useMediaQuery,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Pencil from "../../../../../Assets/Icons/pencil.png";
import { sellerOverviewEditAPI } from "../../../../../Config/apiConfig";
import axios from "axios";

const Overview = ({ overviewData, profId }) => {
  // console.log("overviw", overviewData);
  console.log("profileId", profId);

  const [overview, setOverview] = useState({});
  const [emploeyees, setEmploeyees] = useState("");
  const [experience, setExperience] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [about, setAbout] = useState("");
  const [open, setOpen] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width:576px)");

  useEffect(() => {
    if (overviewData) {
      setOverview(overviewData);
      setEmploeyees(overview?.no_of_employees || "");
      setExperience(overview?.experience || "");
      setWorkingHours(overview?.working_hours || "");
      setAbout(overview?.about_brand || "");
    }
  }, [overviewData]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formData = {
    id: profId,
    no_of_employees: emploeyees,
    experience,
    working_hours: workingHours,
    about_brand: about,
  };

  const handleSave = async () => {
    const loginResponse = sessionStorage.getItem("loginCredentials");
    const parsedData = JSON.parse(loginResponse);
    if (parsedData) {
      try {
        const response = await axios.put(sellerOverviewEditAPI, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response?.data && response.status === 200) {
          handleClose();
          window.location.reload();
        }
      } catch (error) {
        console.log("Error updating social links", error);
      }
    }
  };

  return (
    <div className="mt-5 px-2 pt-4">
      <div className="account_overview d-flex justify-content-between gap-2 align-items-center">
        <h4 className="overview_heading  gilroy ">Overview</h4>

        <div>
          <Button
            onClick={handleOpen}
            style={{
              backgroundColor: "rgb(238,238,244)",
              fontSize: isSmallScreen ? "14px" : "16px",
              width: isSmallScreen ? "20px" : "80px",
              height: "40px",
            }}
            className="rounded-pill text-capitalize gilroy"
          >
            <img src={Pencil} alt="edit pen" className="edit_pen_size" />
            <span className="d-none d-sm-inline">&nbsp;Edit</span>
          </Button>
        </div>
      </div>
      <div className=" d-flex flex-column flex-sm-row gap-2 gap-sm-5 my-1">
        <div className="overview_info_box employee-count">
          <p className="overview-heading gilroy p-0 my-2">Employee count</p>
          <p className="overview-info gilroy m-0 p-0 ">
            {overviewData?.no_of_employees}
          </p>
        </div>
        <div className="overview_info_box experience">
          <p className="overview-heading gilroy p-0 my-2">Experience</p>
          <p className="overview-info gilroy m-0 p-0 ">
            Since {overview?.experience}
          </p>
        </div>
        <div className="overview_info_box working-hours">
          <p className="overview-heading gilroy p-0 my-2">Working Hours </p>
          <p className="overview-info gilroy m-0 p-0 ">
            {overview?.working_hours}
          </p>
        </div>
      </div>
      <div className="overview_info_box mt-4">
        <p className="overview-heading gilroy p-0 my-2">About</p>
        <div className="overview-para rounded p-3 p-sm-4 mt-4">
          {overview?.about_brand}
        </div>
      </div>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          <TextField
            fullWidth
            size="small"
            margin="dense"
            label="No. of Employees"
            value={emploeyees}
            onChange={(e) => setEmploeyees(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            margin="dense"
            label="Experience"
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
          />

          <TextField
            fullWidth
            size="small"
            margin="dense"
            label="Working Hours"
            value={workingHours}
            onChange={(e) => setWorkingHours(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            margin="dense"
            label="About"
            multiline
            rows={4}
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Overview;
