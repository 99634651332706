import React, { useEffect, useState } from "react";
import "./EmailVerification.css";
import Button from "@mui/material/Button";
import { Box, TextField, useMediaQuery } from "@mui/material";
import { registerEmailAPI, verifyOtpAPI } from "../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function EmailVerification({ onDataChange, onValidate }) {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [emailId, setEmailId] = useState("");
  const [isOtpDisabled, setIsOtpDisabled] = useState(true);

  // const emailApi = "http://65.1.109.1/api/register/registerOtp";
  // const verifyOtpAPI = "http://65.1.109.1/api/register/verifyOtp";

  const isSmallScreen = useMediaQuery("(max-width: 576px) ");

  useEffect(() => {
    const storeData = localStorage.getItem("apiResponseData");
    console.log(storeData);
    if (storeData) {
      const parsedData = JSON.parse(storeData);
      if (parsedData.data._id) {
        setEmailId(parsedData.data._id);
        console.log(parsedData.data._id);
      }
    }
  }, []);

  const handleChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    onDataChange({ email: newEmail });

    // Clear the error for the email field when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "",
    }));
  };

  const handleOtpChange = (event, index) => {
    const value = event.target.value;

    // Allow only numbers (0-9) or an empty value (for backspace)
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field if the current one is filled
      if (value !== "" && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
      // Handle Backspace behavior - focus on the previous input if value is empty
      if (value === "" && index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email address";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  onValidate(validateForm);

  const handleGenerateOtp = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post(registerEmailAPI, {
          email,
          id: emailId,
        });
        if (response) {
          const registerMsg = response.data.message;
          toast.success(registerMsg);
          setOtp(Array(6).fill(""));
          setIsOtpDisabled(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const errorMsg = error.response.data.error;
          toast.warn(errorMsg);
          setOtp(Array(6).fill(""));
          setIsOtpDisabled(true);
        } else {
          console.error("Error sending OTP:", error);
        }
      }
    }
  };

  useEffect(() => {
    const enteredOtp = otp.join("");
    if (enteredOtp.length === 6) {
      verifyOtp();
    }
  }, [otp]);

  const verifyOtp = async () => {
    const enteredOtp = otp.join("");
    try {
      const response = await axios.post(verifyOtpAPI, {
        id: emailId,
        otp: enteredOtp,
      });
      if (response.status === 200) {
        const otpVerifiedMsg = response.data.msg;
        toast.success(otpVerifiedMsg);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const otpVerifiedMsg = error.response.data.error;
        toast.error(otpVerifiedMsg);
      } else if (error.response && error.response.status === 409) {
        const otpVerifiedMsg = error.response.data.error;
        toast.warn(otpVerifiedMsg);
      }
    }
  };

  return (
    <>
      <div className="adhar-card-number-container ms-2 mt-2">
        <div className="d-flex p-0 mb-2 align-items-center px-2">
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{
              color: "#275ea7",
              fontSize: isSmallScreen ? "18px" : "20px",
            }}
          />
          <p
            style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
            className="my-auto gilroy fw-bold ms-2"
          >
            Active Email ID
          </p>
        </div>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { my: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            type="email"
            size="small"
            inputMode="email"
            fullWidth={true}
            value={email}
            onChange={handleChange}
            required
            error={!!errors.email}
            helperText={errors.email}
          />
        </Box>
      </div>
      <div className="generate-otpbutton ms-2 mt-2">
        <Button
          style={{
            backgroundColor: "rgba(250, 155, 0, 1)",
            color: "#fff",
            textTransform: "none",
          }}
          onClick={handleGenerateOtp}
        >
          Generate OTP
        </Button>
      </div>

      <div className="six-digit-otp ms-2 mt-4 py-3 px-2">
        <p
          style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
          className="my-auto gilroy fw-bold"
        >
          Enter the 6-digit code sent to your email ID
        </p>
        <div className="otp-inputBox d-flex gap-2 mt-2">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              value={digit}
              onChange={(e) => handleOtpChange(e, index)}
              className="otp-box p-2 fs-6 fs-sm-5 fw-bold border rounded gilroy"
              length={1}
              minLength={1}
              maxLength={1}
              id={`otp-input-${index}`}
              style={{
                fontSize: "14px",
                textAlign: "center",
                width: isSmallScreen ? "30px" : "40px",
                height: isSmallScreen ? "30px" : "40px",
              }}
              autoComplete="off"
              disabled={isOtpDisabled}
              inputMode="numeric"
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default EmailVerification;
