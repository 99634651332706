import React, { useState, useEffect } from "react";
import "./ManageBankAccounts.css";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@mui/material";

const ManageBankAccounts = ({ onAddNewBankAccount }) => {
  const [bankData, setBankData] = useState([]);

  // Screen Size for responsiveness ------------
  const isLargeScreen = useMediaQuery("(max-width:992px)");
  const isMediumScreen = useMediaQuery("(max-width: 768px) ");
  const isInBetween768To992px = useMediaQuery(
    "(min-width: 768px) and (max-width: 992px)"
  );
  const isVerySmallScreen = useMediaQuery("(max-width: 435px)");

  const tableHeadStyle = {
    fontSize: "13px",
    fontWeight: 600,
    color: "#ffffff",
  };

  useEffect(() => {
    const bankResponse = localStorage.getItem("bankDetails");
    const parsedbank = JSON.parse(bankResponse) || [];
    console.log("bank ", parsedbank);
    setBankData(parsedbank);
  }, []);

  const handleDelete = (index) => {
    const newBankData = bankData.filter((_, i) => i !== index);
    localStorage.setItem("bankDetails", JSON.stringify(newBankData));

    setBankData(newBankData);
  };

  const renderMessage = () => {
    const rowCount = bankData.length;
    if (rowCount === 0) {
      return (
        <TableCell colSpan={3} className="gilroy ">
          No Accounts added
        </TableCell>
      );
    } else {
      return (
        <TableCell colSpan={3} className="gilroy">
          {rowCount} of {rowCount} Bank Account{rowCount > 1 ? "s" : ""}
          &nbsp;Displayed
        </TableCell>
      );
    }
  };

  return (
    <>
      <div className="manage_bank_accounts__container ">
        <p
          style={{
            fontSize: isVerySmallScreen ? "12px" : "14px",
            backgroundColor: "#d4e8fc",
            textTransform: "none",
          }}
          className="p-3 gilroy rounded "
        >
          Public Finance Management System (PFMS) is mandatory to receive money
          from buyers using PFMS method of payment. Enter your PFMS verified
          account for better experience <br /> <br />
          Doesn't have a PFMS verification yet? Dont't worry, you can prceed
          with a nont-PFMS verified accounts now and come back to section later.
        </p>
        <div
          style={{ width: isInBetween768To992px ? "28rem" : "100%" }}
          className="bank_accounts__table mt-4 "
        >
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead
                style={{
                  backgroundColor: "#9ABAFA",
                }}
              >
                <TableRow>
                  <TableCell
                    style={tableHeadStyle}
                    className="gilroy"
                    align="center"
                  >
                    IFSC
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      color: "#ffffff",
                      width: "12rem",
                    }}
                    className="gilroy"
                    align="center"
                  >
                    Bank Name
                  </TableCell>
                  <TableCell
                    style={tableHeadStyle}
                    className="gilroy"
                    align="center"
                  >
                    Bank Account Number
                  </TableCell>
                  <TableCell
                    style={tableHeadStyle}
                    className="gilroy"
                    align="center"
                  >
                    Account Holder Name
                  </TableCell>
                  {/* <TableCell
                    style={tableHeadStyle}
                    className="gilroy"
                    align="center"
                  >
                    PFMS Code
                  </TableCell> */}
                  <TableCell
                    style={tableHeadStyle}
                    className="gilroy"
                    align="center"
                  >
                    Is Primary?
                  </TableCell>
                  <TableCell
                    style={tableHeadStyle}
                    className="gilroy"
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bankData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ fontSize: "12px" }}
                      className="gilroy border-end"
                    >
                      {row.ifscCode}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "12px" }}
                      className="gilroy border-end"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "12px" }}
                      className="gilroy border-end"
                    >
                      {row.accountNo}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "12px" }}
                      className="gilroy border-end"
                    >
                      {row.holderName}
                    </TableCell>
                    {/* <TableCell
                      style={{ fontSize: "12px" }}
                      className="gilroy border-end"
                    >
                      {row.pfmsCode}
                    </TableCell> */}
                    <TableCell
                      style={{ fontSize: "12px" }}
                      className="gilroy border-end"
                    >
                      {row.isPrimary}
                    </TableCell>
                    {/* Delete button should not be seen if bank account is verified */}
                    <TableCell className="gilroy">
                      <Button
                        style={{
                          textTransform: "none",
                          marginRight: isMediumScreen ? "0" : "5px",
                          padding: "3px 5px",
                          border: "0.5px solid #1365AF",
                        }}
                        onClick={() => handleDelete(index)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  {renderMessage()}
                  <TableCell colSpan={3} align="right">
                    <Button
                      varient="contained"
                      style={{
                        textTransform: "none",
                        backgroundColor: "#fa9b00",
                        color: "#ffffff",
                        padding: "3px 8px",
                      }}
                      onClick={onAddNewBankAccount}
                    >
                      Add New Bank Account
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default ManageBankAccounts;
