import React, { useEffect, useState } from "react";
import { Box, Button, Stack, useMediaQuery } from "@mui/material";
import ManageTaxAssessments from "./ManageTaxAssessments/ManageTaxAssessments";
import AddNewTaxAssessment from "./AddNewTaxAssessment/AddNewTaxAssessment";

const TaxAssessments = ({ data }) => {
  const [currentPage, setCurrentPage] = useState("manageTaxAssessments");
  const [isPanNum, setIsPanNum] = useState("");
  const [editTaxIndex, setEditTaxIndex] = useState(-1);

  // Screen Size for responsiveness ------------
  const isVerySmallScreen = useMediaQuery("(max-width: 435px) ");

  const handleAddNewAssessment = (index) => {
    setEditTaxIndex(index !== undefined ? index : -1);
    setCurrentPage("addNewTaxAssessment");
  };

  const handleManageAssessment = () => {
    setEditTaxIndex(-1); // Reset edit index when going back to manage offices
    setCurrentPage("manageTaxAssessments");
  };

  useEffect(() => {
    if (data) {
      setIsPanNum(data);
    }
  }, [data]);

  return (
    <>
      <div className="tax_assements__container">
        <p
          style={{
            fontSize: isVerySmallScreen ? "16px" : "18px",
            textTransform: "none",
          }}
          className="text-sm-center gilroy-regular"
        >
          In case your date of Incorporation is more than 24 months and you are
          not providing ITR records than you will not be able to participate in
          bids. Your turnover will not reflect in the markets as well.
        </p>
        <p
          style={{
            fontSize: isVerySmallScreen ? "12px" : "13px",
            backgroundColor: "#FFEBBC",
            padding: "10px",
            borderRadius: "4px",
            textTransform: "none",
          }}
          className="gilroy-regular"
        >
          If you are a Proprietor extemted from filling ITR, please update and
          verify GSTIN for your Registered Office Location
        </p>
        <Box>
          <hr />
          <Stack direction="row" spacing={isVerySmallScreen ? 2 : 3}>
            <Button
              style={{
                fontSize: isVerySmallScreen ? "12px" : "14px",
                padding: "5px 10px",
                borderColor:
                  currentPage === "manageTaxAssessments"
                    ? "#fa9b00"
                    : "#B0B0B0",
                color:
                  currentPage === "manageTaxAssessments"
                    ? "#fa9b00"
                    : "#000000",
              }}
              onClick={handleManageAssessment}
              variant="outlined"
              className="gilroy"
            >
              Manage Tax Assessments
            </Button>
            <Button
              style={{
                fontSize: isVerySmallScreen ? "12px" : "14px",
                padding: "5px 10px",
                borderColor:
                  currentPage === "addNewTaxAssessment" ? "#fa9b00" : "#B0B0B0",
                color:
                  currentPage === "addNewTaxAssessment" ? "#fa9b00" : "#000000",
              }}
              onClick={() => handleAddNewAssessment()}
              variant="outlined"
              className="gilroy"
            >
              Add New Tax Assessment
            </Button>
          </Stack>
          <hr />
          <Box>
            {currentPage === "manageTaxAssessments" && (
              <Box className="mt-4">
                <ManageTaxAssessments
                  onAddNewTaxAssessment={handleAddNewAssessment}
                />
              </Box>
            )}

            {currentPage === "addNewTaxAssessment" && (
              <Box className="mt-4">
                <AddNewTaxAssessment
                  editTaxIndex={editTaxIndex}
                  setEditTaxIndex={setEditTaxIndex}
                  onSaveComplete={handleManageAssessment}
                  panValue={isPanNum}
                />
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default TaxAssessments;
