import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "../Profile.css";
import { useMediaQuery } from "@mui/material";
import Pencil from "../../../../../Assets/Icons/pencil.png";
import { sellerBusinessNameEditAPI } from "../../../../../Config/apiConfig";
import axios from "axios";

const BusinessInformation = ({ businessNameData, businessID }) => {
  const [businessName, setBusinessName] = useState("");
  const [busiId, setBusiId] = useState("");
  const [open, setOpen] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const [bussinessName, setBussinessName] = useState("");

  useEffect(() => {
    console.log("id", businessID);
    if (businessNameData) {
      setBusinessName(businessNameData || "");
    }
  }, [businessNameData]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    const loginResponse = sessionStorage.getItem("loginCredentials");
    const parsedData = JSON.parse(loginResponse);
    if (parsedData) {
      try {
        const response = await axios.put(
          sellerBusinessNameEditAPI,
          {
            id: businessID,
            businessName,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          }
        );
        if (response?.data && response.status === 200) {
          handleClose();
          window.location.reload();
        }
      } catch (error) {
        console.log("Error updating social links", error);
      }
    }
  };
  return (
    <div className="mt-5 px-0 mb-2">
      <div className="d-flex justify-content-between gap-2 align-items-center">
        <div>
          <p className="business_name_lable p-0 m-0">Business display name</p>
          <h1 className=" business_name_text p-0 m-0 gilroy ">
            {businessNameData}
          </h1>
        </div>
        <div>
          <Button
            onClick={handleOpen}
            style={{
              backgroundColor: "rgb(238,238,244)",
              fontSize: isSmallScreen ? "14px" : "16px",
              width: isSmallScreen ? "20px" : "80px",
              height: "40px",
            }}
            className="rounded-pill text-capitalize gilroy"
          >
            <img src={Pencil} alt="edit pen" className="edit_pen_size" />
            <span className="d-none d-sm-inline">&nbsp;Edit</span>
          </Button>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Business Name"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BusinessInformation;
