import React, { useEffect, useState } from "react";
import "./ProductDetails.css";
import {
  useMediaQuery,
  Rating,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  Box,
  FormControl,
  TextField,
} from "@mui/material";
import { ArrowBackRounded, CloseRounded } from "@mui/icons-material";
import DescriptionBox from "./DescriptionBox/DescriptionBox";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { custAllProductsAPI } from "../../../Config/apiConfig";
import { custAddToCartAPI } from "../../../Config/apiConfig";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(2.5);
  const [quantiy, setQuantiy] = useState(0);
  const [productDetailsData, setProductDetailsData] = useState([]);
  const [productRecommended, setProductRecommended] = useState([]);
  const [open, setOpen] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [state, setState] = useState("");
  // const [location, setLocation] = useState("");
  const [purchaseQuantity, setPurchaseQuantity] = useState("");

  // Screen Size for responsiveness ------------------
  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isTabSmallScreen = useMediaQuery(" (max-width:648px) ");
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");
  const isBet1024and1080 = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1081px)"
  );

  // const prodID = useSelector(
  //   (state) => state.productCateId.prodID
  // );

  const { prodID } = location.state || {};

  useEffect(() => {
    const fetchCustAllProductsAPI = async () => {
      try {
        if (prodID) {
          const response = await axios.get(custAllProductsAPI + "/" + prodID);
          if (response.status === 200) {
            console.log(response.data.data.product[0]);
            console.log("cart Deta", response.data.data);
            const productData = response.data.data.product[0];
            const recommended = response.data.data.recommended;

            setProductDetailsData(productData);

            setProductRecommended(recommended);
          }
        }
      } catch (error) {
        console.error("Error fetching product details", error);
        // Optionally, show an error message in the UI
      }
    };

    fetchCustAllProductsAPI();
  }, [prodID]);

  const handleQuantityDec = () => {
    if (quantiy <= productDetailsData.minQty) {
      toast.warn(
        `The Minimum Quantity For This Product is ${productDetailsData.minQty}`
      );
      return;
    }
    setQuantiy((prevCount) => prevCount - 1);
  };

  const handleQuantityInc = () => {
    if (quantiy >= productDetailsData.maxQty) {
      toast.warn(
        `The Maximum Quantity For This Product is ${productDetailsData.maxQty}`
      );
      return;
    }
    setQuantiy((prevCount) => prevCount + 1);
  };

  const handleAddToCartBtn = async (id, quantity) => {
    const productId = id;
    const finalQuantity =
      quantity < productDetailsData.minQty
        ? productDetailsData.minQty
        : quantity;
    console.log(id, finalQuantity);
    try {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);

      if (parsedData && parsedData.token) {
        const response = await axios.post(
          custAddToCartAPI,
          {
            id: productId,
            qty: String(finalQuantity),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          }
        );

        if (response && response.status === 200) {
          toast.success(response.data.msg);
        }
      } else {
        toast.warn("Please login before adding to cart your product");
      }
    } catch (error) {}
  };

  const handleBuyNow = () => {
    const custLoginResponse = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(custLoginResponse);
    handleAddToCartBtn(productDetailsData._id, quantiy); // Add to cart first
    if (parsedData && parsedData.token) {
      navigate("/cust-product-cart"); // Then navigate to cart
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <div className="product_details__container">
        {/* Heading */}
        <div className="d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "35px" }}
            className="back-arrow mt-1 fw-bold"
            onClick={() => {
              navigate("/cust-products");
            }}
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2.5em" }}
              className="heading fw-bold gilroy"
            >
              Products Details
            </h1>

            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              /{" "}
              <Link
                underline="none"
                href={"/cust-products"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Products & Services{" "}
              </Link>{" "}
              / Product Details
            </p>
          </div>
        </div>
        {/* Product Details */}

        <div key={productDetailsData._id} className="w-100">
          {/* Product Images, Add To Cart and Buy Now Buttons */}
          <section className="product-details_content_imgs d-flex flex-column flex-md-row gap-4 my-5 justify-content-center justify-content-md-start align-items-center align-items-md-start ">
            <div
              className={` product-details__images d-flex ${
                isTabSmallScreen ? "flex-column-reverse" : "flex-row"
              } flex-md-column-reverse flex-lg-row gap-4`}
            >
              <div
                id="products-small__imgs"
                className={`d-flex ${
                  isTabSmallScreen ? "flex-row" : "flex-column"
                }  flex-md-row flex-lg-column gap-4`}
              >
                <div id="products__img_1">
                  <img
                    src={productDetailsData.image}
                    alt="products-img"
                    className="products-small-img rounded"
                  />
                </div>
                <div id="products__img_1">
                  <img
                    src={productDetailsData.image}
                    alt="products-img"
                    className="products-small-img rounded"
                  />
                </div>
                <div id="products__img_1">
                  <img
                    src={productDetailsData.image}
                    alt="products-img"
                    className="products-small-img rounded"
                  />
                </div>
              </div>
              <div id="products-big__img">
                <img
                  src={productDetailsData.image}
                  alt="products-img"
                  className="products-img rounded"
                />
              </div>
            </div>
            <div id="product-details__data" className="w-100">
              <div className="products_details__data">
                <h3 className="product_details__title text-capitalize gilroy-regular">
                  {productDetailsData.title}
                </h3>
              </div>
              <div className="product_details__quantity d-flex flex-row gap-3">
                <span className="details__quantity gilroy">
                  Mn. Qty: {productDetailsData.minQty}
                </span>
                <span className="details__quantity gilroy">to</span>
                <span className="details__quantity gilroy">
                  Max. Qty: {productDetailsData.maxQty}
                </span>
              </div>
              <div className=" quantity_count d-flex align-items-center my-2">
                <button
                  className="quantity_decreament  border-end-0 rounded-start"
                  onClick={handleQuantityDec}
                >
                  -
                </button>
                <p className="quantity_input m-0 text-center p-1 px-0 gilroy">
                  {quantiy < productDetailsData.minQty
                    ? productDetailsData.minQty
                    : quantiy}
                </p>
                <button
                  className=" quantity_increment border-start-0 rounded-end"
                  onClick={handleQuantityInc}
                >
                  +
                </button>
              </div>
              <div className="product_details__raitings d-flex align-items-center gap-2 ">
                <Rating
                  name="half-rating-read"
                  readOnly
                  defaultValue={Number(productDetailsData.star)}
                  precision={0.5}
                  sx={{
                    fontSize: isLargeScreen
                      ? "28px"
                      : isBet1024and1080
                      ? "24px"
                      : "32px",
                  }}
                />
                <p className="ratings__no m-0 gilroy">
                  ({productDetailsData.totalReview})
                </p>
              </div>
              <div className="product_details__overall_mrp ">
                <p className="details__overall_mrp m-0 gilroy">
                  &#8377; {productDetailsData.finalPrice}
                </p>
              </div>

              <div className="product_details__mrp d-flex flex-column">
                <p className="details__price_for_one gilroy m-0">
                  Price For: 1 Piece
                </p>
                <p className="details__mrp gilroy m-0">
                  MRP:{" "}
                  <span className="text-decoration-line-through">
                    &#8377; {productDetailsData.mrp}
                  </span>
                </p>
                <p className="details__saved_money gilroy m-0">
                  You save &#8377;{" "}
                  {productDetailsData.mrp - productDetailsData.finalPrice} in
                  this product
                </p>
              </div>
              <div className="product_details__available_stock">
                <p className="details__available_stock gilroy">
                  Available Stock: {productDetailsData.stock}
                </p>
              </div>

              <div className=" product_details__buy_cart_btn d-flex flex-column gap-3 ">
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    padding: "10px auto",
                    backgroundColor: "#1365AF",
                  }}
                  className="gilroy"
                  fullWidth
                  onClick={() =>
                    handleAddToCartBtn(productDetailsData._id, quantiy)
                  }
                >
                  Add To Cart
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    padding: "10px auto",
                    backgroundColor: "#1365AF",
                  }}
                  className="gilroy"
                  fullWidth
                  onClick={handleBuyNow}
                >
                  Buy Now
                </Button>
              </div>
            </div>
          </section>
          {/* Prouct Descriptions  */}
          <section className="description_box my-5">
            <DescriptionBox
              data={productDetailsData}
              recommended={productRecommended}
            />
          </section>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
