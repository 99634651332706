import React, { useState, useEffect } from "react";
import PDFImage from "../../../../Assets/Images/pdf.png";

const ProductVerify = ({ data }) => {
  const [fileData, setFileData] = useState("");

  useEffect(() => {
    console.log("pdf", data);

    setFileData(data?.name);
  }, [data]);

  const openPDF = (pdfUrl) => {
    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
    }
  };

  return (
    <>
      <div>
        {" "}
        <h3
          style={{ fontSize: "20px", fontWeight: 600, color: "#000000" }}
          className="gilroy mb-5"
        >
          Documents
        </h3>
        <div className="d-flex flex-column">
          <div
            className="file-item d-flex flex-column justify-content-between align-items-center gap-2 p-3 pb-2 border rounded"
            style={{ width: "150px", textAlign: "center" }}
          >
            <img
              src={PDFImage}
              alt="PDF Icon"
              style={{ width: "100px", height: "100px", cursor: "pointer" }}
            />
            <p
              style={{
                cursor: "pointer",
                fontSize: "16px",
                textAlign: "center",
                wordBreak: "break-word",
              }}
              className="gilroy"
            >
              {fileData}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductVerify;
