import React, { useEffect } from "react";
import "./Dashboard.css";
// import Carousel from "../Home/Carousel/Carousel";
// import CarouselPage from "../Home/Carousel/Carousel";
import QuickAction from "./QuickAction/QuickAction";
import RecentOrder from "./RecentOrder/RecentOrder";
import RecentAuctions from "./RecentAuctions/RecentAuctions";

import DashboardCarousel from "./DashboardCarousel/DashboardCarousel";
import AnalyticsSection from "./AnalyticsSection/AnalyticsSection";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    const loginResponse = sessionStorage.getItem("loginCredentials");
    const parsedData = JSON.parse(loginResponse);
    if (!parsedData?.token) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div className="dashboard-box ">
        <DashboardCarousel />
        <div className="dashboard_contents">
          <div className="dasboard-content w-100">
            <QuickAction />
            <RecentOrder />
            <RecentAuctions />
            <hr className="my-5" />
            <AnalyticsSection />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
