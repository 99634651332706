import React, { useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import Filter from "../../../../../Assets/Icons/filter.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme }) => ({
    color: "rgba(0, 70, 139, 1)",
  })
);

const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "rgba(208, 223, 255, 1)",
  borderRadius: "6px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 70, 139, 1)",
    borderWidth: "2px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 70, 139, 1)",
    borderWidth: "2px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 70, 139, 1)",
    borderWidth: "2px",
  },
  "& .MuiSvgIcon-root": {
    color: "rgba(0, 70, 139, 1)",
  },
  "& .MuiSelect-select": {
    padding: "8px", // Adjust padding to reduce height
    fontSize: "0.875rem", // Adjust font size to reduce height
  },
}));

export default function ProductFilter({ onFilter }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    const newValue = selectedOptions === value ? "Default" : value;
    setSelectedOptions(newValue);
    onFilter(newValue);
  };

  return (
    <Box sx={{ minWidth: 120 }} className="me-2">
      <FormControl fullWidth>
        <CustomSelect
          id="demo-simple-select-placeholder-label"
          value={selectedOptions}
          displayEmpty
          onChange={handleChange}
          IconComponent={CustomKeyboardArrowDownIcon}
          renderValue={() => (
            <p
              style={{ color: "rgba(0, 70, 139, 1)" }}
              className="fw-bold d-flex align-items-center gilroy  m-0"
            >
              {/* <FilterListRoundedIcon fontSize="small" />  */}
              <img
                src={Filter}
                alt="filter"
                style={{ width: 21, height: 20 }}
              />
              <span>&nbsp; Filter</span>
            </p>
          )}
        >
          <MenuItem value="" disabled>
            <span
              style={{ color: "rgba(0, 70, 139, 1)" }}
              className="fw-bold gilroy"
            >
              Filter
            </span>
          </MenuItem>
          {["Low To High", "High To Low"].map((name) => (
            <MenuItem
              key={name}
              value={name}
              onClick={() => handleChange({ target: { value: name } })}
            >
              <Checkbox checked={selectedOptions === name} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </Box>
  );
}
