import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const ManageOffices = ({ onAddNewOffice }) => {
  const [addressData, setAddressData] = useState([]);

  // Screen Size for responsiveness ------------
  const isLargeScreen = useMediaQuery("(max-width:992px)");
  const isMediumScreen = useMediaQuery("(max-width: 768px) ");
  const isVerySmallScreen = useMediaQuery("(max-width: 435px)");

  useEffect(() => {
    const locationResponse = localStorage.getItem("addNewOfficeData");
    const parsedLocation = JSON.parse(locationResponse) || [];
    setAddressData(parsedLocation);
  }, []);

  const handleDelete = (index) => {
    const newAddressData = addressData.filter((_, i) => i !== index);
    // Update localStorage
    localStorage.setItem("addNewOfficeData", JSON.stringify(newAddressData));

    setAddressData(newAddressData);
  };

  const handleEdit = (index) => {
    onAddNewOffice(index);
  };

  const renderMessage = () => {
    const rowCount = addressData.length;
    if (rowCount === 0) {
      return (
        <TableCell
          style={{ textTransform: "none" }}
          colSpan={isMediumScreen ? 2 : 3}
          className="gilroy"
        >
          No Address Details Found. Add Address Below.
        </TableCell>
      );
    } else {
      return (
        <TableCell
          style={{ textTransform: "none" }}
          colSpan={isMediumScreen ? 2 : 3}
          className="gilroy-regular"
        >
          {rowCount} of {rowCount} Office Location{rowCount > 1 ? "s" : ""}
          &nbsp;Displayed
        </TableCell>
      );
    }
  };

  return (
    <>
      <div className="manage_offices__container w-sm-75 ">
        <p
          style={{
            fontSize: isVerySmallScreen ? "14px" : "16px",
            textTransform: "none",
          }}
          className="text-sm-center gilroy-regular"
        >
          You need to update your GSTIN for getting orders above 40 lakhs
        </p>
        <div className="office_location__table mt-4">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      backgroundColor: "#9ABAFA",
                      verticalAlign: "top",
                      color: "#ffffff",
                    }}
                    className="gilroy"
                    align="center"
                  >
                    Office
                  </TableCell>
                  <TableCell
                    style={{
                      width: "22rem",
                      fontSize: "16px",
                      fontWeight: 500,
                      backgroundColor: "#9ABAFA",
                      verticalAlign: "top",
                      color: "#ffffff",
                    }}
                    className="gilroy"
                    align="center"
                  >
                    Address
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      backgroundColor: "#9ABAFA",
                      verticalAlign: "top",
                      color: "#ffffff",
                    }}
                    className="gilroy"
                    align="center"
                  >
                    GSTIN
                  </TableCell>
                  <TableCell
                    style={{
                      width: isMediumScreen ? 100 : 160,
                      fontSize: "16px",
                      fontWeight: 500,
                      backgroundColor: "#9ABAFA",
                      verticalAlign: "top",
                      color: "#ffffff",
                    }}
                    className="gilroy"
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addressData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ padding: "6px 5px", verticalAlign: "top" }}
                      className="gilroy border-end"
                    >
                      {row.officeName}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "6px 5px",
                        width: "22rem",
                        verticalAlign: "top",
                      }}
                      className="gilroy border-end"
                    >
                      {row.flatBlockNo} {row.buildingVillage}, {row.street},{" "}
                      {row.area}, {row.townCityDistrict}, {row.state},{" "}
                      {row.pincode}
                    </TableCell>
                    <TableCell
                      style={{ padding: "6px 5px", verticalAlign: "top" }}
                      className="gilroy border-end"
                    >
                      {row.gstIn}
                    </TableCell>
                    <TableCell
                      style={{ padding: "6px 10px", verticalAlign: "top" }}
                      className="gilroy"
                    >
                      <div className="d-flex flex-column flex-md-row gap-1">
                        <Button
                          style={{
                            textTransform: "none",
                            marginRight: isMediumScreen ? "0" : "5px",
                            padding: "3px 5px",
                            border: "0.5px solid #1365AF",
                          }}
                          onClick={() => handleDelete(index)}
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={() => handleEdit(index)}
                          style={{
                            textTransform: "none",
                            marginLeft: isMediumScreen ? "0" : "5px",
                            padding: "3px 5px",
                            border: "0.5px solid #1365AF",
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  {renderMessage()}
                  <TableCell
                    colSpan={isMediumScreen ? 2 : 1}
                    className="gilroy"
                  >
                    <Button
                      varient="contained"
                      style={{
                        textTransform: "none",
                        backgroundColor: "#fa9b00",
                        color: "#ffffff",
                        padding: "3px 8px",
                      }}
                      onClick={onAddNewOffice}
                    >
                      Add New Office
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default ManageOffices;
