import React, { useState, useEffect } from "react";
import "./ServiceCatelogue.css";
import { SearchRounded, ArrowBackRounded } from "@mui/icons-material";
import { Box, Button, useMediaQuery, Link } from "@mui/material";
import Categories from "./Categories/Categories";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";
import CategoriesButtons from "./CategoriesButtons/CategoriesButtons";

import {
  custServiceListAPI,
  custSubCategoriesAPI,
} from "../../../Config/apiConfig";

import axios from "axios";
import ServiceSort from "./ServiceSort/ServiceSort";
import { useLocation, useNavigate } from "react-router-dom";

const ServiceCatelogue = () => {
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [cateId, setCateId] = useState("");
  const [custSortSerData, setCustSortSerData] = useState([]);
  const [serviceCount, setServiceCount] = useState("");
  const [cateName, setCateName] = useState("");
  const [sorting, setSorting] = useState("");
  const [unFilterTitle, setUnFilterTitle] = useState([]);
  const [filteredSubCateTitle, setFilteredSubCateTitle] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { subCateActiveId, subCateActiveName } = location?.state || {};

  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery("(max-width:320px )");

  const handleServiceSort = (data) => {
    setSorting(data);
  };

  const handleCategoryId = (id) => {
    setCateId(id);
  };

  const handleCateName = (data) => {
    if (data) {
      setCateName(data);
    }
  };

  const handleSearch = (event) => {
    setSearchSubCategory(event.target.value);
  };
  const handleSubCateTitle = (data) => {
    setUnFilterTitle(data);
  };

  useEffect(() => {
    if (searchSubCategory.trim() === "") {
      setFilteredSubCateTitle(unFilterTitle);
    }
  }, [searchSubCategory, unFilterTitle]);

  const handleSubCateTitleFilter = () => {
    if (searchSubCategory.trim() !== "") {
      const filtered = unFilterTitle.filter((item) =>
        item.name.toLowerCase().includes(searchSubCategory.toLowerCase())
      );
      setFilteredSubCateTitle(filtered);
    }
  };

  useEffect(() => {
    const fetchServiceSortAPI = async () => {
      if (!cateId) return;
      try {
        const response = await axios.get(custServiceListAPI + cateId);

        if (response?.data && response.status === 200) {
          setServiceCount(response?.data.data.length);
          setCustSortSerData(response?.data?.data);
        }
      } catch (error) {
        console.log("All Services Error", error);
      }
    };
    fetchServiceSortAPI();
  }, [cateId]);

  useEffect(() => {
    if (filteredSubCateTitle.length === 0) {
      setServiceCount(0);
      setCustSortSerData([]);
      setCateName("");
    }
  }, [filteredSubCateTitle]);

  return (
    <>
      <div id="cust_sub_categories__container">
        <div className="d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-1 fw-bold"
            onClick={() => {
              navigate("/");
            }}
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2.5em" }}
              className="heading fw-bold gilroy"
            >
              {subCateActiveName}
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              /{" "}
              <Link
                underline="none"
                to={"/service-categories"}
                style={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Categories{" "}
              </Link>{" "}
              / {subCateActiveName}
            </p>
          </div>
        </div>
        <div className="search__bar_container d-flex mt-5 pt-3">
          <div className="search_bar search-bar border border-2 border-end-0 px-1 d-flex align-items-center justify-content-center w-100">
            <SearchRounded
              sx={{
                color: "rgba(106, 125, 159, 1)",
                width: isVerySmallScreen ? "16px" : "25px",
                height: isVerySmallScreen ? "16px" : "25px",
              }}
            />
            <Box
              sx={{
                m: 0,
                width: "100%",
                height: isVerySmallScreen ? "30px" : "auto",
              }}
            >
              <input
                className="search-bar__input w-100 fs-6 fw-medium border-0 bg-transparent"
                type="text"
                id="searchForService"
                placeholder="Search For Service"
                value={searchSubCategory}
                onChange={handleSearch}
              />
            </Box>
          </div>
          <Button
            variant="contained"
            sx={{
              padding: "auto 25px",
              borderLeft: "none",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
            onClick={handleSubCateTitleFilter}
            className="gilroy"
          >
            Search
          </Button>
        </div>
        <section>
          <div className="d-flex flex-column flex-md-row justify-content-start  gap-4 mt-5">
            <div className="">
              <CategoriesButtons
                subCateBtn={subCateActiveId}
                catID={handleCategoryId}
                catName={handleCateName}
                onSubCateTitle={handleSubCateTitle}
                filteredTitle={filteredSubCateTitle}
              />
            </div>
            <VirticalStyleLine />
            <div className="categories_container w-100">
              <h4 className="categories_heading gilroy">
                Showing All <strong>({serviceCount || 0})</strong>
                <span>{cateName ? cateName : ""}</span>
              </h4>
              <div
                className={`d-flex ${
                  isExtraSmallScreen ? "flex-column" : "flex-row"
                }  gap-2 gap-sm-inherit justify-content-between mt-4`}
              >
                <ServiceSort onCheck={handleServiceSort} />
              </div>
              <Categories custSortSerData={custSortSerData} sorting={sorting} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ServiceCatelogue;
