import React from "react";
import { Button, useMediaQuery } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

function ProfileReviewsButtons({ onViewChange, currentView }) {
  const isVerySmallScreen = useMediaQuery(" (max-width: 420px) ");

  return (
    <>
      <div
        className={`profile-button--reviews-sent-button d-flex  justify-content-center mt-5 mb-4 mx-sm-5 px-sm-5`}
      >
        <Button
          fullWidth
          style={{
            width: isVerySmallScreen ? "100%" : "22rem",
            backgroundColor:
              currentView === "profile"
                ? "rgba(214, 236, 255, 1)"
                : "transparent",
            "&:hover": {
              backgroundColor: "rgba(214,236,255,1)",
            },
            color: "rgba(0, 30, 47, 1)",
            fontSize: isVerySmallScreen ? "14px" : "16px",
          }}
          className="profile-button border border-end-0 rounded-end rounded-pill border-secondary text-capitalize gilroy"
          onClick={() => onViewChange("profile")}
        >
          {currentView === "profile" ? (
            <DoneRoundedIcon fontSize="small" sx={{ fontSize: "20px" }} />
          ) : (
            ""
          )}
          Profile
        </Button>
        <Button
          fullWidth
          style={{
            width: isVerySmallScreen ? "100%" : "22rem",
            backgroundColor:
              currentView === "reviews"
                ? "rgba(214, 236, 255, 1)"
                : "transparent",
            "&:hover": {
              backgroundColor: "rgba(214,236,255,1)",
            },
            color: "rgba(0, 30, 47, 1)",
            fontSize: isVerySmallScreen ? "14px" : "16px",
          }}
          className="reviews-sent-button text-capitalize border rounded-start rounded-pill border-secondary gilroy"
          onClick={() => onViewChange("reviews")}
        >
          {currentView === "reviews" ? (
            <DoneRoundedIcon fontSize="small" sx={{ fontSize: "20px" }} />
          ) : (
            ""
          )}
          Reviews
        </Button>
      </div>
    </>
  );
}

export default ProfileReviewsButtons;
