import React, { useEffect, useState } from "react";
import "./ContactInformation.css";

import {
  Button,
  useMediaQuery,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Pencil from "../../../../../Assets/Icons/pencil.png";
import { sellerContactEditAPI } from "../../../../../Config/apiConfig";
import axios from "axios";

const ContactInformation = ({ addressData, businessID, profId }) => {
  // console.log("address aya kya", addressData[0]);
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const [address, setAddress] = useState([]);

  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [townCityDistrict, setTownCityDistrict] = useState("");
  const [flatBlockNo, setFlatBlockNo] = useState("");
  const [buildingVillage, setBuildingVillage] = useState("");
  const [street, setStreet] = useState("");
  const [area, setArea] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setAddress(addressData[0]);
    // console.log("office id", addressData[0]);
    if (addressData) {
      setPincode(address?.pincode || "");
      setState(address?.state || "");
      setTownCityDistrict(address?.townCityDistrict || "");
      setFlatBlockNo(address?.flatBlockNo || "");
      setBuildingVillage(address?.buildingVillage || "");
      setStreet(address?.street || "");
      setArea(address?.area || "");
      setContactNo(address?.contactNo || "");
      setEmail(address?.email || "");
    }
  }, [addressData]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formData = {
    bpId: businessID,
    id: address?._id,
    pincode,
    state,
    townCityDistrict,
    flatBlockNo,
    buildingVillage,
    street,
    area,
    contactNo,
    email,
  };

  const handleSave = async () => {
    const loginResponse = sessionStorage.getItem("loginCredentials");
    const parsedData = JSON.parse(loginResponse);
    if (parsedData) {
      try {
        const response = await axios.put(sellerContactEditAPI, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response?.data && response.status === 200) {
          handleClose();
          window.location.reload();
        }
      } catch (error) {
        console.log("Error updating social links", error);
      }
    }
  };

  return (
    <>
      <div className="">
        <div className="account_contact d-flex px-0 justify-content-between gap-2 align-items-center mt-5">
          <h4 className="account_contact_label gilroy text-black">
            Contact Information
          </h4>
          <Button
            onClick={handleOpen}
            style={{
              backgroundColor: "rgb(238,238,244)",
              fontSize: isSmallScreen ? "14px" : "16px",
              width: isSmallScreen ? "20px" : "80px",
              height: "40px",
            }}
            className="rounded-pill text-capitalize gilroy"
          >
            <img src={Pencil} alt="edit pen" className="edit_pen_size" />
            <span className="d-none d-sm-inline">&nbsp;Edit</span>
          </Button>
        </div>
        <div className="contact-info-container gilroy mt-3 p-4">
          <div
            style={{ marginTop: "1rem" }}
            className="d-flex flex-column gap-2 gap-sm-3 gap-lg-4"
          >
            <div className="address account_info_box">
              <p className="account_info_head gilroy p-0 my-2">Address</p>

              <p className="contact-info account_info_text gilroy m-0 p-0 fw-bold">
                {address?.flatBlockNo} {address?.buildingVillage},{" "}
                {address?.street}, {address?.area}, {address?.townCityDistrict},{" "}
                {address?.state}, {address?.pincode}
              </p>
            </div>
            <div className="mobile-number account_info_box">
              <p className="contact-headings account_info_head gilroy p-0 my-2">
                Mobile No.
              </p>

              <p className="contact-info account_info_text gilroy m-0 p-0 fw-bold">
                {address?.contactNo}
              </p>
            </div>
            <div className="email-address account_info_box">
              <p className="contact-headings account_info_head gilroy p-0 my-2">
                Business Email ID
              </p>
              <p className="contact-info account_info_text m-0 p-0 fw-bold text-lowercase">
                {address?.email}
              </p>
            </div>
          </div>
        </div>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogContent>
            <div className="w-100 d-flex justify-content-between gap-3">
              <TextField
                fullWidth
                size="small"
                margin="dense"
                label="Flat/Block No."
                value={flatBlockNo}
                onChange={(e) => setFlatBlockNo(e.target.value)}
              />
              <TextField
                fullWidth
                size="small"
                margin="dense"
                label="Street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>
            <div className="w-100 d-flex justify-content-between gap-3">
              <TextField
                fullWidth
                size="small"
                margin="dense"
                label="Town/City/District"
                value={townCityDistrict}
                onChange={(e) => setTownCityDistrict(e.target.value)}
              />
              <TextField
                fullWidth
                size="small"
                margin="dense"
                label="Pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </div>
            <div className="w-100 d-flex justify-content-between gap-3">
              <TextField
                fullWidth
                size="small"
                margin="dense"
                label="Building/Village"
                value={buildingVillage}
                onChange={(e) => setBuildingVillage(e.target.value)}
              />

              <TextField
                fullWidth
                size="small"
                margin="dense"
                label="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="w-100 d-flex justify-content-between gap-3">
              <TextField
                fullWidth
                size="small"
                margin="dense"
                label="Area"
                value={area}
                onChange={(e) => setArea(e.target.value)}
              />
              <TextField
                fullWidth
                size="small"
                margin="dense"
                label="Contact No."
                value={contactNo}
                onChange={(e) => setContactNo(e.target.value)}
              />
            </div>

            <TextField
              type="email"
              fullWidth
              size="small"
              margin="dense"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ContactInformation;
