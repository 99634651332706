import React, { useEffect, useState } from "react";
import "./BuyersProAndSer.css";
import { useMediaQuery, Link, Button } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import CustProduct from "./CustProduct/CustProduct";
import CustService from "./CustService/CustService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BuyersProAndSer = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("products");

  // Screen Size for responsiveness
  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery("max-width:320px ");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Get Service Id from Redux
  const popularServiceCateId = useSelector(
    (state) => state.popularCateId.popularServiceCateId
  );

  const serviceSubCateIdItem = useSelector(
    (state) => state.serviceSubCateId.serviceSubCateIdItem
  );

  useEffect(() => {
    if (popularServiceCateId || serviceSubCateIdItem) {
      setActiveTab("services");
    }
  }, [popularServiceCateId]);

  return (
    <>
      <div className="buyers-products_and_service__container">
        <div className="d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-1 fw-bold"
            onClick={() => {
              navigate("/");
            }}
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2.5em" }}
              className="heading fw-bold gilroy"
            >
              Products
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}>Products</span>
            </p>
          </div>
        </div>

        <div>
          <CustProduct />
        </div>
      </div>
    </>
  );
};

export default BuyersProAndSer;
