import React, { useState } from "react";

import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import useResponsiveStyles from "./useResponsiveStyles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddNewAccount = ({ onSave }) => {
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [holderName, setHolderName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [isPrimary, setIsPrimary] = useState("");

  // handling states ----------------
  const handleIfscCode = (event) => {
    setIfscCode(event.target.value);
  };
  const handleBankName = (event) => {
    setBankName(event.target.value);
  };
  const handleBankAddress = (event) => {
    setBankAddress(event.target.value);
  };
  const handleHolderName = (event) => {
    setHolderName(event.target.value);
  };
  const handleAccountNo = (event) => {
    setAccountNo(event.target.value);
  };
  const handleIsPrimary = (event) => {
    setIsPrimary(event.target.checked ? "Yes" : "No");
  };

  const handleSave = () => {
    if (
      (ifscCode !== "" || bankName !== "",
      bankAddress !== "" || holderName !== "",
      accountNo !== "")
    ) {
      const formData = {
        ifscCode: ifscCode,
        name: bankName,
        address: bankAddress,
        holderName: holderName,
        accountNo: accountNo,
        isPrimary: isPrimary,
      };

      // Save formData to localStorage under the key 'officelocation'
      const existingKeywords = localStorage.getItem("bankDetails");
      let keywords =
        existingKeywords !== undefined ? JSON.parse(existingKeywords) : [];

      if (!Array.isArray(keywords)) {
        keywords = [];
      }

      if (!keywords.includes(formData)) {
        keywords.push(formData);
      }

      localStorage.setItem("bankDetails", JSON.stringify(keywords));

      setIfscCode("");
      setBankName("");
      setBankAddress("");
      setHolderName("");
      setAccountNo("");
      setIsPrimary("");
    } else {
      toast.warn("Please provide your bank details.");
    }
  };

  // Use responsive styles
  const {
    labelStyle,
    labelClassStyle,
    sectionStyle,
    isSmallScreen,
    isBetween768And883,
  } = useResponsiveStyles();

  return (
    <>
      <div className="add_new_account__container w-100">
        <div className={`ifsc_code--bank_name ${sectionStyle}`}>
          <div className="ifsc_code w-100">
            <p style={labelStyle} className={labelClassStyle}>
              IFSC Code*
            </p>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter IFSC Code"
              value={ifscCode}
              onChange={handleIfscCode}
            />
          </div>
          <div className="bank_name w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Bank Name*
            </p>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter Your Bank Name"
              value={bankName}
              onChange={handleBankName}
            />
          </div>
        </div>
        <div className={`bank_address--bank_holder_name ${sectionStyle} mt-5`}>
          <div className="bank_address w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Bank Address*
            </p>
            <TextField
              fullWidth
              size="small"
              className="form-control"
              placeholder="Enter Bank Address"
              value={bankAddress}
              onChange={handleBankAddress}
            ></TextField>
          </div>
          <div className="bank_holder_name w-100 mb-1">
            <p style={labelStyle} className={labelClassStyle}>
              Bank Holder Name*
            </p>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter Bank Holder Name"
              value={holderName}
              onChange={handleHolderName}
            />
          </div>
        </div>
        <div
          className={`bank_account_no--confirm_account ${sectionStyle} mt-5`}
        >
          <div className="bank_account_no w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Bank Account Number*
            </p>
            <TextField
              fullWidth
              size="small"
              style={{
                width: isSmallScreen
                  ? "100%"
                  : isBetween768And883
                  ? "100%"
                  : "46.4%",
              }}
              placeholder="Enter Account Number"
              value={accountNo}
              onChange={handleAccountNo}
            />
          </div>
        </div>
        <div className="is_primary--save_button mt-4">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={isPrimary === "Yes"}
                  onChange={handleIsPrimary}
                />
              }
              label={
                <p style={labelStyle} className=" m-0 gilroy">
                  Primary Account
                </p>
              }
            />
          </FormGroup>
        </div>
        <div className="mt-4 text-center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#fa9b00", color: "#ffffff" }}
            onClick={handleSave}
            className="gilroy"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddNewAccount;
