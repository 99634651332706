import React, { useEffect, useState } from "react";
import "./PickService.css";
import { TextField, MenuItem, Select, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCheck } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { custBidServicesAPI } from "../../../Config/apiConfig";
import axios from "axios";

const PickService = ({ data, details, onSubCateID, onSubCateName }) => {
  const [categoryName, setCategoryName] = useState("");
  const [childCategoryId, setChildCategoryId] = useState("");
  const [childCategoryName, setChildCategoryName] = useState("");
  const [cateDetails, setCateDetails] = useState([]);

  useEffect(() => {
    if (data && details) {
      setCategoryName(data);
      setCateDetails(details);
    }
  }, [data, details]);

  const handleChildCategory = (id, name) => {
    if (id && name) {
      const subcategoryId = id;
      setChildCategoryId(subcategoryId);
    }

    onSubCateID(id);
    onSubCateName(name);
  };

  return (
    <>
      <div className="search_pick__service mt-4 mb-5  w-100">
        <div className="d-flex align-items-center border px-3 m-5 mt-0 ">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ color: "#6a7d9f", fontSize: "20px" }}
          />
          <TextField
            sx={{
              border: "none",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            fullWidth
            size="small"
          />
        </div>
        <div className="w-100">
          <h3 className="heading_service gilroy my-3">{categoryName} </h3>
          <Row>
            {Array.isArray(cateDetails) &&
              cateDetails.map((item) => (
                <Col
                  key={item._id}
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  lg={6}
                  className="p-1 d-flex"
                  onClick={() => handleChildCategory(item._id, item.name)}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor:
                        childCategoryId === item._id ? "#d3d3d8" : "#EEEEF4",
                      "&:hover": {
                        backgroundColor: "#d3d3d8",
                      },
                      color: "#172B4D",
                      padding: "15px 20px",
                      fontSize: "20px",
                      lineHeight: "23.54px",
                      fontWeight: 500,
                      margin: "10px ",
                    }}
                    className="gilroy"
                  >
                    {item.name}
                    {childCategoryId === item._id && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginLeft: "10px", color: "#172B4D" }}
                      />
                    )}
                  </Button>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default PickService;
