import React, { useEffect, useState } from "react";
import "./Categories.css";
import { IconButton, Rating, useMediaQuery } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPopularProductCateId } from "../../../../redux/productCateIdSlice";
import NotLike from "../../../../Assets/Icons/oulineHeart.png";
import Like from "../../../../Assets/Icons/redHeart.png";
import { custAddFavouriteAPI } from "../../../../Config/apiConfig";
import axios from "axios";

const Categories = ({ custProductData, filter }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLargeScreen = useMediaQuery("(max-width: 1024px)");

  // useStates
  const [currentPage, setCurrentPage] = useState(0);
  const [favorites, setFavorites] = useState({});
  const [checkedWishList, setCheckedWishList] = useState([]);

  const itemsPerPage = 4;

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const handleCustProductId = (id) => {
    navigate("/buyer-product-details", { state: { prodID: id } });
  };

  const toggleFavorite = async (productId) => {
    const response = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(response);

    if (parsedData?.token) {
      try {
        const response = await axios.get(custAddFavouriteAPI + productId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });

        if (response?.data && response.status === 200) {
          setFavorites((prev) => ({
            ...prev,
            [productId]: !prev[productId],
          }));
        }
      } catch (error) {
        console.error("Error in adding to favorites:", error);
      }
    }
  };

  useEffect(() => {
    const fetchWishlistAPI = async () => {
      const response = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(response);

      if (parsedData?.token) {
        try {
          const response = await axios.get(custAddFavouriteAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });

          if (response?.data && response.status === 200) {
            setCheckedWishList(response.data.data);
          }
        } catch (error) {
          console.error("Error in fetching wishlist:", error);
        }
      }
    };
    fetchWishlistAPI();
  }, []);

  // Update favorites state when checkedWishList changes

  useEffect(() => {
    const favoriteMap = {};
    checkedWishList.forEach((item) => {
      favoriteMap[item._id] = true; // if checklist has ids than true
    });
    setFavorites(favoriteMap);
  }, [checkedWishList]);

  const isFavorite = (porductId) => favorites[porductId] || false; // if there the

  const sortedProducts =
    filter === "High To Low"
      ? [...custProductData].sort((a, b) => b.finalPrice - a.finalPrice) // Descending order
      : filter === "Low To High"
      ? [...custProductData].sort((a, b) => a.finalPrice - b.finalPrice) // Ascending order
      : filter === "Newest"
      ? [...custProductData].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      : filter === "Oldest"
      ? [...custProductData].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      : [...custProductData]; // Default order

  const offset = currentPage * itemsPerPage;
  const currentProducts = sortedProducts.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div className="cust_products_categories_results__container">
        <section className="product_card_section mt-4 w-100">
          <Row className="product_card__container p-0">
            {currentProducts.length > 0 ? (
              currentProducts?.map((product) => (
                <Col
                  key={product._id}
                  xs={12}
                  sm={6}
                  md={6}
                  className="my-3"
                  onClick={() => handleCustProductId(product._id)}
                >
                  <div className="product_card__content border rounded p-3">
                    <div className="product_img_section position-relative">
                      <IconButton
                        className="favorite-icon"
                        sx={{ position: "absolute", top: "2%", right: "2%" }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents navigation on click
                          toggleFavorite(product._id);
                        }}
                      >
                        {isFavorite(product._id) ? (
                          <img
                            src={Like}
                            alt="liked"
                            style={{ width: "18px" }}
                          />
                        ) : (
                          <img
                            src={NotLike}
                            alt="not liked"
                            style={{ width: "18px" }}
                          />
                        )}
                      </IconButton>
                      <img
                        src={product.image}
                        alt={product.title}
                        className="product__img"
                      />
                    </div>
                    <div className="product_info_section mt-2">
                      <p className="product__title gilroy-regular">
                        {product.title}
                      </p>
                      <p className="product__quantity m-0 gilroy">
                        Min. Qty: {product.minQty}
                      </p>
                      <div className="ratings mt-2 d-flex gap-2 align-items-center">
                        <Rating
                          sx={{
                            fontSize: isLargeScreen ? "18px" : "20px",
                            marginTop: 0.5,
                          }}
                          name="half-rating-read"
                          readOnly
                          defaultValue={product.star || 0}
                          precision={0.5}
                        />
                        <p className="ratings__viewers gilroy m-0 pt-1">
                          ({product.totalReview})
                        </p>
                      </div>
                      <div className="product__price_content d-flex gap-2 align-items-center mt-3">
                        <span className="orignal__prod_price text-decoration-line-through">
                          {formatCurrency(product.mrp)}
                        </span>
                        <span className="disconted__prod_price">
                          {formatCurrency(product.finalPrice)}
                        </span>
                      </div>
                    </div>
                    <div className="delivery d-flex gap-2 align-items-center mt-2">
                      <img
                        src="icons/delivery-car.png"
                        alt="delivery_icon"
                        className="delivery_icon m-0"
                      />
                      <p className="delivery_text gilroy m-0">
                        Delivery in 4 to 5 days.
                      </p>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <p className="text-center mt-4">
                No data available at the moment
              </p>
            )}
          </Row>

          {/* Pagination */}
          <ReactPaginate
            previousLabel={
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ color: "#001b3d", fontSize: "16px" }}
              />
            }
            nextLabel={
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ color: "#001b3d", fontSize: "16px" }}
              />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(custProductData.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            pageClassName="pt-1"
            previousClassName="pt-1"
            nextClassName="pt-1"
          />
        </section>
      </div>
    </>
  );
};

export default Categories;
