import React, { useState, useEffect } from "react";
import "./BidCategoriesBtn.css";
import { Button, useMediaQuery } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseChimney,
  // faBriefcase,
  // faGraduationCap,
  // faFile,
  // faBuilding,
  // faStarOfLife,
  // faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { custBidCateListAPI } from "../../../Config/apiConfig";
import axios from "axios";

const BidCategoriesBtn = ({ catID, catName }) => {
  const [currentBtn, setCurrentBtn] = useState(null);
  const [categoriesTitle, setCategoriesTitle] = useState("");

  const isLargeScreen = useMediaQuery("(max-width:1024px)");
  const isMedLarScreen = useMediaQuery("(max-width:870px)");

  const style =
    "service__mini--categories__buttons justify-content-start border gap-3 rounded w-100 gilroy";

  // Style for the active button
  const activeButtonStyle = {
    width: isMedLarScreen
      ? "12.6rem"
      : isLargeScreen
      ? "15.6rem"
      : window.innerWidth <= 576
      ? "16rem"
      : "16.6em",
    // maxWidth: "16rem !important",
    minWidth: isMedLarScreen
      ? "12.6rem"
      : isLargeScreen
      ? "15.6rem"
      : window.innerWidth <= 576
      ? "16rem"
      : "16.6em",
    fontSize: isMedLarScreen ? "16px" : "18px",
    fontWeight: 500,
    color: "#42474E",
    justifyContent: "start",
    backgroundColor: "#D6ECFF",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    textAlign: "start",
    "&:hover": {
      backgroundColor: "#D6ECFF",
    },
    border: "1px solid #1365AF",
  };

  // Style for the inactive button
  const inactiveButtonStyle = {
    width: isMedLarScreen ? "12.6rem" : isLargeScreen ? "15.6rem" : "16.6em",
    minWidth: "12rem !important",
    fontSize: isMedLarScreen ? "16px" : "18px",
    fontWeight: 500,
    color: "#42474E",
    justifyContent: "start",
    backgroundColor: "#FAF9FD",
    border: "1px solid #C2C7CF",
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Ensure text stays within the button
    textOverflow: "ellipsis",
    display: "inline-block",
    textAlign: "start",
  };

  useEffect(() => {
    const fetchCustBidCateAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData.token) {
        try {
          const response = await axios.get(custBidCateListAPI, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response.data && response.status === 200) {
            setCategoriesTitle(response.data.data);

            catID(response?.data?.data[0]?._id);
            catName(response?.data?.data[0]?.name);
            setCurrentBtn(1);
          }
        } catch (error) {}
      }
    };
    fetchCustBidCateAPI();
  }, []);

  // Handle button click and log _id
  const handleButtonClick = (id, index, name) => {
    const cateId = id;
    const cateName = name;
    catID(cateId);
    catName(cateName);
    setCurrentBtn(index + 1);
  };

  return (
    <>
      <h3 className="service--categories__minicateg">Categories</h3>
      <div className="service__mini--categories__container d-flex flex-row flex-md-column w-100 overflow-scroll no-scrollbar  gap-4 mt-4">
        {categoriesTitle &&
          categoriesTitle.map((item, index) => (
            <Button
              key={item._id}
              className="gilroy text-left px-3 py-2"
              sx={
                currentBtn === index + 1
                  ? activeButtonStyle
                  : inactiveButtonStyle
              }
              onClick={() => handleButtonClick(item._id, index, item.name)}
            >
              <img src={item.path} alt={item.name} style={{ width: "20px" }} />
              &nbsp;&nbsp;
              {item.name}
            </Button>
          ))}
      </div>
    </>
  );
};

export default BidCategoriesBtn;
