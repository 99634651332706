import React, { useState, useEffect } from "react";
import "./AccountInfo.css";
import {
  Favorite,
  ArrowForwardIosRounded,
  EditCalendarRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Heart from "../../../Assets/Icons/heart.png";
import Create from "../../../Assets/Icons/create.png";
import Wallet from "../../../Assets/Icons/wallet.png";
import Setting from "../../../Assets/Icons/settings.png";
import Location from "../../../Assets/Icons/location.png";
import { custAddressEditAPI } from "../../../Config/apiConfig";
import { toast } from "react-toastify";
import axios from "axios";

const AccountInfo = () => {
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const navigate = useNavigate();
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [openAddressDialog, setOpenAddressDialog] = useState(false);

  const handleLogoutBtn = () => {
    localStorage.removeItem("custLoginCredentials");
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1500);
  };

  const handleOpenAddressDialog = () => {
    setOpenAddressDialog(true);
  };

  const handleCloseAddressDialog = () => {
    setOpenAddressDialog(false);
  };

  const handleSaveAddress = async () => {
    // console.log("Address Saved:", address);
    const custLoginResponse = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(custLoginResponse);
    if (parsedData?.token) {
      try {
        const formData = {
          city,
          state,
          pincode,
          landmark,
        };
        const response = await axios.post(custAddressEditAPI, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response?.data && response.status === 200) {
          // console.log("address", response?.data);
          toast.success(response?.data.msg);
          setOpenAddressDialog(false);
        }
      } catch (error) {
        console.log("Error in Address Update", error.response.data);
      }
    }
  };

  return (
    <div>
      <div className="mt-4">
        <Box
          style={{ backgroundColor: "#F4F3F7" }}
          className="d-flex justify-content-between align-items-center p-3"
        >
          <div className="d-flex gap-2 align-items-center ps-2">
            <img
              src={Heart}
              alt="My Favorites"
              style={{ width: "24px", height: "24px" }}
            />
            <p className="account_contents_items m-0">My Favorites</p>
          </div>
          <div>
            <IconButton
              onClick={() => {
                navigate("/cust-wish-list");
              }}
            >
              <ArrowForwardIosRounded
                sx={{ width: "20px", height: "20px", color: "#6A7D9F" }}
              />
            </IconButton>
          </div>
        </Box>
        <Box
          style={{ backgroundColor: "#F4F3F7" }}
          className="d-flex justify-content-between align-items-center p-3 mt-3"
        >
          <div className="d-flex gap-2 align-items-center ps-2">
            <img
              src={Location}
              alt="Address"
              style={{ width: "26px", height: "24px" }}
            />
            <p className="account_contents_items m-0">My Address</p>
          </div>
          <div>
            <IconButton onClick={handleOpenAddressDialog}>
              <ArrowForwardIosRounded
                sx={{ width: "20px", height: "20px", color: "#6A7D9F" }}
              />
            </IconButton>
          </div>
        </Box>
        {/* Dialog Box for Address */}
        <Dialog
          fullWidth
          open={openAddressDialog}
          onClose={handleCloseAddressDialog}
        >
          {/* <DialogTitle>Edit Address</DialogTitle> */}
          <DialogContent>
            <TextField
              fullWidth
              label="Enter your City"
              variant="outlined"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              margin="dense"
            />
            <TextField
              fullWidth
              label="Enter your State"
              variant="outlined"
              value={state}
              onChange={(e) => setState(e.target.value)}
              margin="dense"
            />
            <TextField
              fullWidth
              label="Enter your Pincode"
              variant="outlined"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              margin="dense"
            />
            <TextField
              fullWidth
              label="Enter your Landmark"
              variant="outlined"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddressDialog} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSaveAddress}
              color="primary"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <div className="mt-5">
          <Button
            variant="outlined"
            className="gilroy"
            sx={{
              fontSize: "18px",
              backgroundColor: "#ffcccc",
              border: "2px solid #ff5c5c",
              color: "#000",
              boxShadow: "1px 4px 7px -4px",
              "&:hover": {
                backgroundColor: "#ffaaaa",
                border: "2px solid #ff5c5c",
              },
            }}
            onClick={handleLogoutBtn}
          >
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
