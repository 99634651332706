import React, { useState, useEffect } from "react";
import "./ServiceLists.css";
import { Button, Box, useMediaQuery, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { custServiceCategoryAPI } from "../../Config/apiConfig.js";
import {
  ArrowBackRounded,
  SearchRounded,
  ChevronRightRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function ServiceLists() {
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery("(max-width:768px)");
  const isSmallScreen = useMediaQuery("(max-width: 576px)");

  const [categoryData, setCategoryData] = useState([]);
  const [searchCate, setSearchCate] = useState("");
  const [filteredSearchCate, setFilteredSearchCate] = useState([]);

  const handleSearchCate = (event) => {
    setSearchCate(event.target.value);
  };

  const handleSubCategories = (id, name) => {
    navigate("/sub-categories", {
      state: { subCateActiveId: id, subCateActiveName: name },
    });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(custServiceCategoryAPI);
        if (response.status === 200 && response?.data?.data) {
          setCategoryData(response.data.data);
          setFilteredSearchCate(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchCategoriesBtn = () => {
    if (searchCate.trim() === "") {
      setFilteredSearchCate(categoryData); // Search empty hone par default categories
    } else {
      const filtered = categoryData.filter((item) =>
        item.name.toLowerCase().includes(searchCate.toLowerCase())
      );
      setFilteredSearchCate(filtered);
    }
  };
  // Filter categories based on search input

  return (
    <div className="cust_auction_container mx-0">
      <div className="heading-box d-flex gap-1 gap-sm-3 align-items-start">
        <ArrowBackRounded
          sx={{ fontSize: isSmallScreen ? "24px" : "30px" }}
          className="back-arrow mt-2 fw-bold"
        />
        <div>
          <h1
            style={{ fontSize: isSmallScreen ? "1.6em" : "2em" }}
            className="heading fw-bold gilroy m-0"
          >
            Categories
          </h1>
          <p
            style={{
              fontSize: isSmallScreen ? "14px" : "16px",
              fontWeight: 600,
              color: "#6A7D9F",
            }}
            className="m-0 gilroy"
          >
            <Link to="/" style={{ textDecoration: "none", color: "#6A7D9F" }}>
              Home
            </Link>{" "}
            / Categories
          </p>
        </div>
      </div>

      <div className="sub--categories__container">
        <div className="search__bar_container d-flex justify-content-center w-100 my-5 py-3">
          <div className="search_bar border border-2 border-end-0 px-1 d-flex align-items-center">
            <SearchRounded
              sx={{
                color: "rgba(106, 125, 159, 1)",
                width: isSmallScreen ? "16px" : "25px",
                height: isSmallScreen ? "16px" : "25px",
              }}
            />
            <Box
              sx={{ width: "100%", height: isSmallScreen ? "30px" : "auto" }}
            >
              <input
                className="search-bar__input w-100 fs-6 fw-medium border-0 bg-transparent"
                type="text"
                placeholder="Search For Product"
                value={searchCate}
                onChange={handleSearchCate}
              />
            </Box>
          </div>
          <Button
            variant="contained"
            onClick={handleSearchCategoriesBtn}
            sx={{
              fontSize: isSmallScreen ? "12px" : "18px",
              borderLeft: "none",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
            className="gilroy"
          >
            Search
          </Button>
        </div>

        <section className="w-100 d-flex flex-column gap-2 gap-sm-4 mt-5 pt-5">
          {filteredSearchCate.length > 0 ? (
            filteredSearchCate.map((item) => (
              <div className="w-100 border p-2 p-sm-3 rounded" key={item?._id}>
                <div className="d-flex flex-row gap-3 gap-sm-4 align-items-center">
                  <div className="cust_auction_img_container">
                    <div id="cust_categories__img">
                      <img src={item?.path} alt={item?.name} />
                    </div>
                  </div>
                  <div className="cust_auction_name_container">
                    <h4 className="gilroy m-0">{item.name}</h4>
                  </div>
                  <div className="sub_categories_next__btn_contanier">
                    <IconButton
                      onClick={() => handleSubCategories(item?._id, item?.name)}
                    >
                      <ChevronRightRounded
                        sx={{
                          width: isSmallScreen
                            ? "18px"
                            : isMediumScreen
                            ? "25px"
                            : "35px",
                          height: isSmallScreen
                            ? "18px"
                            : isMediumScreen
                            ? "25px"
                            : "35px",
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              className="text-center gilroy fw-bold"
              style={{ color: "#6A7D9F" }}
            >
              No categories available at the moment
            </p>
          )}
        </section>
      </div>
    </div>
  );
}

export default ServiceLists;
