import React, { useEffect } from "react";
import "./MyOrders.css";
import { useMediaQuery } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MyOrdersPaginate from "./MyOrdersPaginate/MyOrdersPaginate";

import { Link } from "@mui/material";

const MyOrders = () => {
  const navigate = useNavigate();
  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");

  useEffect(() => {
    const custLoginResponse = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(custLoginResponse);
    if (!parsedData?.token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="my_orders_container">
        <div className="d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-1 mt-sm-0 fw-bold"
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2.5em" }}
              className="heading fw-bold gilroy"
            >
              Orders
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}>My Orders</span>
            </p>
          </div>
        </div>

        <div>
          <MyOrdersPaginate />
        </div>
      </div>
    </>
  );
};

export default MyOrders;
