import React, { useRef, useState, useEffect } from "react";
import "./PersonalVerification.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  TextField,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
  useMediaQuery,
} from "@mui/material";

import dayjs from "dayjs";

function PersonalVerification({ onDataChange, onValidate }) {
  const [viewPersonalDetails, setViewPersonalDetails] = useState("d-none");
  const [errors, setErrors] = useState({});
  const [radioError, setRadioError] = useState("");
  const [cardError, setCardError] = useState(false);
  const [cardData, setCardData] = useState("");
  const [formData, setFormData] = useState({
    checkedTerms: false,
    mobileNum: "",
    selectDetails: "",
    dateOfBirth: null,
    nameOnPan: "",
  });

  // const isVerySmallScreen = useMediaQuery("(max-width: 425px) ");
  const isSmallScreen = useMediaQuery("(max-width: 576px) ");

  const cardNumRef = useRef(null);

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "selectDetails") {
      setViewPersonalDetails("d-block");
    }

    if (name === "selectDetails" && value === "PAN") {
      setFormData((prevData) => ({
        ...prevData,
        mobileNum: "",
      }));
    } else if (name === "selectDetails" && value === "Aadhaar") {
      setFormData((prevData) => ({
        ...prevData,
        dateOfBirth: null,
        nameOnPan: "",
      }));
    }
  };

  useEffect(() => {
    if (
      formData.selectDetails === "PAN" &&
      formData.dateOfBirth &&
      formData.nameOnPan &&
      cardData.length === 10
    ) {
      onDataChange({
        selectDetails: formData.selectDetails,
        cardData,
        dateOfBirth: dayjs(formData.dateOfBirth).format("MM/DD/YYYY"),
        nameOnPan: formData.nameOnPan,
      });
    } else if (
      formData.selectDetails === "Aadhaar" &&
      formData.mobileNum.length === 10 &&
      cardData.length === 12
    ) {
      onDataChange({
        selectDetails: formData.selectDetails,
        cardData,
        mobileNum: formData.mobileNum,
      });
    }
  }, [formData, onDataChange, cardData]);

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    console.log(formattedDate);

    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: formattedDate,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      dateOfBirth: "",
    }));
  };

  const aadharPanDataHandle = (event) => {
    const newValue = event.target.value;
    let formattedInput = "";
    if (formData.selectDetails === "Aadhaar") {
      formattedInput = newValue.replace(/\D/g, "").slice(0, 12);
      setCardError(formattedInput.length !== 12);
      setRadioError(
        formattedInput.length !== 12 ? "Aadhaar number must be 12 digits" : ""
      );
    } else if (formData.selectDetails === "PAN") {
      formattedInput = newValue
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 10);
      setCardError(formattedInput.length !== 10);
      setRadioError(
        formattedInput.length !== 10 ? "PAN number must be 10 characters" : ""
      );
    }
    setCardData(formattedInput);
  };

  const handleMobileNumber = (event) => {
    const newValue = event.target.value;
    if (newValue >= 0) {
      const formattedValue = newValue.slice(0, 10);
      handleChange({ target: { name: "mobileNum", value: formattedValue } });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.selectDetails) {
      newErrors.selectDetails = "You must select any one details type";
    }
    if (!formData.checkedTerms) {
      newErrors.checkedTerms = "You must agree to the terms";
    }
    if (
      (formData.selectDetails === "Aadhaar" && cardData.length !== 12) ||
      (formData.selectDetails === "PAN" && cardData.length !== 10)
    ) {
      newErrors.cardData = `Please enter a valid ${
        formData.selectDetails === "Aadhaar" ? "Aadhaar No." : "PAN No."
      } number`;
    }
    if (formData.selectDetails === "PAN") {
      if (!formData.dateOfBirth) {
        newErrors.dateOfBirth = "Date of Birth is required";
      }
      if (!formData.nameOnPan) {
        newErrors.nameOnPan = "Name is required";
      }
    } else if (formData.selectDetails === "Aadhaar") {
      if (!formData.mobileNum || formData.mobileNum.length !== 10) {
        newErrors.mobileNum = "Mobile number must be 10 digits";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  onValidate(validateForm);

  return (
    <>
      <div className="personal_verification__container d-flex flex-column my-0 mx-auto d-md-block justify-content-center ">
        <div className="personal_details_type__section container ms-0 ms-sm-2 my-2 px-2 py-2 ">
          <FormLabel id="radio-buttons-group-label">
            <p
              style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
              className="gilroy fw-bold text-dark"
            >
              Personal Details
            </p>
          </FormLabel>
          <div
            style={{ color: "rgba(119, 119, 122, 1)" }}
            className="personal-details-types ps-2 ps-lg-4"
          >
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              name="selectDetails"
              size="small"
              value={formData.selectDetails}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Aadhaar"
                control={<Radio size="small" />}
                label="Aadhaar number / Virtual ID (linked Mobile number to verify OTP)"
                error={!!errors.selectDetails}
              />
              <FormControlLabel
                value="PAN"
                control={<Radio size="small" />}
                label="Personal PAN"
                error={!!errors.selectDetails}
              />
            </RadioGroup>
          </div>
          <FormHelperText className="text-danger ms-3">
            {errors.selectDetails}
          </FormHelperText>
        </div>

        <div className={`personal__details-box ${viewPersonalDetails} px-2`}>
          <div className="Aadhaar-card-number-container ms-0 ms-sm-2 mt-2 ">
            <div className="d-flex p-0 mb-2">
              <InputLabel htmlFor="idProof">
                <p
                  style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
                  className="my-auto fw-bold gilroy text-dark"
                >
                  {formData.selectDetails === "Aadhaar"
                    ? "Aadhaar Number / Virtual ID"
                    : "PAN Number"}
                </p>
              </InputLabel>
            </div>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { my: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                type="text"
                value={cardData}
                id="idProof"
                fullWidth
                size="small"
                name="cardData"
                onChange={aadharPanDataHandle}
                placeholder={
                  formData.selectDetails === "Aadhaar"
                    ? "Enter Aadhar Number"
                    : "Enter PAN Number"
                }
                error={cardError}
                helperText={radioError}
                inputRef={cardNumRef}
              />
            </Box>
          </div>
          {formData.selectDetails === "Aadhaar" && (
            <div className="mobile-number-container ms-0 ms-sm-2 mt-3 pt-3 ">
              <div className="d-flex p-0 mb-2">
                <InputLabel>
                  <p
                    style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
                    className="my-auto fw-bold gilroy text-dark"
                  >
                    Mobile Number
                  </p>
                </InputLabel>
              </div>
              <TextField
                type="text"
                inputMode="numeric"
                placeholder="Enter mobile number linked "
                size="small"
                fullWidth
                value={formData.mobileNum}
                onChange={handleMobileNumber}
                name="mobileNum"
                inputProps={{ min: 0 }}
                required
                error={!!errors.mobileNum}
                helperText={errors.mobileNum}
              />
            </div>
          )}

          {formData.selectDetails === "PAN" && (
            <div className="pan_DOB-container ms-0 ms-sm-2 mt-3 pt-3 pe-2 w-100 d-block d-lg-flex gap-5 justify-content-between ">
              <div className="w-100 mb-4 mb-lg-0">
                <div className="d-flex p-0 mb-2">
                  <InputLabel>
                    <p
                      style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
                      className="my-auto fw-bold gilroy text-dark"
                    >
                      Date of Birth
                    </p>
                  </InputLabel>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="custom-date-picker me-4"
                    value={
                      formData.dateOfBirth ? dayjs(formData.dateOfBirth) : null
                    }
                    size="small"
                    fullWidth
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.dateOfBirth}
                        helperText={errors.dateOfBirth}
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText className="text-danger ms-3">
                  {errors.dateOfBirth}
                </FormHelperText>
              </div>
              <div className="pan_Name-container w-100">
                <div className="p-0 mb-2">
                  <InputLabel>
                    <p
                      style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
                      className="my-auto fw-bold gilroy text-dark"
                    >
                      Name (as on PAN)
                    </p>
                  </InputLabel>
                </div>
                <TextField
                  type="text"
                  size="small"
                  fullWidth
                  placeholder="Enter Name"
                  value={formData.nameOnPan}
                  onChange={handleChange}
                  name="nameOnPan"
                  error={!!errors.nameOnPan}
                  helperText={errors.nameOnPan}
                />
              </div>
            </div>
          )}
        </div>

        {/* Terms and conditions checkbox */}
        <div className="confirm-checkbox d-flex mt-5 ms-0 ms-sm-2 ms-md-5 px-2">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  id="handleTerms"
                  checked={formData.checkedTerms}
                  onChange={handleChange}
                  name="checkedTerms"
                />
              }
            />
          </FormGroup>
          <label htmlFor="handleTerms" className="m-0 pe-1 pe-md-5">
            <p
              style={{
                textTransform: "none",
                fontSize: isSmallScreen ? "12px" : "14px",
              }}
              className="personal_verificationn__checkbox gilroy-regular"
            >
              I confirm that I am the authorized individual completing this
              verification. The information provided will be validated using my
              Aadhaar or PAN card. I agree to the terms and conditions of Eserve
              Market.
            </p>
          </label>
        </div>
        <FormHelperText className="text-danger ms-1 ms-sm-3">
          {errors.checkedTerms}
        </FormHelperText>
      </div>
    </>
  );
}

export default PersonalVerification;
