import React, { useState } from "react";
import "./MyAuctionsDetails.css";
import { ArrowBackRounded } from "@mui/icons-material";
import MyAuctionAndBidBtn from "./MyAuctionAndBidBtn";
import AuctionDetails from "./AuctionDetails/AuctionDetails";
import AuctionBids from "./AuctionBids/AuctionBids";
import { Link } from "@mui/material";

const MyAuctionsDetails = () => {
  // useStates--------------
  const [isDetailsActive, setIsDetailsActive] = useState(true);

  const handleIsActiveButton = (isActive) => {
    setIsDetailsActive(isActive);
  };

  return (
    <>
      <div className="my_auctions_details__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Auctions</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              /{" "}
              <Link
                underline="none"
                href={"/buyers-auctions-list"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                My Auctions
              </Link>{" "}
              /{" "}
              <span style={{ color: "#1365AF" }}>
                Auction
                {isDetailsActive ? " Details" : " Bids"}
              </span>
            </p>
          </div>
        </div>
        <div>
          <MyAuctionAndBidBtn
            isActive={isDetailsActive}
            onButtonClick={handleIsActiveButton}
            className="w-100"
          />
        </div>
        <div className="d-flex justify-content-center">
          {isDetailsActive ? <AuctionDetails /> : <AuctionBids />}
        </div>
      </div>
    </>
  );
};

export default MyAuctionsDetails;
