import React, { useEffect, useState } from "react";
import "./CustomerOrder.css";
import { orderData } from "../../Dashboard/RecentOrder/OrderCarousel/orderdata.js";
import { Avatar, Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { MoreVertRounded, SearchRounded } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faMapMarkerAlt,
  faCircle,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

import ReactPaginate from "react-paginate";
import FilterOrders from "./CustOrderFilter/FilterOrders.jsx";
import SortOrders from "./CustOrderSort/SortOrders.jsx";

const itemsPerPage = 4;

function CustomerOrder() {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  // Screen Size for responsiveness
  const isLargeScreen = useMediaQuery(" (max-width:992px) ");
  const isMoreThnMediumScreen = useMediaQuery(" (max-width:668px) ");
  const isSmallScreen = useMediaQuery(" (max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery(" (max-width: 420px) ");

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const offset = currentPage * itemsPerPage;
  const currentItems = orderData.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div className="cutomer_orders__container">
        <div className="mb-5 mt-5">
          <div
            className={`d-flex ${
              isExtraSmallScreen ? "flex-column" : "flex-sm-row"
            } gap-2 gap-sm-inherit justify-content-between w-100`}
          >
            <FilterOrders />
            <SortOrders />
          </div>
        </div>

        <div
          className={` my-5 ${isExtraSmallScreen ? "px-0" : "px-5"}  w-100 `}
        >
          <div className="px-sm-5">
            <div className="search-bar border px-2 d-flex align-items-center justify-content-center w-100">
              <SearchRounded style={{ color: "rgba(106, 125, 159, 1)" }} />
              <Box sx={{ m: 0, width: "100%" }}>
                <input
                  className="w-100 fs-5 fw-medium"
                  type="text"
                  id="searchForService"
                  placeholder="Search For Service"
                  value={searchText}
                  onChange={handleInputChange}
                />
              </Box>
            </div>
          </div>
        </div>

        <div>
          {currentItems.map((item) => (
            <div
              key={item.id}
              className="px-2 py-3 p-sm-4 rounded border gilroy mb-4 mt-1 "
            >
              <div
                className={`name-order-id d-flex ${
                  isMoreThnMediumScreen
                    ? "flex-column align-items-start"
                    : "flex-row align-items-center"
                } justify-content-between `}
              >
                <div
                  className={`d-flex ${
                    isMoreThnMediumScreen
                      ? "justify-content-between w-100"
                      : "justify-content-start"
                  } align-items-center gap-2  `}
                >
                  <div className="d-flex align-items-center">
                    <Avatar
                      src={item.image}
                      sx={{
                        width: isSmallScreen ? 28 : 35,
                        height: isSmallScreen ? 28 : 35,
                      }}
                    />
                    <p className="customer-name m-0 pe-2">&nbsp;{item.name}</p>
                  </div>
                  <div className="order-set-time text-lowercase gilroy d-flex  align-items-center gap-1 gap-md-2">
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "#6a7d9f", fontSize: "5px" }}
                    />
                    <p className="order_time text-lowercase m-0">
                      {" "}
                      &nbsp;{item.orderSetTime}
                    </p>
                  </div>
                </div>
                <div
                  className={`d-flex ${
                    isMoreThnMediumScreen
                      ? "justify-content-between w-100"
                      : "justify-content-end"
                  } align-items-center gap-2  `}
                >
                  <p className="order-id m-0 p-0">Order ID: {item.orderId}</p>
                  <IconButton>
                    <MoreVertRounded
                      sx={{ color: "#000000", fontSize: "30px" }}
                    />
                  </IconButton>
                </div>
              </div>
              <div
                className={`d-flex ${
                  isExtraSmallScreen ? "flex-column" : "flex-row"
                } gap-3 mt-2 `}
              >
                <p
                  style={{
                    color: "rgba(0, 30, 47, 1)",
                    backgroundColor: "rgba(214, 236, 255, 1)",
                    textTransform: "none",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  className="px-2 py-1 rounded m-0"
                >
                  General Pest Control
                </p>
                <p
                  style={{
                    color: "rgba(0, 30, 47, 1)",
                    backgroundColor: "rgba(214, 236, 255, 1)",
                    textTransform: "none",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  className="px-2 py-1 rounded m-0"
                >
                  Pest Control
                </p>
              </div>
              <div className="w-100 d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between mt-3 mb-2">
                <div className=" d-flex flex-column gap-2">
                  <div className="order-information d-flex gap-2 align-items-center w-100">
                    {/* <FontAwesomeIcon
                      icon={faCircleInfo}
                      style={{
                        color: "#6a7d9f",
                        fontSize: isSmallScreen ? 14 : isLargeScreen ? 16 : 18,
                      }}
                    /> */}
                    <img src="icons/about.png" alt="" className="icons" />
                    <p className="details content gilroy m-0">
                      {item.information}
                    </p>
                  </div>

                  <div
                    className={`order-location-date d-flex ${
                      isMoreThnMediumScreen
                        ? "flex-column mt-1 gap-2"
                        : "flex-row gap-3"
                    }  gap-md-5 w-100 `}
                  >
                    <div className="order-location d-flex gap-2 align-items-center mb-1 mb-sm-0">
                      {/* <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{
                          color: "#6a7d9f",
                          fontSize: isSmallScreen
                            ? 16
                            : isLargeScreen
                            ? 16
                            : 18,
                        }}
                      /> */}
                      <img src="icons/location.png" alt="" className="icons" />
                      <p className="details content gilroy m-0">
                        {item.location}
                      </p>
                    </div>
                    <div className="order-date d-flex gap-2 align-items-center">
                      {/* <FontAwesomeIcon
                        icon={faCalendarAlt}
                        style={{
                          color: "#6a7d9f",
                          fontSize: isSmallScreen
                            ? 14
                            : isLargeScreen
                            ? 16
                            : 18,
                        }}
                      /> */}
                      <img src="icons/calendar.png" alt="" className="icons" />
                      <p className="details content m-0 gilroy">{item.date}</p>
                    </div>
                  </div>
                </div>
                <p className="order-price gilroy m-0 gilroy mt-3 mt-sm-2">
                  &#8377;&nbsp;{item.price}
                </p>
              </div>

              <div
                className={`order-status-button d-flex ${
                  isExtraSmallScreen
                    ? "flex-column justify-content-start align-items-start"
                    : "flex-row justify-content-end align-items-center"
                }   gap-2 `}
              >
                <p
                  className={`order-status ${
                    item.status === "Completed"
                      ? "order-status__completed"
                      : "order-status__in_progress"
                  } rounded-pill m-0 gilroy`}
                >
                  Status: {item.status}
                </p>
                <Button
                  variant="contained"
                  size="small"
                  fullWidth={isExtraSmallScreen}
                  style={{ fontSize: "18px", padding: "0px 20px 0px 20px" }}
                  className="order-update-button text-capitalize"
                >
                  Update
                </Button>
              </div>
            </div>
          ))}
        </div>
        <ReactPaginate
          previousLabel={
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ color: "#001b3d", fontSize: "16px" }}
            />
          }
          nextLabel={
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ color: "#001b3d", fontSize: "16px" }}
            />
          }
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(orderData.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          pageClassName="pt-1"
          previousClassName=" pt-1"
          nextClassName=" pt-1"
        />
      </div>
    </>
  );
}

export default CustomerOrder;
