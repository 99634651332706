import React, { useState, useEffect } from "react";
import "./SocialLinks.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useMediaQuery } from "@mui/material";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { sellerSocialLinkEditAPI } from "../../../../../Config/apiConfig";
import axios from "axios";

function SocialLinks({ overviewData }) {
  const [overview, setOverview] = useState({});
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [socialId, setSocialId] = useState("");
  const [open, setOpen] = useState(false);

  const isMediumScreen = useMediaQuery("(max-width:768px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const handleWebsite = (event) => {
    setWebsite(event.target.value);
  };

  useEffect(() => {
    // console.log("profile social", overviewData);
    // console.log(overview._id);
    setOverview(overviewData);
    setWebsite(overviewData?.website);
    setFacebook(overviewData?.facebook);
    setInstagram(overviewData?.instagram);
    setTwitter(overviewData?.twitter);
    setSocialId(overviewData?._id);
  }, [overviewData]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = async () => {
    const loginResponse = localStorage.getItem("loginCredentials");
    const parsedData = JSON.parse(loginResponse);
    if (parsedData) {
      try {
        const response = await axios.put(
          sellerSocialLinkEditAPI,
          {
            id: socialId,
            website,
            facebook,
            instagram,
            twitter,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          }
        );
        if (response?.data && response.status === 200) {
          console.log("Updated social links", response.data);
          handleClose();
        }
      } catch (error) {
        console.log("Error updating social links", error);
      }
    }
  };

  return (
    <div className="my-5 px-0 w-100">
      <h4 className=" social_links_heading  gilroy">Social Links</h4>
      <div className="socila-links d-flex px-2 flex-column gap-4 mt-4">
        <div className="social_links_box website-link d-flex gap-2 flex-column flex-sm-row align-items-start align-items-sm-center justify-content-start">
          <label htmlFor="website" className="links-label gilroy align-center">
            <FontAwesomeIcon
              icon={faGlobe}
              style={{ color: "#6a7d9f", fontSize: "19px", marginTop: "1px" }}
            />
            &nbsp;&nbsp;Website
          </label>

          <input
            type="text"
            id="website-input"
            value={website}
            onChange={handleWebsite}
            className=" links form-control rounded"
            placeholder="Username"
            readOnly
          />
        </div>
        <div className="social_links_box facebook-link d-flex flex-column flex-sm-row gap-2 align-items-start align-items-sm-center justify-content-start">
          <label htmlFor="facebook" className="links-label gilroy align-center">
            <FontAwesomeIcon
              icon={faFacebook}
              style={{ color: "#6a7d9f", fontSize: "19px", marginTop: "1px" }}
            />
            &nbsp;&nbsp;Facebook
          </label>
          <input
            type="text"
            id="facebook-input"
            value={facebook}
            className=" links form-control"
            placeholder="Username"
            readOnly
          />
        </div>
        <div className="social_links_box instagram-link d-flex flex-column flex-sm-row gap-2 align-items-start align-items-sm-center justify-content-start">
          <label
            htmlFor="instagram"
            className="links-label gilroy align-center"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              style={{ color: "#6a7d9f", fontSize: "19px", marginTop: "1px" }}
            />
            &nbsp;&nbsp;Instagram
          </label>
          <input
            type="text"
            id="instagram-input"
            value={instagram}
            className=" links form-control"
            placeholder="Username"
            readOnly
          />
        </div>
        <div className="social_links_box twitter-link d-flex flex-column flex-sm-row gap-2 justify-content-start align-items-start align-items-sm-center">
          <label htmlFor="twitter" className="links-label gilroy align-center">
            <FontAwesomeIcon
              icon={faXTwitter}
              style={{ color: "#6a7d9f", fontSize: "19px", marginTop: "1px" }}
            />
            &nbsp;&nbsp;Twitter
          </label>
          <input
            type="text"
            id="twitter-input"
            value={twitter}
            className="links form-control"
            placeholder="Username"
            readOnly
          />
        </div>
        <div className="text-end">
          <Button
            variant="contained"
            onClick={handleOpen}
            style={{
              fontSize: "16px",
              width: isSmallScreen ? "100%" : isMediumScreen ? "50%" : "30%",
              height: "35px",
              textTransform: "none",
            }}
          >
            Update Social Links
          </Button>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Update Social Links</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Facebook"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Twitter"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SocialLinks;
